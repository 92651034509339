.cardFooterContainer {
    height: 48px;
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    padding-top: 8px;
    border-top: 1px solid #e9eef5;

    .actionButtons {
        display: flex;
        gap: 1rem;
    }
}

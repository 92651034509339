@import 'styles/colors';

.datePickerWrapper {
    display: flex;
    white-space: nowrap;
    font-size: 0.875em;
    margin: -0.1em 0em 0em;
    align-self: center;

    .datePickerHeader {
        color: $color-secondary-title;
        padding-right: 0.3125em;
    }
}

.tableWrapperContainer {
    width: 100%;
    padding: 8px 32px 16px 32px;
    position: relative;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    align-items: center;
    gap: 40px;
}

@import 'styles/colors';

.coloredDot {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    // Fallback background
    //background-image: linear-gradient(45deg, #414141, #818181 29%, #b4b4b4);

    // Replace it with a specific one if we have a class for that
    &.green {
        background-color: var(--status_summary_green);
    }
    &.yellow {
        background-color: var(--status_summary_orange);
    }
    &.red {
        background-color: var(--status_summary_red);
    }
    &.grey {
        background-image: linear-gradient(45deg, #414141, #818181 29%, #b4b4b4);
    }
}

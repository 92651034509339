@import 'styles/colors';

.dataValueContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.twiceTheSize {
    flex-grow: 1.5;
}

.row {
    padding: 1.2rem 3rem;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    min-height: 75px;
    gap: 1rem;
    overflow: hidden;

    .predictionProbability {
        display: flex;
        gap: 3px;
    }
}

.activityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    min-width: 150px;
    min-height: 75px;
    gap: 0.5rem;
    overflow: hidden;

    .activityContainer {
        display: flex;
        flex-grow: 2;
        width: 100%;
        overflow: hidden;

        .activityParent {
            width: 100%;
            display: flex;
            justify-content: space-between;
            min-width: 0;
            gap: 2rem;
            align-items: center;

            .activityNameDetails {
                flex: 1;
                overflow: hidden;
                min-width: 60px; // min width for the activity name

                .activityLink {
                    overflow: hidden;
                }
            }
            .status {
                width: 180px;
                display: flex;
                align-items: center;
                gap: 0.7rem;
            }
        }
    }
    .activityId {
        align-self: flex-start;
    }
}

.top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.title {
    font-size: 2rem;
    font-weight: 500;
    color: var(--text_color-black-white);
}
.smallTitle {
    color: var(--text_color-black-white);
}

.plannedFinish {
    background-color: var(--my_focus_header_bg);
}

.bestForecast {
    background-color: $toggleViewActiveColor;
    .bestForecastTitle {
        color: $color-white;
    }
    .bestForecastSmallTitle {
        color: $color-white;
        font-size: 2rem;
        font-weight: 500;
    }
}

.baselineFinish {
    font-size: 1.2rem;
}

.tooltipContentParent {
    display: flex;
    gap: 2rem;

    .tooltipContent {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.listContainer {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: var(--text_color);

    .listItem {
        width: 100%;
        min-width: 12rem;
        font-style: normal;
        font-weight: 300;
        display: flex;
        align-items: center;
        font-family: Roboto, Halvetica, Arial, sans-serif;
        padding: 8px;
        justify-content: space-between;
        height: 3.2rem;
        font-size: 1.6rem;
        line-height: 1.6rem;
        transition: 0.3s;

        &:hover {
            color: var(--select_menu_hover_color);
            background: var(--select_menu_hover_background);
        }
    }
}

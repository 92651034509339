@import 'styles/colors';
.compareContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 56px;
    gap: 0.8rem;
    .compareValue {
        font-size: 2rem;
        color: var(--link_color);
        font-weight: 400;
        text-transform: capitalize;
    }
    .compareTitle {
        font-size: 2rem;
        font-weight: 400;
        color: $color-secondary-title;
    }
}
.compareOption {
    text-transform: capitalize;
}

.tableWrapperContainer {
    width: 100%;
    padding: 8px 3.2rem;
    position: relative;
}

.listWrapper {
    display: flex;
    gap: 4rem;
    .filtersWrapper {
        display: flex;
        gap: 2rem;
        align-items: center;

        .filterTitle {
            color: var(--filters_wrapper_text_color);
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.6rem;
            text-align: left;
        }
    }
}

@import 'styles/colors';

.filterWrapper {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    .selectWrapper {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .title {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
    .filterItemResults {
        width: 250px;
    }
    .inputText {
        width: 30rem;
        background-color: var(--my_custom_select_bg);
    }
}

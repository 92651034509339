.programFocusScopeToGoContainerBoard .smooth-dnd-container.horizontal {
    display: flex;
}

.programFocusScopeToGoContainerBoard .smooth-dnd-container {
    width: 100%;
    height: 100%;
}

.programFocusScopeToGoContainerBoard .smooth-dnd-container.horizontal > section > div {
    width: 100%;
    display: flex;
    /*border-radius: 16px;*/
    background-color: var(--columns_board_lane_bg);
    margin-top: -10px;
    /*padding-top: 16px;*/
    max-height: initial;
    height: 100%;
    padding: 16px 16px 0 16px;
}

.programFocusScopeToGoContainerBoard .smooth-dnd-container.horizontal > section:nth-child(1) > div {
    background-color: #5465dc;
}

.programFocusScopeToGoContainerBoard .smooth-dnd-container.horizontal > section:nth-child(2) > div {
    background-color: #142878;
}

.programFocusScopeToGoContainerBoard .smooth-dnd-container.horizontal > section:nth-child(3) > div {
    background-color: #5c984b;
}

.programFocusScopeToGoContainerBoard section header {
    display: none;
}

.programFocusScopeToGoContainerBoard .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: initial;
}

@import 'styles/colors';

.detailsCell {
    height: 100%;
    position: relative;
    .content {
        font-weight: 400;
        font-size: 1.6rem;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        white-space: normal;
        max-width: 100%;
    }

    .latestCommentDetails {
        margin-top: 0.8rem;
        font-size: 1.2rem;
        color: $color-secondary-title;
        display: flex;
        gap: 0.4rem;
    }

    .aiGenerated {
        margin-top: 0.8rem;
        font-size: 1.2rem;
        color: $color-secondary-title;
    }

    .actionButtons {
        position: absolute;
        top: 0;
        right: 1.6rem;
        align-items: center;
        gap: 0.8rem;
        display: none;
    }

    &:hover {
        .actionButtons {
            display: flex;
        }
    }

    ul,
    ol {
        list-style: initial;
        padding: initial;
        padding-left: 2rem;
    }
}

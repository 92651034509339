@import 'styles/colors';

.failed {
    color: var(--status_summary_red);
    font-weight: bold;
}
.passed {
    color: var(--status_summary_green);
    font-weight: bold;
}

.container {
    width: 100%;
    display: flex;
    height: 1.6rem;
    .green {
        background-color: var(--status_summary_green);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .red {
        background-color: var(--status_summary_red);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}

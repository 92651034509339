.fswLogoWrapper {
    display: block;
    text-align: center;
    width: 100%;
    background: #fff;
    padding: 1em;
    img.fswLogoImg {
        max-height: 100%;
        max-width: 100%;
    }
}

@import 'styles/colors';
.modalContainer {
    width: 45rem;

    .title {
        font-weight: bold;
        font-size: 2.2rem;
    }
    .subTitle {
        margin-top: 2rem;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 0 1rem 0 5px;
    }
    .selectDeselect {
        padding: 1rem 0;
        .selectDeselectTitle {
            float: right;
            color: $light-theme-link-color;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .footer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $divider;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: space-between;
            .actions {
                display: flex;
                justify-content: flex-end;
                gap: 8px;
            }
        }
    }
}

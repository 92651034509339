.selectWrapper {
    position: relative;
    margin-right: 28px;
    margin-top: 20px;
    display: flex;
    align-items: center;

    .title {
        font-size: 1.6rem;
        color: var(--text_color);
        margin-right: 12px;
    }

    .advancedWrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .title {
            margin-right: 8px;
        }
    }

    .wbsFilterCustomClass {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.textSearchInputWrapper {
    border: solid 1px var(--widget__bottom_border_color);
    padding: 4px;
}

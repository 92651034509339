@import 'styles/varibles.scss';

.editorTextAreaContainer {
    position: relative;
    display: flex;
    .editorTextArea {
        height: calc(100% - 60px);
        padding: 8px;
        border-radius: 2px;
        border: 1px solid var(--my_custom_select_border_color);
        &.error {
            border: 2px solid #cc0000;
        }
    }
}

@import 'styles/varibles.scss';
@import 'styles/colors';

.container {
    width: 20rem;
}

.programLibraryMenuLink {
    text-decoration: none;
    color: var(--select_menu_color);
    width: 100%;

    &:hover {
        color: var(--select_menu_hover_color);
        text-decoration: none;
    }
}

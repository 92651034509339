@import 'styles/colors';
.modalContainer {
    width: 63rem;

    .title {
        margin-top: 2rem;
        font-weight: bold;
        font-size: 2.2rem;
    }

    .content {
        margin-block: 2rem;
        font-size: 2rem;
    }

    .footer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .actions {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            gap: 10px;
        }
    }
}

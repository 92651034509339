@import 'styles/varibles';
@import 'styles/colors';

.customizedRoundIconContainer {
    @include flex-center;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    transition: 100ms all ease-in;
    border: none;
    cursor: pointer;

    &.enableHover {
        &:hover {
            span {
                color: $body-background-color-start;
            }
            svg {
                path {
                    fill: $body-background-color-start;
                }
            }
        }
    }

    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &.border {
        border: 1px solid var(--customized_round_icon_border);
    }

    &.selected {
        span {
            color: $body-background-color-start;
        }
        svg {
            path {
                fill: $body-background-color-start;
            }
        }
    }
}

@import 'styles/varibles';

.UsersManagementContainer {
    width: 100%;

    .usersManagementTitle {
        display: flex;
        align-items: center;
        margin-left: 1.6rem;
        flex: 1;
    }

    .userManagementTableWrapper {
        padding: 1.6rem;
        .actions {
            display: flex;
            gap: 0.8rem;
        }
    }
}

.availableUsersContainer {
    margin-left: 1rem;
    color: var(--text_color);

    .risk {
        color: $indicatorRiskColor;
    }
}

@import 'styles/varibles.scss';
.container {
    padding-left: 30px;
    padding-top: 30px;
    background-color: var(--lollipop_bg);
    .chart {
        height: 860px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        @include custom-scroll;
    }
    .xaxis {
        height: 50px;
    }
}

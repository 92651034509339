@import 'styles/colors';
.bulkAssingContent {
    .messageForTheUser {
        margin-top: 1.2rem;
    }

    .title {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .optionContainer {
        font-size: 1.6rem;
        margin-top: 2.8rem;
        display: flex;
        gap: 2.4rem;
    }

    .selectTitle {
        font-size: 1.6rem;
        margin-top: 2.8rem;
        .bold {
            font-weight: 500;
        }
    }

    .selectWrapper {
        margin-top: 2.8rem;
        label {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            cursor: pointer;
        }
        .fileUploadInput {
            display: none;
        }
        .customWidth {
            width: 30rem;
        }
    }
    .row {
        margin-top: 2.8rem;
        label {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            cursor: pointer;
        }
        .fileUploadInput {
            display: none;
        }
        .customImageUpload {
            padding: 6px 1.2rem;
            cursor: pointer;
            display: flex;
            align-items: center;
        }
    }

    .footer {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

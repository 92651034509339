@import 'styles/colors.scss';

.excelExportContainer {
    display: flex;
    gap: 16px;
    margin: 32px 0;

    .container {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        border: 1px solid var(--sub_program_preferences_border);
        padding: 40px 32px 32px 32px;
        margin: -16px 0 0 -16px;

        .checkBoxSection {
            margin-top: -16px;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .checkBoxWrapper {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }

        .boldText {
            font-weight: bold;
        }

        .textAreaWrapper {
            margin-top: -16px;
            width: 40rem;
            height: 15rem;
        }

        .actionButtons {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 1.6rem;
        }
    }
}

.row {
    padding: 3.2rem;
}
.box {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;
    flex: 1;
}

.dataValueContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

h3 {
    font-size: 1.8rem;
    font-weight: 400;
}

h1 {
    font-size: 2.2rem;
    font-weight: 500;

    &.big {
        font-size: 2.5rem;
    }
}

.tooltipContent {
    ul {
        padding-left: 30px;
        list-style: initial;
        margin: initial;
        li {
            display: list-item;
            margin: initial;
            padding: 4px 0;
        }
    }
}

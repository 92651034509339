.headerContentContainer {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    .searchInputWrapper {
        width: 20rem;
        font-size: 1.6rem;
    }

    .datePickerContainer {
        width: 20rem;
        font-size: 1.6rem;
    }
}

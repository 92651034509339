@import 'styles/colors';
@import 'styles/varibles';

.badge {
    position: absolute;
    height: 20px;
    border-radius: 10px;
    right: -10px;
    top: 0;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0 8px;
    background-color: $color10;
    color: $color-white;
    @include flex-center;
}

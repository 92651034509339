@import 'styles/varibles.scss';
@import 'styles/colors';

.LibraryTableContainer {
    .container {
        padding: 1.6rem;

        .pendingStatus {
            background-color: $programLibraryOrange;
            color: $color-black;
        }
        .errorStatus {
            background-color: $programLibraryRed;
            color: $color-black;
        }
    }
}

@import 'styles/colors.scss';

.dropdownContent {
    min-width: 250px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    animation-duration: 0.5s;
    animation-name: slidein;
    border: 1px solid $color-white;
    border-radius: 15px;
    margin: 1px;
    .separator {
        width: 100%;
        border: 0.5px solid var(--widget__bottom_border_color);
    }
    .title {
        color: $color-secondary-title;
    }

    .createNewDashboard {
        cursor: pointer;
        color: var(--text_color);
        width: 100%;
        min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        padding: 8px;
        transition: 0.3s;

        &:hover {
            color: var(--select_menu_hover_color);
            background: var(--select_menu_hover_background);
        }
    }
}

.timezoneSelectorContainer {
    margin-top: 3.2rem;
    width: 500px;
    display: flex;
    gap: 0.8rem;
    align-items: center;

    .label {
        color: var(--text_color);
        font-weight: 300;
    }
}

@import 'styles/colors';

.lmsContainer {
    .filters {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }
    .lmsCards {
        padding: 2.1rem 3.7rem 7.3rem 3.7rem;
        display: flex;
        flex-wrap: wrap;
    }

    .inputSearch {
        width: 30rem;
    }
}

@import 'styles/varibles';
@import 'styles/colors';

.relationshipsContainer {
    padding: 8px;

    .tableContainer {
        padding: 1.6rem;
    }
}

.singleTab {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 0.8rem;

    font-weight: 400;
    text-transform: capitalize;
    padding: 2.4rem 0 2.4rem 3.2rem;
    font-size: 1.8rem;
    color: var(--text_color);

    .statusBarWrapper {
        width: 80px;
    }
}

@import 'styles/colors';
.constraintsWrapperContainer {
    margin-top: 1.8rem;
    font-size: 1.5rem;
    padding-right: 2.4rem;
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;

    .title {
        font-weight: 500;
        padding-top: 4px;
    }
}

@import 'styles/colors';

.pillsContainer {
    font-size: 1.2rem;
    padding: 0.7rem 1.2rem;
    border-radius: 1.4rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    width: fit-content;
    background-color: var(--pills-background-color);
    color: var(--pills-color);
    svg {
        path {
            fill: var(--pills-color);
        }
    }

    &.risk {
        background-color: #ffdbdb;
        color: $indicatorRiskColor;
        svg {
            path {
                fill: $indicatorRiskColor;
            }
        }
    }

    &.info {
        background-color: #ffe78d;
        color: #252c38;
        svg {
            path {
                fill: #252c38;
            }
        }
    }

    &.assignee {
        font-weight: 500;
        background-color: $assigneePillColor;
        color: $color-white;

        svg {
            path {
                fill: $color-white;
            }
        }
    }

    &.unAssignee {
        font-weight: 500;
        background-color: $plannedColor;
        color: $color-white;

        svg {
            path {
                fill: $color-white;
            }
        }
    }

    &.todo {
        background-image: $todo;
        color: $color-white;
        svg {
            path {
                fill: $color-white;
            }
        }
    }

    &.inProgress {
        background-image: $inProgress;
        color: $color-white;
        svg {
            path {
                fill: $color-white;
            }
        }
    }

    &.done {
        background-image: $done;
        color: $color-white;
        svg {
            path {
                fill: $color-white;
            }
        }
    }

    &.tag {
        background-color: var(--indicator-tag-backgroundColor);
        color: #ffffff;
    }

    &.user {
        background-color: $userPillColor;
        color: #ffffff;
        font-weight: normal;
        font-size: 1.2rem;
        svg {
            path {
                fill: $color-white;
            }
        }
    }

    &.tableRisk {
        background-color: $indicatorRiskColor;
        color: $color-white;
        padding: 4px 1.2rem;
    }
}

.actionDueDatesContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;

    .title {
        width: 10rem;
        font-size: 1.5rem;
        font-weight: 500;
    }

    .content {
        flex: 1;

        .datesWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.6rem;

            .date {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                flex: 1;

                .dateTitle {
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }
        }
    }
}

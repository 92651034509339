@import 'styles/varibles.scss';

.treeProgramTopHeader {
    display: flex;
    align-self: stretch;
    margin-bottom: 1.6rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.6rem;
    font-size: 2.4rem;

    .headerContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 1.6rem;
    }

    .actions {
        display: flex;
        gap: 0.8rem;
        align-items: center;
        flex: 1;
    }
}

@import 'styles/colors.scss';

.rowsBoardHeader {
    display: flex;
    align-items: center;

    .actionButtonsWrapper {
        display: flex;
        gap: 0.8rem;
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    align-items: center;
    gap: 40px;
}

.simulationContainer {
    .simulationWrapper {
        padding: 8px 3.2rem;

        .statusChanged {
            background-color: var(--activity-has-change);
        }

        .statusHasNotChanged {
            background-color: var(--activity-has-no-change);
        }
    }
    .titleFilters {
        padding-inline: 3rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.footer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

@import 'styles/colors';

.ganttChartDisplayContainer {
    .progressSelection {
        display: flex;
        color: $RadioButtonsTitlesColor;
        font-size: 1.6rem;
        padding: 1.4rem 26px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;

        .rowContainer {
            display: flex;
            align-items: center;
            gap: 0.8rem;

            .filterView {
                display: flex;
                align-items: center;

                label {
                    margin-right: 0.8rem;
                }
                .clusterSelect {
                    min-width: 40rem;
                }
            }
            .critical {
                margin-left: 1.6rem;
                display: flex;
                align-items: center;
            }
            .clusterDetails {
                padding: 0.8rem 1.2rem;
            }
        }
    }

    .container {
        height: 70vh;
        width: 100%;
        &.fullScreen {
            height: calc(100vh - 68px);
        }
        .spinnerContainer {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
    .resourses {
        padding: 0 2.6rem 1.4rem 3rem;
    }
}

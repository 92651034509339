.breadcrumbItem {
    font-size: 2rem;
    font-weight: 400;
    color: var(--breadcrumbs_text_color);
    cursor: pointer;
    &.disabled {
        color: #8fa3c3;
        cursor: initial;
    }
}

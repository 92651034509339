@value tableRow from 'components/common/Tables/MainTable/MainTable.module.scss';

.rowOptionsCell {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.tableRow:hover .rowOptionsCell {
    visibility: visible;
}

@import 'styles/colors';
@import 'styles/varibles';

$projectNameWidth: 250px;
$gridTop: 4rem;
.wrapper {
    margin: 8px 0 2rem 0;
    z-index: 0;

    .box {
        position: relative;
        background: var(--modal_base-bg);
        padding: 2.4rem 0;
        margin: 1.6rem 2.4rem;
        border-radius: 8px;

        .container {
            display: flex;
            flex-direction: column;
            gap: 48px;
            margin: 48px 1.6rem 2.4rem 1.6rem;
            position: relative;

            .grid {
                position: absolute;
                width: 100%;
                height: calc(100% + #{$gridTop});
                left: 0;
                top: -$gridTop;
                z-index: 0;
                display: flex;
                border-top: solid 1px #585e6a;
                background: var(--modal_base-bg);

                .title {
                    width: $projectNameWidth;
                    display: flex;
                    align-items: center;
                    top: -30px;
                    position: relative;
                    color: $login-gray-text;
                    font-size: 1.2rem;
                    height: 30px;
                }

                .columns {
                    flex: 1;
                    position: relative;

                    &:nth-child(odd) {
                        background: var(--phase-performance-chart-odd);
                    }

                    .tickBox {
                        position: absolute;
                        font-size: 1rem;
                        transform: translateX(-50%);
                        top: -2.4rem;
                        display: flex;
                        flex-direction: column;
                        gap: 0.8rem;
                        align-items: center;
                        justify-items: center;

                        .number {
                            height: 1rem;
                            min-width: 1rem;
                            text-align: center;
                        }

                        .tick {
                            width: 1px;
                            height: 5px;
                            background: #585e6a;
                        }
                    }
                }
            }

            .row {
                z-index: 1;
                display: flex;
                width: 100%;
                align-items: center;

                .projectName {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    width: $projectNameWidth;
                    gap: 0.8rem;

                    .title {
                        width: $projectNameWidth;
                        font-weight: bold;
                        font-size: 1.8rem;
                        padding-right: 2.4rem;
                    }

                    .date {
                        font-size: 1.2rem;
                    }

                    .status {
                        width: 140px;
                    }
                }

                .barsContainer {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 1.2rem;

                    .columns {
                        width: 100%;
                        height: 50px;
                        display: flex;

                        &.baseline {
                            height: 1.6rem;
                        }

                        .phase {
                            position: relative;
                            height: 100%;

                            .phaseInner {
                                width: 100%;
                                height: 50px;

                                &.baseline {
                                    height: 1.6rem;
                                }
                            }

                            .milestone {
                                position: absolute;
                                transform: translateX(-50%);
                                bottom: 45px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 0.8rem;

                                &.last {
                                    transform: translateX(50%);
                                    right: 0;
                                }

                                .title {
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.milestoneIcon {
    width: 1.4rem;
    height: 1.4rem;
    background: #ffbf00;
    border: solid 1px #000000;
    transform: rotate(45deg);
}

.summaryWrapper {
    display: flex;
    color: var(--text_color);
    .column {
        display: flex;
        margin-left: 2rem;

        .content {
            margin-left: 4px;
            font-weight: 400;
        }
    }
}

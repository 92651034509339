.criticalPathCell {
    width: 100%;
    color: var(--text_color);
    font-weight: 400;

    .changeMap {
        margin-top: 1.2rem;
        font-size: 1.4rem;
        color: #9b9b9b;
    }

    .notFound {
        visibility: hidden;
    }
}

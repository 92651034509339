.titleActions {
    padding-left: 16px;
    flex: 1;
}
.container {
    padding: 16px;

    .row {
        display: flex;
        margin: 32px 0;
        align-items: center;
        .label {
            width: 16rem;
            font-weight: bold;
        }
        .inputWrapper {
            flex: 0 100 max-content;
        }
        .input {
            width: 350px;
        }
        .actionWrapper {
            padding-left: 16rem;
            display: flex;
            gap: 8px;
        }
        .projectOptions {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
        .projectSelection {
            display: flex;
            gap: 8px;
            align-items: center;
        }
        .radioButtonLabel {
            display: flex;
            gap: 8px;
        }
    }
    .rowAlignStart {
        align-items: flex-start;
    }
    .actions {
        gap: 8px;
        display: flex;
    }
}

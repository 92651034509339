@import 'styles/colors.scss';
@import 'styles/varibles';

.WidgetWithTitle {
    background-color: var(--widget_bg);
    border-radius: 1.6rem;
    color: var(--text_color);
    width: 100%;
    border: 1px solid var(--widget_border_color);
    box-sizing: content-box;

    .contentArea {
        width: 100%; // so all the children put in here can use all the space
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @include custom-scroll;
    }

    .titleArea {
        // double the border in CollapsableWidget because we don't utilize two adjacent borders like there
        background-color: var(--widget_title_bg);
        border-radius: 1.6rem 1.6rem 0 0;
        min-height: 50px;
        border-bottom: solid 2px var(--widget__bottom_border_color);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .titleComponents {
            padding: 1.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3 {
                color: var(--text_color);
                font-size: 2.2rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
            }

            .endOfLine {
                display: flex;
                column-gap: 1.6rem;
                flex: 1;
                justify-content: flex-end;
                align-items: center;
            }
        }

        .titleFilters {
            padding: 0 1.6rem 1.6rem 1.6rem;
        }
    }
}

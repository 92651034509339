.inputWidth {
    width: 34rem;
}

.popoverContentWrapper {
    display: flex;
    flex-direction: column;
    min-width: 900px;
    height: 400px;
    background-color: var(--select_menu_background);
    padding-top: 32px;

    .popoverContent {
        flex: 1;
        display: flex;
    }
}

.sideMenu {
    ul {
        width: 150px;
    }
}

.getRisk {
    font-weight: bold;
    color: var(--status_summary_green);
    &.low {
        color: var(--status_summary_green);
    }
    &.medium {
        color: var(--status_summary_orange);
    }
    &.high {
        color: var(--status_summary_red);
    }
}

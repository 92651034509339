@import 'styles/colors.scss';

.trackContainer {
    width: 100%;
    .segmentsContainer {
        height: 8px;
        width: 100%;
        display: flex;
        .red {
            background: var(--status_summary_red);
        }
        .orange {
            background: var(--status_summary_orange);
        }
        .green {
            background: var(--status_summary_green);
        }
    }
    .handlesContainer {
        height: 2rem;
        width: 100%;
        display: flex;
        white-space: nowrap;
        .markerValue {
            text-align: right;
        }
        .sliderValue {
            margin: 0 4px;
        }
    }
}

@import 'styles/colors';

.todo {
    font-weight: bold;
    color: $todoCardBackground;
}

.inProgress {
    font-weight: bold;
    color: $inProgressCardBackground;
}

.done {
    font-weight: bold;
    color: $doneCardBackground;
}

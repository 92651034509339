@import 'styles/colors';
@import 'styles/varibles.scss';
.draggableList {
    margin-top: 20px;
    max-height: 35vh;
    overflow: auto;
    @include custom-scroll;
    .listItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        .listItemWrapper {
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 1rem;
            margin: 1rem;
            gap: 1rem;
            border: 1px solid $colorGray;
            border-radius: 5px;
            width: 50rem;
            .listItemTitleWrapper {
                width: 46rem;
                display: flex;
                .listItemTitle,
                .listItemSubTitle {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0 10px;
                    overflow: hidden;
                }
                .listItemTitle {
                    font-weight: bold;
                    min-width: 20rem;
                }
            }
        }
        &.draggingListItem {
            .listItemWrapper {
                border: 1px solid $organizeCustomDashboardBlue;
            }
        }
    }
}

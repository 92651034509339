@import 'styles/colors';

.toggleViewContainer {
    border: solid 1px $toggleViewActiveColor;
    display: flex;
    height: 3.5rem;
    cursor: pointer;
    align-self: center;
    border-radius: 8px;

    .IconWrapper {
        color: $toggleViewActiveColor;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 0 1.6rem;
        gap: 1.6rem;
        font-weight: 500;

        &.active {
            color: $color-white;
            background: $toggleViewActiveColor;
            svg {
                path {
                    fill: $color-white;
                }
            }
        }
    }

    .columns {
        border-radius: 6px 0 0 6px;
    }

    .rows {
        border-radius: 0 6px 6px 0;
    }
}

.addCommentWidgetContainer {
    width: 100%;

    .actionButtonsWrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.8rem;

        .actionButtons {
            display: flex;
            align-items: center;
            gap: 1.6rem;
        }
    }
}

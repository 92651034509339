@import 'styles/colors';
@import 'styles/varibles';

.mainHeaderComponent {
    background: $color-black;
    display: flex;
    z-index: 1;

    .alertSwitchUser {
        font-size: 1.8rem;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
    }
    > .mainHeaderContainer {
        padding: 0 1.2em;
        height: $header-height;
        flex-grow: 1;
        margin: 0 auto;

        > .leftSection {
            display: flex;
            align-items: center;
            .imgWrapper {
                height: 43px;
                padding: 1px;
                background-color: $color-white;
                img.mainHeaderOrgImg {
                    width: 41px;
                }
            }

            .navItem {
                @include flex-center();
                color: $color-white;

                padding: 0 32px;
                height: 7rem;
                gap: 8px;
                border-bottom: solid 3px transparent;
                transition: 0.3s;
                font-size: 1.9rem;

                &:hover {
                    background-color: #181c22;
                    cursor: pointer;
                }

                // very important!!!
                // if you want that .active class will not get hashed since it comes from outside (NavLink)
                // add :global to make scss module respect the class
                &:global.active {
                    border-bottom: solid 0.6rem var(--side_menu_active_text_color);
                }
            }
        }

        > .rightSection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            color: $color-white;
            gap: 2.4rem;
        }
    }
}

.dropdownLinks {
    display: flex;
    color: white;
    gap: 0.8rem;
}

.mainHeaderComponent .mainHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tagListContainer {
    display: flex;
    gap: 16px;
    margin: 32px 0;

    .container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        border: 1px solid var(--sub_program_preferences_border);
        padding: 40px 32px 32px 32px;
        margin: -16px 0 0 -16px;
        min-width: 450px;
    }
    .actionButtons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }
}

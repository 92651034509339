.ActiveDirectoryContainer {
    padding: 1.2rem 0px 1.3rem;
    margin: 1.7rem auto 2rem;
    color: #000;
    font-weight: 400;
    font-size: 1em;
    border-radius: 9px;
    max-width: 45rem;
    line-height: 1.25;
    text-align: center;
}

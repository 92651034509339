.versionName {
    color: var(--breadcrumbs_text_color);
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    font-size: 2rem;
}

.multiSelectionOnOffButton {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
}

.versionSelect {
    display: flex;
    gap: 6px;
}
.versionSelectTitle {
    font-size: 2rem;
    font-weight: 400;
    color: #8fa3c3;
}

.dropdownButtonContainer {
    display: flex;
    align-items: center;
    height: 3.6rem;
    padding: 1.2rem;
    color: white;
    background-color: transparent;
    border-radius: 1.8rem;
    border: 2px solid var(--my_custom_select_border_color);
    font-size: 1.6rem;
    transition: all 0.3s ease;
    cursor: pointer;
    justify-content: space-between;

    .title {
        max-width: 400px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        //background-color: var(--my_custom_select_bg);
        color: var(--my_custom_select_text_color);
    }

    &:hover {
        background-color: var(--select_menu_hover_background);
        border-color: #465772;
    }
}

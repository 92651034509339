@import 'styles/varibles.scss';
@import 'styles/colors';

.SingleStory {
    width: 100%;
    padding: 8px 0 0 0;
    font-family: Roboto;
    position: relative;
    color: var(--text_color);

    .title {
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0 32px;
        width: 800px;
    }

    .date {
        padding: 0 32px;
        margin-top: 0.8rem;
        font-size: 1.4rem;
        font-weight: 400;
    }

    .contract {
        padding: 0 32px;
        font-size: 1.4rem;
        margin-top: 16px;
        font-weight: 400;
    }

    .description {
        padding: 0 32px;
        margin-top: 5px;
        font-size: 1.4rem;
        display: flex;
        gap: 0.8rem;
        font-weight: 400;
    }

    .imageContainer {
        width: 100%;
        height: 500px;
        margin-top: 2.8rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: var(--widget_bg);
        overflow: hidden;
        position: relative;
        .hideToolBar {
            background-color: var(--widget_bg);
            width: 100%;
            height: 30px;
            position: absolute;
            bottom: 0;
        }
    }

    .tags {
        padding: 16px 32px;
        width: 100%;
        min-height: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
    }

    .createdBy {
        padding: 0 32px;
        width: 100%;
        height: 30px;
        font-size: 1.2rem;
        font-weight: 400;
    }
}

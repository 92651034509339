@import 'styles/colors.scss';

.importantActivitiesContainer {
    display: flex;
    gap: 16px;
    margin: 32px 0;
    .container {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;
        border: 1px solid var(--sub_program_preferences_border);
        padding: 40px 32px 32px 32px;
        margin: -16px 0 0 -16px;

        .subTitle {
            width: 52rem;
            .boldText {
                font-weight: bold;
            }
        }

        .sectionWrapper {
            display: flex;
            gap: 1.6rem;
            align-items: center;

            .sliderWrapper {
                width: 20rem;

                .labelSize {
                    font-size: 1.2rem;
                }
            }
        }

        .actionButtons {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            gap: 1.6rem;
        }
    }
}

@import 'styles/colors';
@import 'styles/varibles';

.collapseToggle {
    width: 100%;
    height: 32px;
    position: relative;
    top: -16px;
    .svgContainer {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(180deg);
        z-index: 3;
        background-color: var(--collapse_toggle_bg);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.2s;
        @include flex-center;
        &.isCollapse {
            transform: translate(50%, -50%) rotate(0deg);
        }
        &:hover {
            background-color: var(--customized_round_icon_hover);
        }
    }
}

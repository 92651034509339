@import 'styles/varibles';
.tableContainer {
    padding: 8px 1.6rem;
}

.filterContainer {
    display: flex;
    justify-content: space-between;
}

.filterWrapper {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    .selectWrapper {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .title {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
}

.linkContainer {
    padding: 0.8rem 1.6rem 0.8rem 0;
}

.filterItemResults {
    width: 20rem;
}

.tableContainer {
    width: 100%;
}

.boxWrapper {
    height: 468px;
    overflow: hidden;
    @include flex-center;
}

.associatedDocumentsContainer {
    position: relative;
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;

    > span {
        font-size: 1.5rem;
        font-weight: bold;
        cursor: pointer;
    }

    ul {
        li {
            margin-top: 0.4rem;
            display: flex;
            align-items: center;

            a {
                max-width: 30rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            span {
                cursor: pointer;
                visibility: hidden;
                margin-left: 1.6rem;
            }

            &:hover {
                span {
                    visibility: visible;
                }
            }
        }
    }
}

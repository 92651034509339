.headerContainer {
    width: 100%;

    .tabStyle {
        height: 48px;
        padding: 8px 1.6rem;
        display: flex;
        align-items: center;
        color: var(--text_color);
    }
}

.tabsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #94a1b7;
    margin-bottom: 2.4rem;
    position: relative;

    .tabWrapper {
        flex: 1;
        cursor: pointer;
        color: var(--card-tabs-color);
        &.active {
            border-bottom: 3px solid var(--link_color);
            color: var(--link_color);
        }
    }
}

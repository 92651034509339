@import 'styles/colors';

.container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    .header {
        display: flex;
        border-bottom: solid 1px #eaecf0;
        justify-content: space-between;
        padding: 8px 0;
        align-items: center;
        .projectName {
            font-weight: bold;
            font-size: 1.6rem;
        }
        .status {
            width: 12rem;
        }
    }
    .gates {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        .gate {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
    .milestones {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1.6rem;
        .milestone {
            width: 20rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.date {
                font-size: 1.2rem;
            }
        }
    }
    .phase {
        font-weight: bold;
        font-size: 2.4rem;
        .currentPhase {
            font-size: 1.6rem;
        }
    }
    .duration {
        display: flex;
        gap: 1.6rem;
        &.p50 {
            .title {
                font-weight: 400;
                width: 180px;
            }
            .months {
                font-weight: 600;
            }
        }
        .title {
            width: 180px;
        }
        .months {
            font-weight: initial;
        }
    }
    .boxes {
        display: flex;
        gap: 1.6rem;
        .box {
            flex: 1;
            background: var(--phase-performance-typical-bg);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            padding: 1.6rem;
            gap: 0.8rem;

            .title {
                font-size: 1.4rem;
                font-weight: bold;
            }

            .item {
                display: flex;
                justify-content: space-between;

                .label {
                    font-size: 1.2rem;
                }

                .value {
                    font-size: 1.4rem;

                    &.negative {
                        color: $greenStatusColor;
                    }

                    &.positive {
                        color: $redStatusColor;
                    }
                }
            }
        }
    }
}

.versionCompareSelector {
    display: flex;
    gap: 0.4rem;
    .selectedTitle {
        color: #8fa3c3;
        font-size: 1.8rem;
        font-weight: 400;
    }

    .selectedVersion {
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.2em;
        color: var(--text_color);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 400px;
    }
}

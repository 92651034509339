@import 'styles/colors';

.customTooltipContainer {
    font-size: 1.4rem;
    .wrapper {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .colorBox {
            width: 1.4rem;
            height: 1.4rem;
            border: 1px solid $color-white;
        }
    }
}

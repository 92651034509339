@import 'styles/varibles';
@import 'styles/colors';

.customClusteringContainer {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 1.6rem 0;
    gap: 1rem;

    h3 {
        font-size: 2rem;
        color: var(--text_color);
    }

    .searchInputContainer {
        width: 400px;
    }
}

.saveFilterModalContainer {
    width: 100%;
    position: relative;

    .title {
        font-weight: bold;
        font-size: 2.2rem;
    }

    .searchValue {
        margin-top: 2.8rem;
        word-break: break-word;
    }

    .footer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e9eef5;

        > div {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

.container {
    width: 600px;
    position: relative;
    .title {
        color: #252b32;
        font-weight: bold;
        font-size: 2.2rem;
        margin-bottom: 28px;
    }
    .row {
        display: flex;
        margin: 28px 0 0 0;
        align-items: center;
        .label {
            width: 20rem;
            font-weight: bold;
            padding-right: 2.4rem;
        }
        .input {
            width: 350px;
        }
        .actionWrapper {
            padding-left: 16rem;
            display: flex;
            gap: 0.8rem;
        }
    }
    .footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e9eef5;
        margin-top: 2.8rem;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

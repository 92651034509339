.cardWidgetWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 3.2rem;
}

.switchWrapper {
    display: flex;
    align-items: center;

    .label {
        white-space: nowrap;
    }
}

.scoreDeduction {
    color: var(--status_summary_red);
    font-weight: 400;
}

.tableContainer {
    padding: 1.6rem;
    width: 100%;

    .validationDescription {
        align-self: flex-start;
        margin-bottom: 44px;

        h3 {
            font-size: 1.8rem;
            font-weight: bold;
            line-height: 1.56;
        }

        p {
            font-size: 1.7rem;
            font-weight: 300;
            line-height: 1.56;
        }
    }
}

.complianceRangeContainer {
    width: 100%;
    padding-right: 3.2rem;
}

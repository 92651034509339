.sortSelector {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 7px;
    max-width: 300px;
    padding-inline: 2px;
    .selector {
        width: 190px;
    }
}

@import 'styles/varibles.scss';
@import 'styles/colors.scss';
.filterWrapper {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    .title {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }
    .selectWrapper {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .title {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
    .filterTextSearch {
        width: 260px;
        .textInput {
            background-color: var(--my_custom_select_bg);
        }
    }
}

.filtersAndLinkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topSection {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    .resolutionWrapper {
        display: flex;
        gap: 2rem;
        align-items: center;

        .title {
            color: var(--filters_wrapper_text_color);
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.6rem;
            text-align: left;
        }
    }
}

.titleComponents {
    display: flex;
    flex: 1;
    gap: 16px;
    align-items: center;
    padding-left: 0.8rem;

    .dateRangeWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

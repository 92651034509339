.errorCellContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .errorDetails {
        color: var(--link_color);
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.inner {
    display: flex;
    .trigger {
        min-width: 0;
        flex: 1;
        display: flex;
        gap: 8px;
        align-items: center;
        .icon {
        }
        .error {
            flex: 1;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.errorMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .copyErrorText {
        font-size: 12px;
    }
}

.popoverError {
    background-color: var(--tooltip_bg);
    border: solid 1px var(--tooltip_border);
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.57);
    color: var(--text_color);
    font-size: 1.5rem;
    line-height: 1.54rem;
    text-align: left;
    word-break: break-word;
    padding: 16px 28px 8px 28px;
    width: 500px;

    .closePopoverIcon {
        position: absolute;
        right: 5px;
        top: 5px;
        cursor: pointer;
    }
}

.popoverErrorCopyValue {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--link_color);
    &:hover {
        text-decoration: underline;
    }
}

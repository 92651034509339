@import 'styles/colors';

.programActivityRelatedActivityDatesContainer {
    margin-top: 2.2rem;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;

    .title {
        font-size: 1.5rem;
        font-weight: 500;
    }

    .mainContent {
        padding-left: 1.6rem;
        display: flex;
        align-items: center;

        .title {
            width: 15rem;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--card-modal-title-color);
        }

        .content {
            display: flex;
            align-items: center;
            gap: 1.6rem;

            .date {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                white-space: nowrap;

                .dateTitle {
                    font-size: 1.5rem;
                    font-weight: 500;
                }
                .dateValue {
                    font-size: 1.5rem;
                }
            }
        }

        .relatedActivityDatesOptions {
            display: flex;
            align-items: center;
            gap: 16px;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--card-modal-title-color);

            .remaining,
            .completion {
                display: flex;
                align-items: center;
                gap: 16px;

                &.hide {
                    visibility: hidden;
                }
            }

            .remainingInput,
            .completionInput {
                background-color: var(--my_custom_select_bg);
                color: var(--my_custom_select_text_color);
                border: 1px solid var(--my_custom_select_border_color);
                font-weight: normal;
                width: 50px;
                height: 3rem;
                border-radius: 8px;
                padding: 4px;
                &:disabled {
                    border: none;
                }
                &:focus {
                    outline: none;
                }
                &::placeholder {
                    color: $PlaceHolderGrayColor;
                    font-weight: 300;
                }
                &.error {
                    border: 1px solid $errorRedColor;
                }
            }

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type='number'] {
                -moz-appearance: textfield;
            }
        }
    }
}

@import 'styles/colors';

.successorsCell {
    width: 100%;
    color: var(--text_color);
    font-weight: 400;

    .changeMapSuccessors {
        margin-top: 1.2rem;
        font-size: 1.2rem;

        &.afterTime {
            color: $versionCompareAfterTimeColor;
        }

        &.beforeTime {
            color: $versionCompareBeforeTimeColor;
        }
    }

    .notFound {
        visibility: hidden;
    }

    .noChange {
        font-size: 1.4rem;
        color: #9b9b9b;
    }
}

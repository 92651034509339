@import 'styles/colors';

.ganttChartDisplayContainer {
    .progressSelection {
        display: flex;
        color: $RadioButtonsTitlesColor;
        font-size: 1.6rem;
        padding: 1.4rem 26px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;

        .radioContainer {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            .progress {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                margin-right: 64px;
            }
            .selectView {
                display: flex;
                align-items: center;
            }
            .critical {
                margin-left: 1.6rem;
                display: flex;
                align-items: center;
            }
        }
    }

    .container {
        height: 70vh;
        width: 100%;
        &.fullScreen {
            height: calc(100vh - 68px);
        }
        .spinnerContainer {
            width: 100%;
            height: 100%;
            position: relative;
        }
    }
}

@import 'styles/varibles';

.AddRoleContainer {
    width: 100%;
    .addRoleForm {
        position: relative;
        .buttonsContainer {
            margin: 0.75em 3.3125em;
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
        }
        .roleNameInput {
            position: relative;
            margin: 0.75em 3.3125em 2.75em;
            display: flex;
            flex-direction: column;
            label {
                margin-bottom: 0.8125em;
            }
            input {
                background: transparent;
                outline: none;
                border-radius: 0.5625em;
                border: solid 1px #7f91af;
                height: 2.5em;
                color: var(--text_color);
                font-size: 1.3em;
                font-weight: 300;
                padding: 0.125em 0.9375em;
            }
            span {
                &.errorMessage {
                    position: absolute;
                    bottom: -1.7em;
                    left: 0em;
                    color: rgb(199, 13, 13);
                }
            }
        }
        .multiChoiceContainer {
            display: flex;
            justify-content: center;
            .addRoleMultiCheckbox {
                margin: 0em 2.5625em 3.25em;
                &.associatedPermission {
                    width: 37.625em;
                }
                &.associatedUsers {
                    width: 29.75em;
                }
                .choicesContainer {
                    border-radius: 0.5625em;
                    margin-top: 0.8125em;
                    .listContainer {
                        .listUsers {
                            pointer-events: none;
                        }
                        overflow: auto;
                        max-height: 20.5em;
                        background: var(--body_background);
                        border-bottom-left-radius: 0.5625em;
                        border-bottom-right-radius: 0.5625em;
                        @include custom-scroll;
                    }

                    .keyMilestonesTableKpiDot {
                        height: 0.75em;
                        width: 0.75em;
                        border-radius: 50%;
                        position: absolute;
                        top: 0.9375em;
                        left: 1.475em;
                    }
                    label {
                        padding: 16px;
                        gap: 8px;
                        display: flex;
                        align-items: center;
                        border-bottom: solid 1px #465772;
                        &:last-child {
                            border-bottom: none;
                        }
                        &:hover {
                            &.container {
                                color: var(--link_color);
                            }
                        }
                    }
                }
                .checkboxesTitles {
                    font-size: 1em;
                    font-weight: 300;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        color: var(--text_color);
        form {
            .container {
                display: block;
                position: relative;
                padding-left: 2.1875em;
                cursor: pointer;
                font-size: 1.125em;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                &.selectAllTitle {
                    border-radius: 0.5625em;
                    background: #465772;
                }
                &:hover {
                    input ~ .checkmark {
                        background-color: #ccc;
                    }
                }
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                    &:checked ~ .checkmark {
                        background-color: #fff;
                        &:after {
                            display: block;
                        }
                    }
                }
                .checkmark {
                    position: absolute;
                    top: 23px;
                    left: 36px;
                    height: 22px;
                    width: 22px;
                    border-radius: 6px;
                    background-color: transparent;
                    border: solid 1px #7489aa;
                    &:after {
                        content: '';
                        position: absolute;
                        display: none;
                        left: 6px;
                        top: 2px;
                        width: 8px;
                        height: 14px;
                        border: solid #5a6880;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
        }
        .errorMessage {
            bottom: 0.9375em;
            left: 3.225em;
            position: absolute;
            color: rgb(199, 13, 13);
        }
    }
}

.selectAllContainer {
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

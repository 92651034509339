@import 'styles/colors';

.toggleClusteringOptionContainer {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-size: 2rem;
    color: $RadioButtonsTitlesColor;
    padding: 0 2.4rem 0 0;
}

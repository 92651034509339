@import 'styles/varibles.scss';
@import 'styles/colors.scss';
.NoAccessContainer {
    display: flex;
    color: var(--text_color);
    margin: 128px auto 0 auto;
    width: 94.75em;
    .iconWrapper {
        @include no-permission-page;
    }
    .noAccessNotification {
        align-self: center;
    }
}

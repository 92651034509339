@import 'styles/varibles';

.card {
    width: 20%;
    height: 30rem;
    padding: 5px;
    .cardBackground {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 5px;
        border-radius: 9px;
        &.typeImage {
            background-image: url('../../../../../styles/assets/png/Image.png');
        }
        &.typeLive {
            background-image: url('../../../../../styles/assets/png/Live.png');
        }
        &.typeArticle {
            background-image: url('../../../../../styles/assets/png/Notes.png');
        }
        &.typePresentation {
            background-image: url('../../../../../styles/assets/png/Presentation.png');
        }
        &.typeReport {
            background-image: url('../../../../../styles/assets/png/Report.png');
        }
        &.typeVideo {
            background-image: url('../../../../../styles/assets/png/Video.png');
        }
        .cardTop {
            width: 100%;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .cardType {
                font-size: 0.9em;
                font-weight: bold;
            }
            .cardDownload {
                border-radius: 1.5rem;
                background-color: rgba(255, 255, 255, 0.2);
                width: 30px;
                height: 30px;
                @include flex-center;
                transition: all 0.3s ease;
                cursor: pointer;
                &:hover {
                    background-color: rgba(255, 255, 255, 0.6);
                }
            }
        }
        .cardCenter {
            flex: 1;
            font-weight: bold;
            font-size: 2em;
            padding-top: 1.8em;
        }
        .cardBottom {
            flex: 1;
            font-weight: 400;
            font-size: 1.2em;
            padding: 0 1rem;
            text-align: center;
        }
    }
}

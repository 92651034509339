@import 'styles/varibles';
@import 'styles/colors';

.sortFilterContainer {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
    padding-bottom: 1.6rem;
    color: var(--text_color);
    font-size: 1.6rem;
    .filterContainer {
        display: flex;
        justify-content: center;
        gap: 2.4rem;
        align-items: center;

        .selectWrapper {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            flex-wrap: wrap;
            .title {
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }
        }
    }
    .linkContainer {
        padding: 0.8rem 1.6rem 0.8rem 0;
        display: flex;
        gap: 0.8rem;
        align-items: center;
        white-space: nowrap;
        justify-content: space-between;
        a {
            color: var(--link_color);
            line-height: 2rem;
        }
    }
    .submittedCompletion {
        display: flex;
        align-items: center;
        white-space: nowrap;
        .sliderTitle {
            margin-right: 1.2rem;
        }
        .sliderWrapper {
            margin-left: 1.2rem;
            width: 10rem;
        }
    }
}

@import 'styles/varibles.scss';

.customDashboardTopHeader {
    display: flex;
    max-width: $page-container;
    align-self: stretch;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.6rem;
    margin-bottom: 1.6rem;
    color: var(--text_color);

    .pageTitle {
        font-size: 2.4rem;
        display: flex;
        align-items: center;
    }
    .headerContent {
        display: flex;
        justify-content: space-between;
        gap: 1.6rem;
        align-items: center;

        .title {
            display: flex;
            gap: 0.8rem;
            align-items: center;
            flex: 1;
        }
    }
}
.back {
    font-size: 3.2rem;
    cursor: pointer;
}

@import 'styles/colors';

.content {
    .title {
        color: $color-main-title;
        font-size: 2.2rem;
        font-weight: 700;
    }

    .subTitle {
        color: $color-main-title;
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 2.8rem;
    }
    .row {
        margin-top: 2.8rem;
        display: flex;
        align-items: center;
        label {
            width: 240px;
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
    .footer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $divider;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

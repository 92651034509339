@import 'styles/varibles';
@import 'styles/colors';
.container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 16px;
    .programTopHeader {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        gap: 64px;
        padding-right: 16px;
        .programTopHeaderTitle {
            color: #27fef6;
            font-size: 2rem;
            font-weight: 400;
        }
        .link {
            text-decoration: none;
            color: var(--link_color);
            font-size: 1.6rem;
        }
    }
}

@import 'styles/colors';
@import 'styles/varibles';

.group {
    margin: 0 0 16px 0;

    .groupTitle {
        color: $color-secondary-title;
        font-weight: bold;
        text-transform: capitalize;
        margin-bottom: 16px;
    }
}

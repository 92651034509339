.customizeFilterContainerRow {
    //width: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;

    .remove,
    .add {
        display: flex;
        align-items: center;
    }

    .add {
        span {
            margin-left: 4px;
        }
    }
}

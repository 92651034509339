.container {
    display: flex;
    border-radius: 1.6rem;
    border: 1px solid var(--widget_border_color);
    padding: 1.8rem;
    align-items: center;
    gap: 1.6rem;
    .pills {
        flex: 1;
        display: flex;
        gap: 1.6rem;
        .filtersContainer {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            .title {
                font-weight: 500;
            }
        }
    }
}

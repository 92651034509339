.relatedActivityDatesContainer {
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .title {
        font-size: 1.5rem;
        font-weight: 500;
    }

    .mainContent {
        padding-left: 1.6rem;
        display: flex;
        align-items: center;

        .title {
            width: 15rem;
            font-size: 1.5rem;
            font-weight: 500;
            color: var(--card-modal-title-color);
        }

        .content {
            display: flex;
            align-items: center;
            gap: 1.6rem;

            .date {
                display: flex;
                align-items: center;
                gap: 0.8rem;

                .dateTitle {
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }
        }
    }
}

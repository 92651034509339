@import 'styles/colors';
.renderActivityCodesContainer {
    margin-top: 1.8rem;
    font-size: 1.5rem;
    padding-right: 2.4rem;
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;

    .title {
        font-weight: 500;
        padding-top: 4px;
    }

    .value {
        flex: 1;
        color: $lightTableText;
        font-weight: normal;
        gap: 0.8rem;
        flex-wrap: wrap;
        display: flex;
        &.notAvailable {
            padding-top: 4px;
        }
        .more {
            padding-top: 6px;
        }
    }
}

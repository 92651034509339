.monthDatePickerContainer .react-datepicker {
    border-radius: 1.6rem;
    box-shadow: 0 1.6rem 1.6rem 0 rgb(0 0 0 / 50%);
    border: 1px solid #7b8da8;
    color: #fff;
}

.monthDatePickerContainer .react-datepicker__header {
    border-radius: 1.6rem 1.6rem 0 0;
}

.monthDatePickerContainer .react-datepicker__current-month,
.monthDatePickerContainer .react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 1.5rem;
}

.monthDatePickerContainer .react-datepicker__day-name,
.monthDatePickerContainer .react-datepicker__day,
.react-datepicker__time-name {
    width: 2.7rem;
    line-height: 2.7rem;
    font-size: 1.5em;
}

.monthDatePickerContainer .react-datepicker__navigation {
    outline: none;
}

/*========================================================================*/

.monthDatePickerContainer .react-datepicker-wrapper {
    cursor: pointer;
}
.monthDatePickerContainer .react-datepicker .react-datepicker__month-container {
    width: 100%;
}
.monthDatePickerContainer .react-datepicker .react-datepicker__month-container .react-datepicker__month {
    color: inherit;
    font-size: 1.5rem;
    margin-top: 0.4rem;
}
.monthDatePickerContainer
    .react-datepicker
    .react-datepicker__month
    .react-datepicker__month-wrapper
    .react-datepicker__month-text {
    margin: 2px 1.4rem;
    padding: 0.8rem;
    outline: none;
}

.dateRangeFilterContainer {
    .inputWidth {
        width: 29rem;
    }
}

.popoverContentWrapper {
    display: flex;
    flex-direction: column;
    width: 940px;
    height: 470px;
    background-color: var(--select_menu_background);

    .popoverContent {
        flex: 1;
        display: flex;
    }
}

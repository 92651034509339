.customLaneHeaderContainer {
    width: 100%;
    //height: 180px;
    border-radius: 28px 28px 0 0;
    color: #ffffff;

    .customLaneTitle {
        padding: 0 3.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 65px;
        font-size: 2.5rem;
    }

    .customLaneMain {
        height: 65px;
        background-color: #2d40d8;
        display: flex;
        box-shadow: inset 0px -4px 8px 0px #0000001a;

        justify-content: space-between;
        padding: 0 3.2rem;

        .customLaneMainWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .customLaneMainWrapperTitle {
                font-size: 1.3rem;
            }

            .customLaneMainWrapperCounter {
                font-size: 2.4rem;
                margin-top: 0.4rem;
            }
        }
    }
}

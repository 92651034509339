@import 'styles/colors';

.dateInputWrapperContainer {
    padding: 16px;
    border-top: 1px solid #e9eef5;
    color: var(--text_color);
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text {
        font-size: 1.4rem;
    }

    .inputContainer {
        display: flex;
        align-items: center;
        gap: 16px;

        .inputWrapper {
            width: 170px;
        }

        .iconWrapper {
            cursor: pointer;
            width: 40px;
            height: 4rem;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--my_custom_select_border_color);

            &.error {
                border: 1px solid $errorRedColor;
            }
        }
    }

    .error {
        border: 1px solid $errorRedColor;
    }
}

.error {
    color: $errorRedColor;
}

@import 'styles/varibles';
@import 'styles/colors';

.loginFormWrapper {
    position: relative;
    width: 40rem;

    .loginFormTitleWrapper {
        color: #000000;
        font-weight: 500;
        font-size: 2.8rem;
        padding-bottom: 32px;
    }

    .authenticator_type {
        text-transform: capitalize;
    }

    .loginFormSubTitle {
        color: #7c838c;
        margin-bottom: 32px;
        display: flex;
        align-items: center;
    }

    .formWrapper {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        gap: 32px;
        .inputsContainer {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .inputWrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .passwordLabelWrapper {
                    display: flex;
                    justify-content: space-between;
                }
                label {
                    color: #7c838c;
                    font-size: 1.8rem;
                }

                .loginInput {
                    font-size: 1.8rem;
                    padding: 0 0.8rem;
                    height: 4rem;
                    width: 100%;
                    display: block;
                    border: solid 1px #dfdfe3;
                    border-radius: 6px;
                    color: #7c838c;
                    outline: none !important;
                    background-color: #ffffff;

                    &.invalid {
                        border: 1px solid $errorRedColor;
                    }
                }

                /* Change the white to any color ;) */
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
                }

                /*Change text in autofill textbox*/
                input:-webkit-autofill {
                    -webkit-text-fill-color: #7c838c !important;
                }

                /* placeholder color */
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                ::placeholder {
                    opacity: 1; /* Firefox */
                }
            }
        }

        .actionsWrapper {
            margin-top: 0.5rem;
            .submit {
                font-size: 2.4rem;
                width: 100%;
            }
        }

        .backToLoginLink {
            text-align: center;
        }
    }

    .loginSeparatedLine {
        color: #707070;
        width: 100%;
        font-size: 1.5rem;
        max-width: 40rem;
        font-weight: 400;
        text-align: center;
        border-bottom: 1px solid #707070;
        line-height: 0.1rem;
        margin: 3.2rem auto;

        .loginSeparatedLineTitle {
            padding: 0 10px;
            background: #fff;
        }
    }
}

@import 'styles/colors';
.tableFooterContainer {
    width: 100%;
    font-weight: 400;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;

    .showMore,
    .showAll {
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $tableFooterColor;
    }
}

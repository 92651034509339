.container {
    padding-left: 30px;
    padding-top: 30px;
    .chart {
        height: 860px;
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

.riskScoreCell {
    &.green {
        color: var(--status_summary_green);
    }

    &.red {
        color: var(--status_summary_red);
    }

    &.orange {
        color: var(--status_summary_orange);
    }
}

.modalContent {
    width: 370px;
}
.title {
    font-size: 2.2rem;
    font-weight: bold;
}

.text {
    padding: 12px 0;
    line-height: 1.4;
    font-size: 1.6rem;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e9eef5;

    .container {
        display: flex;
        margin-top: 2rem;
        width: 100%;
        justify-content: flex-end;
        gap: 1rem;
    }
}

.cardDetailsContainer {
    display: flex;
    justify-content: space-between;
    gap: 3.2rem;
    flex: 1;

    .leftSection {
        width: 80rem;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        .title {
            font-size: 1.5rem;
            font-weight: 400;
            margin-bottom: 0.8rem;
        }

        .details {
            padding-top: 8px;
            line-height: 1.3;
        }
    }

    .rightSection {
        flex: 1;

        .selectWrapper {
            display: flex;
            align-items: center;
            margin-bottom: 1.6rem;

            .title {
                width: 10rem;
                font-size: 1.5rem;
                font-weight: 500;
            }

            .content {
                flex: 1;

                .assigneeWrapper {
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;

                    .inlineSelectWrapper {
                        flex: 1;
                    }
                }
            }
        }
    }
}

@import 'styles/varibles';
@import 'styles/colors';

.relatedCardsContainer {
    width: 100%;
    height: calc(100% - 3.6rem); // total height - button height
    padding: 0 0.8rem;
    @include custom-scroll;

    .cardWrapper {
        padding-top: 1.6rem;
        padding-left: 0.4rem;
        a:hover {
            text-decoration: none;
        }
        .relatedCard {
            margin-bottom: 1.6rem;
        }
    }
}

@import 'styles/colors.scss';
@import 'styles/varibles';

.templateProjectsContainer {
    border-top: 1px dashed $login-gray-text;
    padding: 30px;
    .title {
        font-size: 1.6rem;
        font-weight: bold;
        padding-bottom: 1rem;
    }
    .topContent {
        display: flex;
        align-items: center;
        gap: 1.6rem;

        .searchInputContainer {
            width: 100%;
        }
    }

    .projectsWrapper {
        margin-top: 1.6rem;
        max-height: 500px;
        overflow: auto;
        @include custom-scroll;
    }
}

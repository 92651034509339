.userImageContainer {
    width: 3.6rem;
    height: 3.6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #cccccc;
    border-radius: 50%;
}

.large {
    width: 0.9rem;
    height: 0.9rem;
}

@import 'styles/colors';
.widgetFiltersContainer {
    margin-top: -10px;
    font-size: 1.5rem;
    .filtersContainer {
        margin-top: 0.8rem;
        font-size: 15px;
        padding-right: 24px;
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .title {
            color: var(--text_color);
            font-weight: 500;
            padding: 4px 10px 0 0;
        }
        .pill {
            background-color: var(--widget_filter_pill_bg_color);
            color: var(--widget_filter_pill_color);
            padding: 6px 16px;
            font-size: 1.5rem;
        }

        .value {
            flex: 1;
            color: $lightTableText;
            font-weight: normal;
            gap: 8px;
            flex-wrap: wrap;
            display: flex;
            .more {
                color: var(--text_color);
                padding-top: 6px;
            }
        }
    }
}

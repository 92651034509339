.addProjectModalContainer {
    width: 370px;

    .title {
        font-weight: bold;
        font-size: 2.2rem;
    }

    .inputTitle {
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 2.8rem;
    }

    .inputWrapper {
        margin-top: 0.8rem;
    }

    .footer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .container {
            display: flex;
            margin-top: 24px;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

.sideMenuContainer {
    margin-top: -2.4rem;
    ul {
        height: 100%;
        width: 260px;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        padding: 1.8rem 1.6rem;
        border-right: 1px solid #dfdfe3;
        li {
            padding: 8px 1.6rem;
            height: 3.5rem;
            font-size: 1.6rem;
            font-weight: 400;
            border-radius: 8px;
            color: var(--range_date-side-menu-color);
            &:hover {
                background-color: var(--range_date-side-menu-bg);
                cursor: pointer;
                color: var(--range_date-side-menu-hover-color);
                font-weight: 500;
            }

            &.active {
                background-color: var(--range_date-side-menu-bg);
                cursor: pointer;
                color: var(--range_date-side-menu-hover-color);
                font-weight: 500;
            }
        }
    }
}

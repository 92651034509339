@import 'styles/colors.scss';
@import 'styles/varibles.scss';

.boardWrapper {
    height: 75vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .header {
        height: 130px;
        width: calc(100% - 30px);
        display: flex;
        margin-left: 10px;
        gap: 10px;
        .headerItem {
            flex: 1;
        }
    }

    .board {
        overflow: auto;
        flex: 1;
        scrollbar-gutter: stable;

        @include custom-scroll;

        > div > div {
            width: 100%;
        }

        .bottomScrollTextMessage {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--text_color);
            font-weight: bold;
        }

        .showMore {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: $tableFooterColor;
            font-weight: bold;
        }
    }
}

.criticalAnalysisIntensityContainer {
    padding: 1.6rem;
}
.annotationFilter {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.selectWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .title {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.lineChart {
    padding: 1.6rem;
}

@import 'styles/varibles';

.UserProfileContainer {
    position: relative;
    width: 100%;

    .userProfileForm {
        padding: 2.6875em 3.3125em 3.125em;

        .formInputsWrapper {
            display: flex;
            margin-bottom: 2.5625em;
            flex-wrap: wrap;
            .fileUploadInput {
                display: none;
            }
            .profilePhotoWrapper {
                .profilePhotoTitle {
                    color: var(--text_color);
                    font-weight: 300;
                    font-size: 1.0625em;
                    margin: 0.8125em 0.8125em 0.8125em 0;
                }
                .profilePhotoContainer {
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                    height: 3.6rem;
                    .actions {
                        display: none;
                        gap: 0.8rem;
                    }
                    &:hover {
                        .actions {
                            display: flex;
                        }
                    }
                }
            }

            .emailInputWrapper,
            .nameInputWrapper,
            .lastNameInputWrapper,
            .titleInputWrapper,
            .phoneInputWrapper {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 250px;
                margin: 0.8125em 0.8125em 0.8125em 0;

                label {
                    color: var(--text_color);
                    font-weight: 300;
                    font-size: 1.0625em;
                    margin-bottom: 0.3125em;
                }

                input {
                    background: var(--my_custom_select_bg);
                    border-radius: 0.5625em;
                    border: 1px solid var(--my_custom_select_border_color);
                    outline: none;
                    color: var(--my_custom_select_text_color);
                    padding: 0.96875em 1.28125em;

                    &.validateInput {
                        border: solid 1px #00f2f2;
                    }
                }
            }
        }

        .passwordAndUserGroupSection {
            margin: 3.2rem 0;
            label {
                color: var(--text_color);
                font-weight: 300;
                font-size: 1.0625em;
                margin-bottom: 0.8rem;
                display: inline-block;
            }
        }
        .associatedRolesContainer {
            position: relative;
            width: fit-content;

            .associatedRolesTitle {
                font-size: 1.0625em;
                color: #d9dfe8;
                margin-bottom: 0.375em;
            }
        }

        .buttonsWrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 3em;

            .buttonsWrapperLeft {
                font-size: 1.125em;
            }

            .buttonsWrapperRight {
                display: flex;
                gap: 1rem;
            }
        }

        .adminActionWrapper {
            cursor: pointer;
            font-size: 1em;
            color: var(--link_color);
            display: flex;
            gap: 0.4rem;

            .changePasswordTitle {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .newUserPasswordContainer {
            display: flex;
            margin-bottom: 2em;
            position: relative;

            .passwordInput {
                display: flex;
                flex-direction: column;
                margin-right: 0.8125em;

                label {
                    color: var(--text_color);
                    font-weight: 300;
                    font-size: 1.0625em;
                    margin-bottom: 0.3125em;
                }

                input {
                    background: var(--my_custom_select_bg);
                    border-radius: 0.5625em;
                    border: solid 1px var(--my_custom_select_border_color);
                    outline: none;
                    color: var(--my_custom_select_text_color);
                    padding: 0.96875em 1.28125em;

                    &.validateInput {
                        border: solid 1px #00f2f2;
                    }
                }
            }
        }

        .requestErrorMessage {
            color: rgb(199, 13, 13);
        }
    }

    .multipleRoundSpinnerRouter {
        position: absolute;
        bottom: 1.25em;
        right: 50%;
    }
}

.bottomActions {
    display: flex;
    gap: 3.6rem;
    align-items: center;
    margin-left: auto;
    margin-top: 3.6rem;
}

.userStatusContainer {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-left: auto;
}

@import 'styles/colors';
@import 'styles/varibles';

.customizedTextAreaContainer {
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none; /*remove the resize handle on the bottom right*/
    background-color: var(--my_custom_select_bg);
    color: var(--my_custom_select_text_color);
    border: 1px solid var(--my_custom_select_border_color);
    font-weight: normal;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 8px;
    line-height: 2rem;

    @include custom-scroll;

    &::placeholder {
        color: $PlaceHolderGrayColor;
        font-weight: 300;
    }
}

@import 'styles/colors';

.expandContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    &.fixedHeight {
        height: 1080px;
    }

    .copyLink {
        position: absolute;
        right: -16px;
        cursor: pointer;
        top: 4px;
    }

    .title {
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0 32px;
    }

    .date {
        padding: 0 32px;
        margin-top: 0.8rem;
        font-size: 1.4rem;

        font-weight: 400;
    }

    .contract {
        padding: 0 32px;
        font-size: 1.4rem;
        margin-top: 16px;
        font-weight: 400;
    }

    .description {
        padding: 0 32px;
        margin-top: 5px;
        font-size: 1.4rem;
        display: flex;
        font-weight: 400;
        margin-bottom: 20px;

        > span {
            margin-left: 3px;
        }
    }

    .imageContainer {
        width: 100%;
        flex: 1;
        margin-top: 2.8rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: var(--modal_base-bg);
        position: relative;
        .hideToolBar {
            background-color: var(--modal_base-bg);
            width: 100%;
            height: 30px;
            position: absolute;
            bottom: 0;
        }
    }

    .tags {
        padding: 16px 32px;
        width: 100%;
        min-height: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
    }

    .createdBy {
        padding: 0 32px;
        width: 100%;
        height: 30px;
        font-size: 1.2rem;
        font-weight: 400;
    }
}

.breadcrumbsContainer {
    display: flex;
    justify-content: flex-start;
    column-gap: 0.8rem;
    font-size: 1.8rem;
    margin-bottom: 2.4rem;
    a {
        color: var(--link_color);
    }

    .arrowSeparator {
        color: var(--icon_color-bg);
    }
}

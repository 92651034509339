@import 'styles/colors';

$header-height: 4.375em;
$left: left;
$right: right;
$page-container: 1700px;
$media-laptop: 1500px;
$media-small-screen: 2560px;
$font-family: 'Roboto', sans-serif;
$mainTableMaxHeight: 910px;

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
//Main
@mixin scrollbar-main {
    width: 10px; // width of vertical scrollbar
    height: 1.2rem; // height of horizontal scrollbar
}
@mixin scrollbar-track-main {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgb(57, 70, 88);
}
@mixin scrollbar-track-thumb-main {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.7);
    background-image: linear-gradient(to bottom, #465772, #ffffff 74%, #b9b9b9 132%);
}

//Secondary
@mixin scrollbar-second {
    width: 2.4rem;
    height: 1.8rem;
}
@mixin scrollbar-thumb-second {
    height: 3.6rem;
    border: 8px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 1.2rem;
    background-image: linear-gradient(to bottom, #465772, #ffffff, #b9b9b9);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
@mixin scrollbar-button-second {
    width: 0;
    height: 0;
    display: none;
}
@mixin scrollbar-corner-second {
    background-color: transparent;
}

//Container margin
@mixin page-container {
    width: 94.75em;
    margin: auto;
}
@mixin no-permission-page {
    background: red;
    border-radius: 28px;
    align-self: center;
    padding: 5px;
    margin: 5px;
}
@mixin custom-scroll {
    &::-webkit-scrollbar {
        width: 10px;
        height: 1.2rem;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px $customScrollTrack;
        border-radius: 10px;
        background-color: $customScrollTrack;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px $customScrollThumb;
        background-color: $customScrollThumb;
    }
}

@mixin media-laptop {
    @media (max-width: $media-laptop) {
        @content;
    }
}

@mixin media-small-screen {
    @media (max-width: $media-small-screen) {
        @content;
    }
}

.inlineSelectContainer {
    .inlineValue {
        color: var(--text_color-black-white);
        max-width: 80rem;
        height: 3.5rem;
        display: flex;
        align-items: center;
        border-radius: 4px;

        &.editable {
            &:hover {
                background-color: var(--inline-select-bg-hover);
                color: var(--inline-select-color-hover);
            }
        }

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.pageTitleContainer {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: var(--text_color);

    .title {
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }

    .pageTitleGoBack {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.8rem;
    }
}

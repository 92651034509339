@import 'styles/varibles';

.customFilter {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    .highLevelSliderContainer {
        gap: 0.8rem;
        display: flex;

        .highLevelSliderTitle {
            font-size: 1.4rem;
            align-self: end;
            white-space: nowrap;
            padding-bottom: 8px;
        }
        .highLevelSlider {
            width: 20rem;
            padding: 0 1.6rem;
        }
    }

    .highLevelRels {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        align-self: end;
        padding-bottom: 2px;
        .highLevelRelsTitle {
            font-size: 1.4rem;
        }
    }
}

.recommendationCell {
    width: 100%;
    .recommendationName {
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        display: block;

        &:hover {
            text-decoration: underline;
        }
    }

    .recommendationId {
        margin-top: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

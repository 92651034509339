@import 'styles/colors';
$marginTopContentRows: 10px;

.modalContainer {
    min-width: 750px;

    .title {
        font-weight: bold;
        font-size: 2.2rem;
    }

    .subTitle {
        margin-top: calc(#{$marginTopContentRows} * 1.5);
        margin-bottom: 36px;
        font-weight: bold;
    }

    .row {
        display: flex;
        margin-top: $marginTopContentRows;
        align-items: center;
        .label {
            width: 200px;
        }

        .versionNameInput {
            width: 350px;
        }
    }

    .footer {
        border-top: 1px solid lightgrey;
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .actions {
            margin-top: calc(#{$marginTopContentRows} * 1.5);
            display: flex;
            justify-content: flex-end;
            gap: 5px;
            width: 100%;
        }
    }
}

.customizedDatePicker .react-datepicker-wrapper {
    display: none;
}

.customizedDatePicker input {
    display: none;
}

.customizedDatePicker .react-datepicker {
    display: flex;
    border: none;
    background-color: var(--select_menu_background);
}

.customizedDatePicker .react-datepicker__current-month,
.customizedDatePicker .react-datepicker-time__header,
.react-datepicker-year-header {
    font-size: 1.6rem;
}

.customizedDatePicker .react-datepicker__day-name,
.customizedDatePicker .react-datepicker__day,
.react-datepicker__time-name {
    line-height: 1.8rem;
    font-size: 1.6rem;
    color: var(--text_color);
}

.customizedDatePicker .react-datepicker__day--disabled {
    opacity: 0.3;
}

.customizedDatePicker .react-datepicker__navigation {
    outline: none;
}

.customizedDatePicker .react-datepicker__triangle {
    display: none;
}

.customizedDatePicker .react-datepicker__header {
    background-color: transparent;
}

.customizedDatePicker .react-datepicker-popper {
    width: 100%;
    position: initial !important;
    transform: none !important;
}

.customizedDatePicker .react-datepicker-popper[data-placement^='bottom'] {
    padding: 0;
}

.customizedDatePicker .react-datepicker__month-container {
    flex: 1;
}

.customizedDatePicker .react-datepicker__day-names {
    margin-top: 2rem;
    display: flex;
    justify-content: space-evenly;
}
.customizedDatePicker .react-datepicker__day-name {
    font-weight: 600;
    color: var(--range_date-side-menu-color);
}

.customizedDatePicker .react-datepicker__month {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
}

.customizedDatePicker .react-datepicker__current-month {
    color: var(--text_color);
}

.customizedDatePicker .react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
}

.customizedDatePicker .react-datepicker__day {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customizedDatePicker .react-datepicker__day:hover {
    color: #000000;
}

.customizedDatePicker .react-datepicker__day--selected {
    color: #ffffff;
}

.customizedDatePicker .react-datepicker__day--keyboard-selected {
    color: #ffffff;
}

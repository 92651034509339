@import 'styles/colors';

.filterInput {
  background-color: var(--my_custom_select_bg);
  color: var(--text_color);
  outline: none;
  border: solid 1px var(--widget_border_color);
  border-radius: 10px;
  width: 100%;
  font-size: 1.2rem;
  padding: 8px;
  margin-top: 8px;
  &::placeholder {
    color: $PlaceHolderGrayColor;
  }
}
@import 'styles/varibles';

.commentsContainer {
    .titleWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.8rem;
        .leftSection {
            display: flex;
            align-items: center;
            gap: 0.8rem;
            .title {
                font-size: 1.5rem;
                font-weight: 400;
                color: var(--card-modal-title-color);
            }
        }
    }

    .addCommentContainer {
        width: 100%;
        display: flex;
        .profileImageWrapper {
            padding-top: 2px;
        }

        .addCommentAndQuickCommentsContainer {
            border: 1px solid var(--my_custom_select_border_color);
            border-radius: 6px;
            width: 100%;
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .addCommentInput {
                height: 4rem;
                border-radius: 8px;
                padding-left: 18px;
                border: none;
                background: none;
                outline: none;
                font-size: 1.5rem;
                font-weight: 400;
                color: var(--card-modal-title-color);
                width: 100%;
                text-align: start;

                &:hover {
                    cursor: text;
                }
            }
            .quickCommentsContainer {
                margin-bottom: 8px;
                margin-left: 16px;
                align-items: flex-end;
                display: flex;
                gap: 8px;

                .quickCommentText {
                    @include media-laptop {
                        font-size: 1rem;
                    }
                }
            }
        }

        .addCommentWrapper {
            flex: 1;
            margin-left: 2rem;
            display: flex;
            flex-direction: column;
            .textArea {
                width: 100%;
                > div {
                    width: 100%;
                    > div {
                        width: 100%;
                    }
                }
            }

            .actionButtons {
                align-self: flex-end;
                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 75px;
                    height: 30px;
                    border: 1px solid #f1f1f1;
                    border-radius: 2px;
                    background: white;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 1px 1px 0 #bfbdbd;
                    }
                }

                span:nth-child(2) {
                    margin-left: 8px;
                    background-color: #ece9e9;
                }
            }
        }
    }

    .commentWrapper {
        margin-top: 2.8rem;
        display: flex;

        .wrapper {
            width: 100%;
            .iconsWrapper {
                width: 100%;
                display: flex;
                align-items: flex-start;
                .iconsHeader {
                    flex: 1;
                    margin-left: 2rem;
                    display: flex;
                    gap: 0.8rem;
                    align-items: center;
                    > span:nth-child(1) {
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: #555e74;
                    }
                    > span:nth-child(2) {
                        font-size: 1.2rem;
                        font-weight: normal;
                        color: #8fa3c3;
                        margin-top: 2px;
                    }

                    .actionIcons {
                        display: flex;
                        align-items: center;
                        gap: 0.8rem;
                        visibility: hidden;
                    }

                    &:hover {
                        .actionIcons {
                            visibility: visible;
                        }
                    }
                }
            }

            .commentText {
                padding-left: 2rem;
                margin-top: 0.8rem;
                font-size: 1.5rem;
                font-weight: normal;
                color: var(--comments-text-color);
                width: 100%;
                display: flex;
                flex-direction: column;
                .textArea {
                    width: 100%;
                    > div {
                        width: 100%;
                        > div {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .actionButtons {
        display: flex;
        gap: 1.6rem;
    }

    ul,
    ol {
        list-style: initial;
        padding: initial;
        padding-left: 2rem;
    }
}

.deleteMessege {
    color: #fff;
    display: flex;
    align-items: center;

    > span:nth-child(1) {
        background-image: linear-gradient(225deg, #e70a17, #880016);
        border-radius: 50%;
        width: 30px;
        height: 30px;
        padding: 5px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    > span:nth-child(2) {
        margin-left: 8px;
    }
}

.actionsModal {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;
}

.templateName {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    .inputWrapper {
        flex: 1;
    }
}

.phasesContainer {
    margin-top: 2rem;
    .title {
        font-size: 2rem;
        font-weight: bold;
    }

    .addPhase {
        width: fit-content;
        margin-top: 2rem;
        padding-left: 1.6rem;
    }
}

.actionsButtons {
    margin-top: 2rem;
    padding-left: 1.6rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
}

.container {
    width: 100%;

    .title {
        font-weight: bold;
        font-size: 1.6rem;
    }

    .footer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e9eef5;

        .actionButtons {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            gap: 1.6rem;
        }
    }
}

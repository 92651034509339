@import 'styles/colors.scss';

.cardHeaderContainer {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    .row {
        display: flex;
        gap: 1.6rem;
        .inputWrapper {
            display: flex;
            align-items: center;
            gap: 0.8rem;

            .inputLabel {
                color: var(--text_color);
                font-size: 1.5rem;
                font-weight: 500;
            }
        }
    }

    .cardType {
        color: $recommendations;
        font-size: 1.6rem;
        font-weight: 500;
        margin-top: 0.8rem;
    }
}

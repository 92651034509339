@import 'styles/varibles';
@import 'styles/colors';

.loginFormWrapper {
    position: relative;
    width: 60rem;

    .loginFormTitleWrapper {
        color: #000000;
        font-weight: 500;
        font-size: 2.8rem;
        padding-bottom: 32px;
    }

    .authenticator_type {
        text-transform: capitalize;
    }

    p {
        line-height: 3rem;
        margin-bottom: 1.6rem;
        &.subText {
            display: flex;
            align-items: center;
        }
    }

    .authLogo {
        width: 32px;
    }

    .formWrapper {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        gap: 32px;
        .inputsContainer {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .inputWrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                .passwordLabelWrapper {
                    display: flex;
                    justify-content: space-between;
                }
                label {
                    color: #7c838c;
                    font-size: 1.8rem;
                }
                input {
                    font-size: 2.8rem;
                    padding: 0 0.8rem;
                    height: 5rem;
                    width: 11.3rem;
                    display: block;
                    border: solid 1px #dfdfe3;
                    border-radius: 6px;
                    color: #7c838c;
                    outline: none !important;
                    background-color: #ffffff;
                }
                input.invalid {
                    border: 1px solid $errorRedColor;
                }
                /* Change the white to any color ;) */
                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
                }

                /*Change text in autofill textbox*/
                input:-webkit-autofill {
                    -webkit-text-fill-color: #7c838c !important;
                }

                /* placeholder color */
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                ::placeholder {
                    opacity: 1; /* Firefox */
                }
            }
        }

        .actionsWrapper {
            margin-top: 0.5rem;
            display: flex;
            justify-content: center;
            .submit {
                font-size: 2.4rem;
                width: 40rem;
            }
        }

        .backToLoginLink {
            text-align: center;
        }
    }
}

.progressBarContainer {
    width: 100%;
    .linearProgressBarWrapper {
        display: flex;
        align-items: baseline;
        width: 100%;
        justify-content: space-between;

        .text {
            margin-left: 0.625em;
            font-size: 2.8rem;
            font-weight: 700;
        }
    }
}

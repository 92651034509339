@import 'styles/varibles';

.timelineAndDensityContainer {
    padding: 1.6rem;
    display: flex;
    @include custom-scroll;
    overflow: auto;
    max-height: 900px;
    position: relative;
    min-height: 300px;

    .mapContainer {
        height: 100%;
        flex: 1;
        overflow: hidden;
        background-color: var(--chart_bg);
        position: relative;

        .mapWrapper {
            padding-top: 8px;
        }
    }

    .titleContainer {
        height: 100%;
        width: 250px;
        background-color: var(--chart_bg);
        font-size: 1.2rem;
        .title {
            padding: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.8rem;

            .label {
                font-weight: bold;
                font-size: 1.8rem;
            }

            .submittedCompletionValue {
                font-weight: bold;
            }

            .linkLabel {
                font-weight: bold;
                font-size: 1.8rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .todayLine {
        width: 1px;
        background-color: #d580ffff;
        position: absolute;
        z-index: 1;
        display: none;
        bottom: 0;

        .dateBox {
            display: flex;
            align-items: center;
            justify-content: center;
            width: max-content;
            top: 95px;
            left: -35px;
            position: absolute;
            border: 1px solid #d580ffff;
            background-color: #ffffff;
            color: #d580ffff;
            padding: 4px;
            z-index: 2;
            font-size: 12px;
        }
    }
}

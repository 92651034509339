.dropdownContentHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .closeButton {
        cursor: pointer;
        margin-left: auto;
    }
}

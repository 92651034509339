@import 'styles/colors';
@import 'styles/varibles';

.notificationList {
    padding: 16px;

    .header {
        display: flex;

        .title {
            font-size: 2.4rem;
            font-weight: bold;
            color: $color-main-title;
            flex: 1;
            padding-top: 6px;
        }

        .configuration {
            .item {
                display: flex;
                align-items: center;
                font-size: 1.4rem;
                justify-content: space-between;

                &.clickable {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .list {
        margin-top: 1.6rem;
        height: 480px;
        overflow-y: auto;
        @include custom-scroll;

        .emptyList {
            @include flex-center;
            flex-direction: column;
            padding: 3.2rem;
            color: #9c9c9c;
            font-size: 2.4rem;
            font-weight: bold;
            gap: 2.4rem;
        }
    }
}

.coloredDotCell {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 9px;
}

.tooltipContent {
    font-weight: bold;
    padding: 0 1rem;
    line-height: 1em;
}

@import 'styles/colors';

.optionWrapper {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6rem;
    text-align: left;
    cursor: pointer;
    color: $color-secondary-title;

    &.active {
        text-decoration: underline solid var(--link_color);
        text-decoration-thickness: 2px;
        color: var(--text_color);
    }
}

@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?hhsv6e');
    src: url('fonts/icomoon.eot?hhsv6e#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?hhsv6e') format('truetype'), url('fonts/icomoon.woff?hhsv6e') format('woff'),
        url('fonts/icomoon.svg?hhsv6e#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-Academia-Icon:before {
    content: '\e900';
}
.icon-annotations:before {
    content: '\e901';
    color: #7c95bc;
}
.icon-arrow_up:before {
    content: '\e902';
    color: #fff;
}
.icon-arrows:before {
    content: '\e903';
    color: #fff;
}
.icon-Attachments:before {
    content: '\e904';
    color: #fff;
}
.icon-Avatar:before {
    content: '\e905';
}
.icon-back:before {
    content: '\e906';
    color: #fff;
}
.icon-camera_icon:before {
    content: '\e907';
    color: #fff;
}
.icon-carousel-arrow-left:before {
    content: '\e908';
    color: #fff;
}
.icon-carousel-arrow-right:before {
    content: '\e909';
    color: #fff;
}
.icon-clock:before {
    content: '\e90a';
    color: #fff;
}
.icon-contract-performance:before {
    content: '\e90b';
}
.icon-Cost:before {
    content: '\e90c';
    color: #fff;
}
.icon-cost-components:before {
    content: '\e90d';
}
.icon-cost-components-arrow-down:before {
    content: '\e90e';
    color: #fff;
}
.icon-cost-components-arrow-up:before {
    content: '\e90f';
    color: #fff;
}
.icon-create-user:before {
    content: '\e910';
}
.icon-delete-icon:before {
    content: '\e911';
    color: #fff;
}
.icon-document:before {
    content: '\e912';
    color: #394659;
}
.icon-Download-icon:before {
    content: '\e913';
    color: #fff;
}
.icon-Drilldown:before {
    content: '\e914';
    color: #fff;
}
.icon-edit-user-profile:before {
    content: '\e915';
}
.icon-expand-window:before {
    content: '\e916';
    color: #fff;
}
.icon-export-table:before {
    content: '\e917';
    color: #fff;
}
.icon-filter-closed:before {
    content: '\e918';
    color: #fff;
}
.icon-filter-open:before {
    content: '\e919';
    color: #fff;
}
.icon-filter-x-icon:before {
    content: '\e91a';
    color: #fff;
}
.icon-format-bold:before {
    content: '\e91b';
    color: #fff;
}
.icon-format-bullet-list:before {
    content: '\e91c';
    color: #fff;
}
.icon-format-italic:before {
    content: '\e91d';
    color: #fff;
}
.icon-format-link:before {
    content: '\e91e';
    color: #fff;
}
.icon-format-strike-through:before {
    content: '\e91f';
    color: #fff;
}
.icon-format-unordered-list:before {
    content: '\e920';
    color: #fff;
}
.icon-Governance-top-right:before {
    content: '\e921';
    color: #9caab7;
}
.icon-Governance-top-right-tick:before {
    content: '\e922';
    color: #9caab7;
}
.icon-header-arrow-down:before {
    content: '\e923';
    color: #fff;
}
.icon-header-help-desk:before {
    content: '\e924';
}
.icon-header-home:before {
    content: '\e925';
}
.icon-header-portfolio:before {
    content: '\e926';
}
.icon-header-search:before {
    content: '\e927';
    color: #fff;
}
.icon-header-settings:before {
    content: '\e928';
}
.icon-Help:before {
    content: '\e929';
    color: #fff;
}
.icon-info-icon:before {
    content: '\e92a';
    color: #7c95bc;
}
.icon-launch:before {
    content: '\e92b';
}
.icon-left-arrow:before {
    content: '\e92c';
    color: #9caab7;
}
.icon-lock-white:before {
    content: '\e92d';
    color: #fff;
}
.icon-log-out:before {
    content: '\e92e';
}
.icon-mic:before {
    content: '\e92f';
    color: #9caab7;
}
.icon-overrun-reference:before {
    content: '\e930';
}
.icon-paperclip:before {
    content: '\e931';
    color: #9caab7;
}
.icon-project-page-icon:before {
    content: '\e933';
    color: #fff;
}
.icon-right-arrow:before {
    content: '\e934';
    color: #9caab7;
}
.icon-search:before {
    content: '\e935';
    color: #fff;
}
.icon-Settings:before {
    content: '\e936';
    color: #fff;
}
.icon-teal-flag:before {
    content: '\e937';
}
.icon-tick .path1:before {
    content: '\e938';
    color: rgb(0, 0, 0);
}
.icon-tick .path2:before {
    content: '\e939';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-Time:before {
    content: '\e93a';
    color: #fff;
}
.icon-video_icon:before {
    content: '\e93b';
    color: #fff;
}
.icon-warning-icon:before {
    content: '\e93c';
    color: #fff;
}
.icon-widet-expand-arrow:before {
    content: '\e93d';
    color: #fff;
}

.darkModeContainer {
    font-size: 1.5rem;
    margin: 1px 0;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .titleWrapper {
        display: flex;
        align-items: center;
        gap: 15px;

        .title {
            font-weight: 300;
        }
    }
}

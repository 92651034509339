.tab {
    font-weight: 400;
    text-transform: capitalize;
    padding: 2.4rem 0 2.4rem 3.2rem;
    .title {
        font-size: 1.8rem;
        color: var(--text_color);

        &.yellow {
            color: var(--status_summary_orange);
        }

        &.red {
            color: var(--status_summary_red);
        }
    }
}

@import 'styles/reset';
@import 'styles/colors';
@import 'styles/varibles';
@import 'styles/assets/style.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

iframe#webpack-dev-server-client-overlay {
    display: none !important;
}

html {
    font-size: clamp(8px, 0.4vw, 10px);
    // uri explain: 8px - min value, 0.4vw - relative value, 10px - max value
    // example: 1280px window width so middle value is 1280 / 100 * 0.4 = 5.12px so final value is 8px (min)
    // example: 2560px window width so middle value is 2560 / 100 * 0.4 = 10.24px so final value is 10px (max)
    height: 100%;
    padding: 0;
    margin: 0;

    body {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
        height: 100%;
        padding: 0;
        margin: 0;
        background: var(--body_background);
        color: var(--text_color);

        #app-root {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        a {
            text-decoration: none;
            color: var(--link_color);
        }

        a.wysiwyg-mention {
            pointer-events: none;
        }

        a:hover {
            text-decoration: underline;
        }

        button {
            cursor: pointer;
        }
        @include custom-scroll;
    }
}

:root {
    --body_background: #{$body-background-color};
    --widget_bg: #{$widget-bg};
    --widget_title_bg: #1d2228;
    --breadcrumbs_text_color: #{$breadcrumbs-text-color};
    --global_filter_bg: #{$color-black};
    --global_filter_border_color: #{$boxBorder};
    --widget__bottom_border_color: #414141;
    --widget_border_color: #{$widget-border-color};
    --box-shadow: #{$box-shadow};
    --text_color: #fff;
    --text_color-black-white: #fff;
    --card-tabs-color: #b8bdc2;
    --notification-box-shadow-color: rgba(255, 255, 255, 0.9);
    --widget-card-wrapper_bg: rgba(39, 39, 39, 0.7);
    --widget-card-wrapper_shadow: 0 12px 23px rgba(0, 0, 0, 0.55);
    --status_summary_green: #{$statusSummaryGreen};
    --status_summary_red: #{$statusSummaryRed};
    --status_summary_orange: #{$statusSummaryOrange};
    --status_summary_amber: #{$amberStatusColor};
    --status_summary_blue: #{$color-notifications};

    --side_menu_bg: #{$widget-bg};
    --side_menu_text_color: #{$text-icon-color-dark};
    --side_menu_border_right: #{$widget-border-color};
    --side_menu_border-bottom: #{$side-menu-border-color};
    --side_menu_row_bg: #{$side-menu-line-bg};
    --side_menu_active_text_color: #{$side-menu-line-text-color};
    --customized_round_icon_bg: #{$customized-round-icon-bg};
    --customized_round_icon_hover: #{$customized-round-icon-hover};
    --customized_round_icon_border: #{$icon-border};
    --icon_color-bg: #{$color-white};
    --table_odd_color: #{$table-odd-color};
    --table_even_color: #{$table-even-color};
    --link_color: #{$dark-theme-link-color};
    --table_text_color: #{$color-white};
    --input_filter_border: #{$color-white};
    --table_wrapper_color: #{$color-white};
    --table_arrow_shadow: #{$color-black};
    --table_arrow_color: #{$color-white};
    --toggle_view_icon_color: #{$color-white};
    --toggle_view_border_color: #{$boxBorder};
    --customized_tabs_title_color: #{$color-white};
    --customized_tabs_sub_title_color: #{$table-text-color};
    --my_custom_select_border_color: #{$my-custom-select-border};
    --my_custom_select_bg: #{$body-background-color};
    --my_custom_selected_bg: #{$my-custom-selected-bg};
    --my_custom_selected_multi_bg: #{$my-custom-selected-bg};
    --my_custom_select_text_color: #{$color-white};
    --priorities_matrix_label_bg: #{$body-background-color};
    --priorities_matrix_label_border: #{$widget-border-color};
    --priorities_matrix_do_now: #{$doNow};
    --priorities_matrix_do_next: #{$doNext};
    --priorities_matrix_prioritize: #{$prioritize};
    --priorities_matrix_later: #{$later};
    --priorities_matrix_do_now_rows_color: #{$doNow-rows};
    --priorities_matrix_do_next_rows_color: #{$doNext-rows};
    --priorities_matrix_prioritize_rows_color: #{$prioritize-rows};
    --priorities_matrix_later_rows_color: #{$later-rows};
    --columns_board_lane_bg: #{$color-white};
    --custom_card_bg: #{$body-background-color};
    --custom_card_sperator_line: #{$widget-bg};
    --select_menu_color: #{$color-white};
    --select_menu_background: #{$color-dark-blue};
    --select_menu_border: #{$selectMenuBorder};
    --select_menu_hover_background: #{$my-custom-selected-bg};
    --select_menu_hover_color: #{$light-blue-second-color};
    --radio_component_default_color: #{$color-white};
    --validation_config_slider: #{$body-background-color};
    --validation_config_slider_border: #{$my-custom-select-border};
    --summary_row_boxes_bg: #{$summaryRowBoxesBg};
    --phase_performance_disabled_input: #{$disabledInput};
    --phase_performance_box_border: #{$border-color};
    --heroes_bg: #{$widget-bg};
    --sub_program_preferences_border: #{$color-white};
    --default_flux_color: #{$defaultFluxColor};
    --active_flux_color: #{$activeFluxColor};
    --slider_color: #{$sliderColor};
    --filters_wrapper_text_color: #{$filtersWrapperTextColor};
    --base_line_text_color: #{$baseLineText};
    --actual_text_color: #{$actualText};
    --base_line_actual_value_color: #{$baseLineActualValue};
    --base_line_background_image: linear-gradient(to top, #1e2d64, #18667c);
    --latest_background_image: linear-gradient(to top, #023b39, #009898);
    --base_line_background_color: none;
    --latest_background_color: none;
    --baseline_left_side_bg_color: transparent;
    --baseline_right_side_box_bg_color: #{$summaryRowBoxesBg};
    --collapse_toggle_bg: #{$color-black};
    --tooltip_bg: #{$body-background-color};
    --tooltip_border: #{$color-dark-blue};
    --lollipop_bg: #{$widget-bg};
    --table_header_bg: #{$color-white};
    --main_table_header_bg: #{$widget-bg};
    --file_icon_color: #{$color-white};
    --main_table_border: 1px solid transparent;
    --widget_card_divider_color: #62687480;
    --widget_filter_pill_bg_color: #{$widgetFilterPillBgColor};
    --widget_filter_pill_color: #{$widgetFilterPillColor};
    --modal_base-bg: #{$body-background-color};

    --range_date-side-menu-bg: #1b2f37;
    --range_date-side-menu-hover-color: #70cde2;
    --range_date-side-menu-color: #ffffff;
    --my_focus_header_bg: #1e4e5a;

    --inline-select-bg-hover: #{$my-custom-selected-bg};
    --inline-select-color-hover: #{$light-blue-second-color};
    --custom_card_box_shadow: 0 2px 8px 0 rgba(255, 255, 255, 0.2);
    --text-editor-toolbar-bg: trabsparent;
    --text-editor-toolbar-icon-bg: #edeff2;
    --comments-text-color: #edeff2;
    --phase-performance-chart-odd: #47484d;
    --phase-performance-typical-bg: #2c3d42;
    --phase-performance-baseline-bg: #1d242f;
    --phase-template-bg: #1d2228;
    --phase-template-add-button: $toggleViewActiveColor;

    --pills-color: #ffffff;
    --pills-background-color: #4a4d51;
    --card-modal-title-color: #b8bdc2;
    --card-header-input-label: #b8bdc2;
    --light-table-text: $color-white;
    --activity-log-title: $color-white;
    --activity-log-text-content: $color-white;
    --activity-log-date: $color-white;
    --indicator-tag-backgroundColor: #3a5e95;

    --chart_bg: #000000;

    //schedule simulation
    --activity-has-no-change: #{$table-odd-color};
    --activity-has-change: #{$activityHasNoChangeDarkTheme};
    --wbs-level-zero: #{$wbsLevelZero};

    --runrate-past-planned: #2cfffe;
    --runrate-past-actual: #3340a2;
    --runrate-future-planned: #3340a2;
    --runrate-future-actual: #fff2e0;

    --resources-past-planned: #2cfffe;
    --resources-past-actual: #3340a2;
    --resources-future-planned: #ff8a00;
}

[data-theme='light'] {
    --body_background: #{$color-white};
    --widget_bg: #{$widget-with-light};
    --widget_title_bg: #{$color-white};
    --breadcrumbs_text_color: #{$breadcrumbs-text-color-light};
    --global_filter_bg: #{$color-white};
    --global_filter_border_color: #{$border-color-light};
    --widget__bottom_border_color: #{$border-color-light};
    --widget_border_color: #{$border-color-light};
    --box-shadow: none;
    --text_color: #{$text-color};
    --text_color-black-white: #{$text-color};
    --card-tabs-color: #7c838c;
    --notification-box-shadow-color: rgba(0, 0, 0, 0.9);
    --widget-card-wrapper_bg: #{$color-white};
    --widget-card-wrapper_shadow: none;
    --status_summary_green: #{$statusSummaryGreenLight};
    --status_summary_red: #{$statusSummaryRedLight};
    --status_summary_orange: #{$statusSummaryOrangeLight};
    --side_menu_bg: #{$widget-with-light};
    --side_menu_text_color: #{$side-menu-text-color};
    --side_menu_border_right: #{$border-color-light};
    --side_menu_border-bottom: #{$border-color-light};
    --side_menu_row_bg: #{$side-menu-line-bg-light};
    --side_menu_active_text_color: #{$side-menu-line-text-color-light};
    --customized_round_icon_bg: #{$color-white};
    --customized_round_icon_hover: #{$login-gray-text};
    --customized_round_icon_border: #{$border-color-light};
    --icon_color-bg: #{$icon-color-light};
    --table_odd_color: #{$table-odd-color-light};
    --table_even_color: #{$table-even-color-light};
    --link_color: #{$light-theme-link-color};
    --table_text_color: #{$table-text-color-light};
    --input_filter_border: #{$table-text-color-light};
    --table_wrapper_color: #{$widget-with-light};
    --table_arrow_shadow: #{$color-white};
    --table_arrow_color: #{$color-black};
    --toggle_view_icon_color: #{$icon-color-light};
    --toggle_view_border_color: #{$border-color-light};
    --customized_tabs_title_color: #{$table-text-color-light};
    --customized_tabs_sub_title_color: #{$table-text-color-light};
    --my_custom_select_border_color: #{$border-color-light};
    --my_custom_select_bg: #{$my-custom-select-bg-light};
    --my_custom_selected_bg: #{$my-custom-selected-bg-light};
    --my_custom_selected_multi_bg: #{$widget-with-light};
    --my_custom_select_text_color: #{$color-black};
    --priorities_matrix_label_bg: #{$priorities-matrix-label-bg-light};
    --priorities_matrix_label_border: #{$border-color-light};
    --priorities_matrix_do_now: #{$doNow-light};
    --priorities_matrix_do_next: #{$doNext-light};
    --priorities_matrix_prioritize: #{$prioritize-light};
    --priorities_matrix_later: #{$later-light};
    --priorities_matrix_do_now_rows_color: #{$doNow-rows-light};
    --priorities_matrix_do_next_rows_color: #{$doNext-rows-light};
    --priorities_matrix_prioritize_rows_color: #{$prioritize-rows-light};
    --priorities_matrix_later_rows_color: #{$later-rows-light};
    --columns_board_lane_bg: #{$widget-with-light};
    --custom_card_bg: #{$color-white};
    --custom_card_sperator_line: #{$border-color-light};
    --select_menu_color: #{$side-menu-text-color};
    --select_menu_background: #{$color-white};
    --select_menu_border: #{$border-color-light};
    --select_menu_hover_background: #{$border-color-light};
    --select_menu_hover_color: #{$side-menu-text-color};
    --radio_component_default_color: #{$radioComponentDefaultColorLight};
    --validation_config_slider: #{$radioComponentDefaultColorLight};
    --validation_config_slider_border: #{$border-color-light};
    --summary_row_boxes_bg: #{$summaryRowBoxesBgLight};
    --phase_performance_disabled_input: #{$disabledInputLight};
    --phase_performance_box_border: #{$border-color-light};
    --heroes_bg: #{$color-white};
    --sub_program_preferences_border: #{$border-color-light};
    --default_flux_color: #{$defaultFluxColorLight};
    --active_flux_color: #{$color-black};
    --slider_color: #{$sliderColorLight};
    --filters_wrapper_text_color: #{$filtersWrapperTextColorLight};
    --base_line_text_color: #{$baseLineTextLight};
    --actual_text_color: #{$actualTextLight};
    --base_line_actual_value_color: #{$baseLineActualValueLight};
    --base_line_background_image: none;
    --latest_background_image: none;
    --base_line_background_color: #{$baseLineTextLight};
    --latest_background_color: #{$actualTextLight};
    --baseline_left_side_bg_color: #{$color-white};
    --baseline_right_side_box_bg_color: #{$color-white};
    --collapse_toggle_bg: #{$widget-with-light};
    --tooltip_bg: #{$color-white};
    --tooltip_border: #{$widget-with-light};
    --lollipop_bg: #{$color-white};
    --table_header_bg: #{$widget-with-light};
    --main_table_header_bg: #{$widget-with-light};
    --file_icon_color: #{$color-black};
    --main_table_border: 1px solid #dfdfe3;
    --widget_card_divider_color: #dfdfe3;
    --widget_filter_pill_bg_color: #{$widgetFilterPillBgColorLight};
    --widget_filter_pill_color: #{$widgetFilterPillColorLight};
    --modal_base-bg: #{$color-white};

    --range_date-side-menu-bg: #e5f4fa;
    --range_date-side-menu-hover-color: #1e7a8e;
    --range_date-side-menu-color: #7c838c;
    --my_focus_header_bg: #ebf6f8;

    --inline-select-bg-hover: #ebecf0;
    --inline-select-color-hover: #868686ff;
    --custom_card_box_shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    --text-editor-toolbar-bg: #{$color-white};
    --text-editor-toolbar-icon-bg: #{$color-white};
    --comments-text-color: #555e74;
    --phase-performance-chart-odd: #f8f9fa;
    --phase-performance-typical-bg: #eaf1f8;
    --phase-performance-baseline-bg: #eaf1f8;
    --phase-template-bg: #{$widget-with-light};
    --phase-template-add-button: $toggleViewActiveColor;

    --pills-background-color: #edeff2;
    --pills-color: #334359;
    --card-modal-title-color: #7c838c;
    --card-header-input-label: #7c838c;
    --light-table-text: $lightTableText;
    --activity-log-title: #252b32;
    --activity-log-text-content: #555e74;
    --activity-log-date: #8fa3c3;
    --indicator-tag-backgroundColor: #59339d;

    --chart_bg: #ffffff;

    //schedule simulation
    --activity-has-no-change: #{$activityHasNoChangeLightTheme};
    --activity-has-change: #{$activityHasChangeLightTheme};
    --wbs-level-zero: #{$wbsLevelZero};

    --runrate-past-planned: #32a4bc;
    --runrate-past-actual: #5a6dee;
    --runrate-future-planned: #5c68bb;
    --runrate-future-actual: #ffe5e5;

    --resources-past-planned: #32a4bc;
    --resources-past-actual: #5a6dee;
    --resources-future-planned: #ffbf00;
}

.slider {
    margin: 0 10px;
    display: flex;

    .sliderTitle {
        font-size: 1.4rem;
        margin-right: 16px;
        padding-top: 29px;
    }

    .sliderWrapper {
        width: 20rem;
    }
}

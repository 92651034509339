@import 'styles/varibles';
.container {
    color: var(--text_color);
    background-color: var(--my_focus_header_bg);
    padding: 1.6rem;
    border-radius: 1.6rem;
    display: flex;
    align-items: flex-end;
    line-height: 2.2rem;
    .content {
        flex: 1;
    }
    .link {
        display: inline-block;
        text-decoration: none;
        color: var(--link_color);
        cursor: pointer;
    }
}

@import 'styles/varibles.scss';

.treeProgramTopHeader {
    display: flex;
    max-width: $page-container;
    align-self: stretch;
    margin-bottom: 1.6rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.6rem;
    color: var(--text_color);
    .back {
        font-size: 3.2rem;
        cursor: pointer;
        color: var(--icon_color-bg);
    }
    .pageTitle {
        font-size: 2.4rem;
        display: flex;
        align-items: center;
    }

    .headerContent {
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }

    .actions {
        display: flex;
        gap: 0.8rem;
        align-items: center;
        flex: 1;
        justify-content: flex-end;
    }
}

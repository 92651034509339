@import 'styles/colors';

.settingsTopHeader {
    display: flex;
    margin-bottom: 1.6rem;

    .titlesWrapper {
        line-height: 1.57;
        .settingsUserDetailsTitle {
            font-size: 2.4rem;
            font-weight: 400;
        }
        .editUserDetailsTitle {
            font-size: 2.2rem;
            font-weight: 300;
        }
    }
    > span {
        position: absolute;
        align-self: center;
        margin-right: 0.9375em;
        left: -30px;
        cursor: pointer;
    }

    .dateWrapper {
        margin-top: 0.8em;
        display: flex;
        color: #8fa3c3;
        > span {
            margin-left: 8px;
            color: #ffea34;
        }
    }

    .backArrowContainer {
        display: flex;
        align-items: center;

        .back {
            font-size: 3.2rem;
            cursor: pointer;
            color: var(--icon_color-bg);
        }
    }
}

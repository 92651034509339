.statusCell {
    font-size: 1.4rem;

    .contentWrapper {
        display: flex;
        gap: 0.8rem;
        align-items: center;
    }

    .wasStatus {
        margin-top: 0.8rem;
        font-weight: 400;
        color: var(--text_color);
    }
}

@import 'styles/colors';

.statusCustomSelectContainer {
    width: 16rem;
    height: 4rem;
    z-index: 1;
}

.statusCustomSelectItem {
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: bold;
    width: 16rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-image: linear-gradient(to bottom, #1a9dfc, #0d4f7e);
    color: #ffffff;
    cursor: pointer;
    position: relative;

    &.TODO {
        background: $todoCardBackground;
    }

    &.IN_PROGRESS {
        background: $inProgressCardBackground;
    }

    &.DONE {
        background: $doneCardBackground;
    }

    .arrowIconWrapper {
        margin-left: 0.8rem;
        position: absolute;
        right: 1.2rem;
        display: none;
    }
}

.statusCustomSelectItem:nth-child(1) {
    .arrowIconWrapper {
        display: block;
    }
}

.statusCustomSelectItem:not(:first-child) {
    margin-top: 0.4rem;
}

@import 'styles/colors';
@import 'styles/varibles.scss';
.draggableList {
    margin-top: 2rem;
    max-height: 35vh;
    overflow: auto;
    @include custom-scroll;
    .listItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        .listItemWrapper {
            display: flex;
            justify-content: left;
            align-items: center;
            padding: 1rem;
            margin: 1rem 1rem 1rem 0;
            gap: 1rem;
            border: 1px solid $colorGray;
            border-radius: 5px;
            flex: 1;
            .listItemTitleWrapper {
                flex: 1;
                .listItemTitle {
                    font-weight: bold;
                    padding: 0 1rem;
                }
            }
        }
        &.draggingListItem {
            .listItemWrapper {
                border: 1px solid $organizeCustomDashboardBlue;
            }
        }

        &.DisableReorderListItem {
            .listItemWrapper {
                color: $colorGray;
            }
        }
    }
}

.fluxIndexComponent {
    width: 5px;
    display: flex;
    align-items: center;

    .fluxIndexWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2px;

        span {
            height: 4px;
            background-color: var(--default_flux_color);
        }

        .activeFluxColor {
            background-color: var(--active_flux_color);
        }
    }
}

@import 'styles/colors';

.mainInputContainer {
    background-color: var(--my_custom_select_bg);
    color: var(--my_custom_select_text_color);
    border: 1px solid var(--my_custom_select_border_color);
    min-height: 35px;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    &.placeholder {
        color: $PlaceHolderGrayColor;
        font-weight: 300;
    }

    &.error {
        border: 1px solid $errorRedColor;
    }
    .input {
        white-space: nowrap;
        flex: 1;
    }
}

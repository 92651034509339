.createCardContainer {
    padding: 0 2.4rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    .tabsWithStatusWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tabWrapperClass {
            flex: 0.2;
        }
    }
}

@import 'styles/varibles';
@import 'styles/colors';

.activityRelationsContainer {
    width: 100%;

    .mainDetails {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 50px;
        .mainDetails_1 {
            width: 12rem;
        }
        .mainDetails_2 {
            width: 10rem;
        }
        .mainDetails_3 {
            width: 15rem;
        }
        .mainDetails_4 {
            width: 15rem;
        }
        .mainDetails_5 {
            width: 10rem;
        }
    }

    .tablesWrapper {
        margin-top: 2rem;
        padding: 0 8px 0 0;
        .tableContainer {
            padding: 8px 3.2rem;
        }
    }
}

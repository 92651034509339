@import 'styles/colors';

.customCardContainer {
    max-width: initial;
    width: 100%;
    color: var(--text_color);
    margin-bottom: 1.6rem;
    background-color: var(--modal_base-bg);
    border-radius: 1.6rem;
    box-shadow: var(--custom_card_box_shadow);
    cursor: pointer;
    padding: 2rem;
    min-height: 252px;

    .header {
        .headerTop {
            color: var(--text_color);
            font-size: 1.2rem;
            font-weight: 300;
            display: flex;
            align-items: center;
            gap: 0.8rem;

            .statusAssigneeContainer {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                .activityStatusWrapper {
                    width: 16rem;
                }
            }

            .headerTopInside {
                display: flex;
                align-items: center;
                gap: 0.4rem;
                flex: 1;
                flex-wrap: wrap;
                .headerTopInsideId {
                    max-width: 20rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .headerTopInsideLabel {
                    white-space: nowrap;
                }
            }
        }

        .headerTitle {
            color: var(--text_color);
            font-size: 2.1rem;
            font-weight: bold;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            max-width: 100%;

            word-break: break-word;
        }

        .headerActivity {
            font-size: 1.3rem;
            color: var(--text_color);
            font-weight: 300;
            white-space: initial;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            margin-top: 1.2rem;
        }
    }

    .main {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        align-items: center;
    }

    .footer {
        border-top: 1px solid var(--side_menu_border-bottom);
        padding-top: 1.2rem;
        margin-top: 1.2rem;
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;
        flex-wrap: wrap;
        position: relative;
        gap: 0.8rem;
        max-height: 110px;
    }

    .trackedUntrackedWrapper {
        visibility: hidden;
        &.tracked {
            visibility: visible;
        }
    }

    &:hover {
        .trackedUntrackedWrapper {
            visibility: visible;
        }
    }
}

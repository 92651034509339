@import 'styles/colors';

.shareInsightModalContainer {
    color: var(--text_color);
    .title {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .optionContainer {
        font-size: 1.6rem;
        margin-top: 2.8rem;
        display: flex;
        gap: 2.4rem;
    }

    .content {
        margin-top: 1.6rem;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        .selectorWrapper {
            margin-top: 0.8rem;
            width: 30rem;
        }

        .textAreaWrapper {
            width: 60rem;
            height: 9rem;
            margin-top: 0.8rem;
        }
    }

    .footer {
        margin-top: 2.8rem;
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid $divider;
        padding-top: 2rem;

        .copyValueWrapper {
            flex: 1;
        }

        .container {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

@import 'styles/varibles.scss';

.cardTabContainer {
    width: 100%;
    height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.4rem;
    font-weight: 400;
}

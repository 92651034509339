@import 'styles/varibles';
@import 'styles/colors';

.activityLogContainer {
    width: 100%;
    margin: 2rem 0;

    .logWrapper {
        margin-top: 2rem;
        display: flex;

        .content {
            margin-left: 2rem;
            flex: 1;
            .textContent {
                font-size: 1.5rem;
                color: var(--activity-log-text-content);
            }

            .date {
                font-size: 1.2rem;
                font-weight: normal;
                color: var(--activity-log-date);
                margin-top: 0.8rem;
            }
        }
    }

    .NoChange {
        margin-top: 2rem;
    }

    .bold {
        font-weight: bold;
    }
}

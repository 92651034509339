.singleProject {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:first-child) {
        margin-top: 1.6rem;
    }

    .actionIcons {
        display: flex;
        gap: 0.8rem;
    }
}

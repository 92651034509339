@import 'styles/colors';
.actionMenuError {
    display: flex;
    gap: 1.6rem;
    align-items: center;
    color: $statusSummaryRedLight;
}
.errorMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .copyErrorText {
        color: lightblue;
        font-size: 1.2rem;
    }
}
.containter {
    padding-left: 48px;
}

@import 'styles/colors';
.content {
    .messageForTheUser {
        margin-top: 0.8rem;
    }

    .title {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .optionContainer {
        font-size: 1.6rem;
        margin-top: 2.8rem;
        display: flex;
        gap: 2.4rem;
    }

    .subTitle {
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 1.6rem;
    }

    .inputWrapper {
        margin-top: 2.8rem;
        width: 250px;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        label {
            display: flex;
            font-weight: 500;
        }
    }

    .footer {
        margin-top: 12rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $divider;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

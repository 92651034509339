@import 'styles/colors';
.narrativeContainer {
    width: 100%;
    padding: 0 1rem 2.4rem 0;
    font-size: 1.7rem;
    .footer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .container {
            display: flex;
            margin: 0 2px;
            width: 100%;
            justify-content: flex-end;
            gap: 0.8rem;
            .clear {
                flex: 1;
            }
        }
    }

    ul,
    ol {
        list-style: initial;
        padding: initial;
        padding-left: 2rem;
    }
}

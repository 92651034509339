@import 'styles/colors.scss';
@import 'styles/varibles';

.singleTemplateContainer {
    background-color: var(--phase-template-bg);
    border-radius: 1.6rem;
    color: var(--text_color);
    width: 650px;
    min-height: 100px;

    .templateContent {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
                font-size: 1.6rem;
                font-weight: bold;
            }

            .projectSCount {
                font-size: 1.4rem;
                color: $login-gray-text;
            }
        }

        .actionIcon {
            display: flex;
            gap: 1.6rem;
        }
    }
}

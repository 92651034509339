.selectClusterRow {
    display: flex;
    flex: 1;
    margin: 1.6rem 0;
    gap: 1.6rem;

    .oneSelectorContainer {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 1.2rem;

        h3 {
            font-size: 2rem;
            width: max-content;
            color: var(--text_color);
        }

        h6 {
            margin-left: 3.2rem;
            font-size: 1.4rem;
            color: #7c95bc;
        }
    }
}

.labelWithIconContainer {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
    }
}

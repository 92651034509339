.selectWrapper {
    position: relative;
    margin-right: 28px;
    margin-top: 20px;
    display: flex;
    align-items: center;

    .title {
        font-size: 1.6rem;
        color: var(--text_color);
        margin-right: 12px;
    }

    .sliderWrapper {
        width: 10rem;
    }

    .wbsNameInput {
        width: 270px;
    }
}

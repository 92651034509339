@import 'styles/varibles';
@import 'styles/colors';

.tableContainer {
    padding: 8px 3.2rem;
    width: 100%;
}

.noData {
    svg {
        margin: 0 5px;
    }
}

@import 'styles/varibles';
.outer {
    flex: 1;
    display: flex;
    overflow-y: hidden;
    z-index: 0;
    background-image: url('../../styles/assets/png/New_Login_Background2x.jpg');
    background-size: cover;
    .inner {
        flex: 1;
        overflow-y: auto;
        scrollbar-gutter: stable;
        @include custom-scroll;
        display: grid;
        justify-content: center;
        .formWrapper {
            width: 45rem;
            @include flex-center();
            flex-direction: column;
            padding-bottom: 48px;
            .logoWrapper {
                height: 100px;
                width: 20rem;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 1.6rem;
            }
        }
    }
}

@import 'styles/varibles.scss';

.addStoryContainer {
    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    .wrapper {
        width: 100%;
        input:focus,
        select:focus,
        textarea:focus {
            outline: none;
        }

        .content {
            position: relative;
            margin-top: 25px;
            display: flex;
            align-items: center;

            .title {
                width: 20rem;
                font-weight: bold;
                font-size: 1.4rem;
            }

            .dateInputWrapper {
                width: 140px;
            }

            .contentValueWrapper {
                flex: 1;
            }

            .contentValue {
                flex: 1;
                font-size: 1.5rem;
            }

            .inputContentValue {
                font-size: 1.5rem;
                height: 32px;
                padding: 8px 66px 8px 16px;
                border-radius: 6px;
            }

            input[type='file'] {
                display: none;
            }

            .customImageUpload {
                border: none;
                padding: 6px 12px;
                cursor: pointer;
                display: flex;
                align-items: center;

                .imageUploadTitle {
                    margin-left: 8px;
                    color: $RadioButtonsTitlesColor;
                    font-weight: bold;
                    font-size: 1.3rem;
                    white-space: nowrap;
                }
            }

            .description {
                align-self: baseline;
                margin-top: 20px;
            }

            .descriptionTextAreaWrapper {
                color: var(--text_color);
                max-width: 533px;
                > div {
                    > div {
                        width: 100%;
                    }
                }
            }
        }
    }

    .actionButtons {
        width: 100%;
        margin-top: 23px;
        display: flex;
        border-top: 1px solid $divider;
        padding-top: 23px;
        gap: 1rem;
        justify-content: flex-end;
    }
}

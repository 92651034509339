.ActivityCell {
    min-width: 0;
    .activityNameContainer {
        display: flex;
        align-items: center;
        height: 3.5rem;
        .activityNameWrapper {
            min-width: 0;
            .activityName {
                font-weight: bold;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .activityId {
        margin-top: 1.2rem;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .activityIdName {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .icons {
            display: flex;
            align-items: center;
            gap: 0.4rem;
        }
    }
}

@import 'styles/colors';
@import 'styles/varibles';

.topContent {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    .searchInputContainer {
        width: 400px;
    }

    .linkStyle {
        text-decoration: none;
        color: $color-white;
        width: 100%;
    }
}

.templatesSection {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

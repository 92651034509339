@import 'styles/colors';

.headerContent {
    display: flex;
    gap: 1.6rem;
    margin-top: 1.6rem;

    .title {
        display: flex;
        gap: 0.8rem;
        align-items: center;
        color: var(--text_color);
    }
}

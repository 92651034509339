@import 'styles/colors';
.linksBoxTitle {
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $color-white;

    .number {
        font-size: 3.2rem;
        font-weight: 600;
        margin-right: 8px;
    }
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 3.2rem;
    overflow: hidden;
}

.item {
    overflow: hidden;
    display: flex;
    padding: 1.2rem 2.4rem;
    flex: 1;
    background-color: var(--summary_row_boxes_bg);
    border-radius: 8px;
    border: 1px solid var(--widget_border_color);
}

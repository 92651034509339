.customizedDatePickerContainer {
    .inputWidth {
        padding: 0;
    }
}

.popoverContentWrapper {
    width: 350px;
    background-color: var(--select_menu_background);

    .popoverContent {
        padding-top: 8px;
        min-height: 320px;
    }
}

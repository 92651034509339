.pageTitle {
    display: flex;
    align-items: center;
    color: var(--text_color);
    font-weight: 400;

    font-size: 2.4rem;

    .back {
        font-size: 3.2rem;
        cursor: pointer;
        color: var(--icon_color-bg);
    }
}

.cardItemProgressBarContainer {
    display: flex;
    width: 230px;
    .cardItemProgressBaComponent {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        .mainText {
            font-size: 2rem;
            font-weight: 500;
        }
    }
}

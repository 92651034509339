@import 'styles/colors';
.widgetCardWrapper {
    background: var(--widget-card-wrapper_bg);
    box-shadow: var(--widget-card-wrapper_shadow);
    overflow: hidden;

    .scoreValue {
        font-size: 2rem;
        font-weight: 500;
        line-height: 2.3rem;
    }

    .cardContent {
        padding: 1.6rem;
    }

    .progressSelection {
        color: $RadioButtonsTitlesColor;
        font-size: 1.8rem;
        display: flex;
        gap: 1.6rem;
        align-items: center;
    }
}

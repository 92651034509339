.titleActions {
    padding-left: 16px;
    flex: 1;
}

.container {
    padding: 16px;
    position: relative;

    .actions {
        gap: 8px;
        display: flex;
    }
}

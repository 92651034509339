.newVersionContainer {
    display: inline-flex;
    width: 95%;

    .newVersionColumnFlex {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .newVersionDate {
            margin-left: 35px;
        }
        .newVersionName {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-left: 35px;
        }

        .newVersionNameInput {
            width: 40rem;
        }

        .newVersionProjectContract {
            margin-left: 35px;
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
}

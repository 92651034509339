@import 'styles/varibles.scss';
@import 'styles/colors.scss';

.filters {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    .filterTextSearch {
        width: 260px;
        .textInput {
            background-color: var(--my_custom_select_bg);
        }
    }
    .selectWrapper {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .title {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
    }
    .titleComponents {
        display: flex;
        flex: 1;
        gap: 0.8rem;
        justify-content: space-between;
        align-items: center;
        padding-left: 0.8rem;
    }
}

@value tableRow from 'components/common/Tables/MainTable/MainTable.module.scss';
@value tableRow as tableRowV8  from 'components/common/Tables/TableV8/tableV8.module.scss';

.tableRow:hover .trackedUntrackedWrapper,
.tableRowV8:hover .trackedUntrackedWrapper {
    display: block;
}

.trackedUntrackedWrapper {
    display: none;
    &.tracked {
        display: block;
    }
}

@import 'styles/varibles';

.lmsTopHeaderContainer {
    display: flex;
    align-self: stretch;
    margin-bottom: 1.6rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1.6rem;
    .pageTitle {
        font-size: 2.4rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 0.8rem;
        .mainTitle {
            font-weight: 500;
        }
        .subTitle {
            font-size: 1.8rem;
        }
    }
}

.tableLocalFiltersContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  .selectWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    .title {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
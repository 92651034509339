@import 'styles/colors';
@import 'styles/varibles';
.message {
    @include flex-center;
    flex-direction: column;
    font-size: 2.2rem;
    height: calc(100vh - 4.375em);
    text-align: center;
    .description {
        margin-bottom: 100px;
        h1 {
            font-size: 3.2rem;
            margin-bottom: 24px;
        }
        h2 {
            font-size: 2.6rem;
        }
    }
}

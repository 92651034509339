.inlineInputContainer {
    font-family: Roboto;

    .inlineValue {
        height: 4rem;
        display: flex;
        align-items: center;

        &.editable {
            &:hover {
                background-color: var(--inline-select-bg-hover);
            }
        }

        span {
            font-size: 2.4rem;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    input {
        background-color: var(--my_custom_select_bg);
        color: var(--my_custom_select_text_color);
        border: 1px solid var(--my_custom_select_border_color);
        border-radius: 4px;
        width: 100%;
        height: 4rem;
        font-size: 2.4rem;
        outline: none;
    }

    &.error {
        border: 2px solid #cc0000;
    }
}

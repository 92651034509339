.textComponentContainer {
    display: flex;
    gap: 0.8rem;
    .textComponent {
        display: flex;
        flex-direction: column;

        .mainText {
            font-size: 1.7rem;
            font-weight: 400;
            text-align: right;
            white-space: nowrap;
        }

        .subText {
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 1.4rem;
            text-align: right;
            margin-top: 0.4rem;
        }
    }
}

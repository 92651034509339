@import 'styles/varibles.scss';

.allBreadcrumbsButton {
    cursor: pointer;
    @include flex-center;
    gap: 2px;
}

.breadcrumbItem {
    font-size: 2rem;
    font-weight: 400;
    color: var(--breadcrumbs_text_color);
    cursor: pointer;
    &.disabled {
        color: #8fa3c3;
        cursor: initial;
    }
}
.dropdownContent {
    min-width: 20rem;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    animation-duration: 0.5s;
    animation-name: slidein;

    .separator {
        width: 100%;
        border: 0.5px solid var(--widget__bottom_border_color);
    }
}

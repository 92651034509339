@import 'styles/varibles.scss';
@import 'styles/colors.scss';

.PageContainerCss {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 1.6rem 0;
    height: 100%;
    overflow: auto;
    @include custom-scroll;
    &.responsive {
        width: initial;
    }
}

$body-background-color-start: #fefefe;
$body-background-color: #13161a;
$text-icon-color-dark: #dfdfe3;
$color-white: unquote('#ffffff');
$headerIconBackGroundColorLight: #262b33;
$headerPopoverGray: #9ba6b7;
$headerPopoverIcon: #8c919a;
$color-black: #000000;
$color-light-black: #4e4e4e;
$color-dark-blue: #13161a;
$color-header-dates: #8fa3c3;
$login-gray-text: #84878a;
$light-blue-second-color: #28fef6;
$box-shadow: 0 0.75em 1.5em 0 rgba(0, 0, 0, 0.9);
$lightTableText: #555e74;
$color-main-title: #252b32;
$color-secondary-title: #8fa3c3;
$color-notifications: #0080ff;
$versionDateColor: #d580ff;
$delayDriverColor: #e68245;
$dark-theme-link-color: #70cde2;
$colorGray: #858789;
$versionCompareTextColor: #252c38;
$versionCompareAfterTimeColor: #fb372a;
$versionCompareBeforeTimeColor: #0aa600;
$versionCompareDefaultGrayColor: #989898;
$PlaceHolderGrayColor: #9299aa;
$RadioButtonsTitlesColor: #7489aa;

$programLibraryGreen: rgba(40, 254, 246, 0.2);

$programActivity: #7786a2;
$recommendations: #794cff;
$userActions: #009dff;
$divider: #e9eef5;
$placeHolderColor: #a9a9a9;

$plannedColor: #94a1b7;
$actualColor: #29fd48;
$forecatsColor: #17cdff;

$boxBorder: #586983;

$tagColor: #59339d;

$assigneePillColor: #32a4bc;
$assigneePillColorLight: #32a4bc33;

$todoCardBackground: #4553af;
$todoCardBackgroundInside: #5465dc;
$todoCardColor: $color-white;

$inProgressCardBackground: #142462;
$inProgressCardBackgroundInside: #142878;
$inProgressCardColor: $color-white;

$doneCardBackground: #4b7a40;
$doneCardBackgroundInside: #5c984b;
$doneCardColor: $color-white;

$todo: linear-gradient(to bottom, $todoCardBackground, $todoCardBackgroundInside);
$inProgress: linear-gradient(to bottom, $inProgressCardBackground, $inProgressCardBackgroundInside);
$done: linear-gradient(to bottom, $doneCardBackground, $doneCardBackgroundInside);

$notFoundBackground: $versionCompareAfterTimeColor;
$notFoundcolor: $color-white;

$wasStatus: #334359;

$customScrollTrack: #d7dee6;
$customScrollThumb: #9aabbc;
$inputSearchBackground: #252b32;
$iconColor: #7c95bc;
$iconHoverColor: $color-white;
$redStatusColor: #f80c1a;
$amberStatusColor: #ffbf00;
$greenStatusColor: #0ca30a;

$radioButtonDarkerColor: #00c9bf;

$userPillColor: #00a1be;

$indicatorDefaultBackgroundColor: #ffe78d;
$indicatorRiskColor: #fd0d1b;
$indicatorRiskBackgroundColor: #ffdbdb;

$lightGray: #edeff2;
$darkGray: #4a4d51;
$errorRedColor: #cc0000;
$warning: #ff9900;

$toggleViewActiveColor: #2c7d90;

// use unquote on colors because node-sass shorten the colors on production build and when using it on fusion charts heatmap - colors are wrong!!
// for example: #33ff00 turns to #3f0 which is valid for css but not valid for fusion charts heatmap configuration
// see solution in: https://stackoverflow.com/questions/15757665/why-does-sass-change-the-format-of-my-colors
$color1: unquote('#228b22');
$_1: #228b22; // just to see the color on the IDE - not for use 😀
$color2: unquote('#33ff00');
$_2: #33ff00; // just to see the color on the IDE - not for use 😀
$color3: unquote('#99ff00');
$_3: #99ff00; // just to see the color on the IDE - not for use 😀
$color4: unquote('#ccff00');
$_4: #ccff00; // just to see the color on the IDE - not for use 😀
$color5: unquote('#ffff00');
$_5: #ffff00; // just to see the color on the IDE - not for use 😀
$color6: unquote('#ffc800');
$_6: #ffc800; // just to see the color on the IDE - not for use 😀
$color7: unquote('#ff9900');
$_7: #ff9900; // just to see the color on the IDE - not for use 😀
$color8: unquote('#ff6600');
$_8: #ff6600; // just to see the color on the IDE - not for use 😀
$color9: unquote('#ff3300');
$_9: #ff3300; // just to see the color on the IDE - not for use 😀
$color10: unquote('#ff0000');
$_10: #ff0000; // just to see the color on the IDE - not for use 😀

$statusSummaryRed: unquote('#ff0000');
$_statusSummaryRed: #ff0000; // just to see the color on the IDE - not for use 😀
$statusSummaryOrange: unquote('#ffbf00');
$_statusSummaryOrange: #ffbf00; // just to see the color on the IDE - not for use 😀
$statusSummaryGreen: unquote('#29fd48');
$_statusSummaryGreen: #29fd48; // just to see the color on the IDE - not for use 😀
$light-blue-color: unquote('#55ecf5');
$_light-blue-color: #55ecf5; // just to see the color on the IDE - not for use 😀

$breadcrumbsTitle: #27fef6;
$createNewDashboard: #344358;

$organizeCustomDashboardBlue: #1393aa;

$widgetFilterPillBgColor: #4a4d51;
$widgetFilterPillColor: #ffffff;

$widgetFilterPillBgColorLight: #edeff2;
$widgetFilterPillColorLight: #000000;

$lightTableRow: #f0f4f7;

$phase_1: #a4d1f3;
$phase_2: #7db6fc;
$phase_3: #3a81f8;
$phase_4: #275afb;
$phase_5: #2a3dd0;
$phase_6: #d5c3fa;
$phase_7: #b997fd;
$phase_8: #9367ec;
$phase_9: #7941ea;
$phase_10: #5a0bd0;
$phase_11: #fbd1a7;
$phase_12: #fbb174;
$phase_13: #fc9a53;
$phase_14: #f97b32;
$phase_15: #df5611;
$phase_16: #abfacf;
$phase_17: #3fe4a8;
$phase_18: #15c898;
$phase_19: #039a79;
$phase_20: #02755c;

$typicalBackground: #eaf1f8;
$baselineBackground: #f8f9fa;
$errorBackground: #ffe5e5;
$errorColor: #ff9696;

$defaultAvatarBackground: #00bdbb;
$unAssignedAvatarBackground: #bdbdbd;
$topMenuAvatarBackground: #34383f;

// new Dark Theme
$border-color: #6e7786;
$side-menu-bg: #1d2129;
$side-menu-border-color: rgba(46, 57, 75, 0.44);
$side-menu-line-bg: #1b2f37;
$customized-round-icon-bg: #262b33;
$customized-round-icon-hover: #586983;
$table-odd-color: #161e29;
$table-even-color: #0e131a;
$table-text-color: #dedede;
$my-custom-selected-bg: #344358;
$priorities-matrix-label-bg: #252c38;
$icon-border: #414141;
$my-custom-select-border: #7c838c;
$widget-bg: #13161a;
$widget-border-color: #414141;
$side-menu-line-text-color: #70cde2;
$breadcrumbs-text-color: #70cde2;
$selectMenuBorder: #7b8da8;
$summaryRowBoxesBg: #262b33;
$disabledInput: #6e7786;
$defaultFluxColor: #4b4b4b;
$activeFluxColor: #989898;
$sliderColor: #70cde2;
$doNow: #1d5f9c;
$doNext: #0f3254;
$prioritize: #0f3254;
$later: #1e2f3f;
$doNow-rows: #4173a1;
$doNext-rows: #33516c;
$prioritize-rows: #33516c;
$later-rows: #2c4358;
$filtersWrapperTextColor: #b8bdc2;
$baseLineText: #2c84bf;
$actualText: #2cfffe;
$baseLineActualValue: #28fef6;
// schedule simulation
$activityHasChangeDarkTheme: #ffffe0;
$activityHasNoChangeDarkTheme: #ebf6f8;

// Light Theme
$body-background-color-light: #ffffff;
$widget-with-light: #f5f7fa;
$border-color-light: #dfdfe3;
$text-color: #000000;
$side-menu-text-color: #00000099;
$statusSummaryGreenLight: unquote('#63a450');
$_statusSummaryGreenLight: #63a450; // just to see the color on the IDE - not for use 😀
$statusSummaryRedLight: unquote('#F60000');
$_statusSummaryRedLight: #f60000; // just to see the color on the IDE - not for use 😀
$statusSummaryOrangeLight: unquote('#FF8A00');
$_statusSummaryOrangeLight: #ff8a00; // just to see the color on the IDE - not for use 😀
$side-menu-line-bg-light: #ebf6f8;
$side-menu-line-text-color-light: #32a4bc;
$icon-color-light: #7c838c;
$table-odd-color-light: #fafbfd;
$table-even-color-light: #ffffff;
$light-theme-link-color: #32a4bc;
$table-text-color-light: #7c838c;
$my-custom-select-bg-light: #ffffff;
$my-custom-selected-bg-light: #e6e6e6;
$priorities-matrix-label-bg-light: #ffffff;
$doNow-light: #164d81;
$doNext-light: #4c7aa8;
$prioritize-light: #4c7aa8;
$later-light: #7f93a5;
$breadcrumbs-text-color-light: #32a4bc;
$radioComponentDefaultColorLight: #b8bdc2;
$summaryRowBoxesBgLight: #ffffff;
$disabledInputLight: #e8e8e8;
$defaultFluxColorLight: #b8bdc2;
$sliderColorLight: #32a4bc;
$doNow-rows-light: #3c6c9a;
$doNext-rows-light: #7295b8;
$prioritize-rows-light: #7295b8;
$later-rows-light: #8ea1b1;
$filtersWrapperTextColorLight: #7c838c;
$baseLineTextLight: #5a6dee;
$actualTextLight: #32a4bc;
$baseLineActualValueLight: #5a6dee;

// Avatar Colors
$avatar_color_0: #ec3e7e;
$avatar_color_1: #7aa8c1;
$avatar_color_2: #ff7c42;
$avatar_color_3: #b442e8;
$avatar_color_4: #38beff;
$avatar_color_5: #7456f2;
$avatar_color_6: #ffbc00;
$avatar_color_7: #04c3a0;
$avatar_color_8: #ff5d55;
$avatar_color_9: #467de7;

$tableReorderModalCheckbox: #15c1c1;
$tableFooterColor: #4c9bfa;
$programLibraryOrange: #fff2e0;
$programLibraryRed: #ffe5e5;

$cardGraphBackGroundDark: #212223;
$cardGraphFont: #84878a;
$pointColorForGraph: #d3d3d3;

// schedule simulation
$activityHasNoChangeLightTheme: #ebf6f8;
$activityHasChangeLightTheme: #ffffe0;
$wbsLevelZero: #fff2e0;

$defaultDensityColor: #275afb;
$defaultBaselineDensityColor: #7db6fc;

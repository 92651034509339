@import 'styles/colors';
@import 'styles/varibles';

.contentWrapper {
    padding: 2rem;
    position: relative;

    .actionsButtons {
        margin-top: 2rem;
        padding-left: 1.6rem;
        display: flex;
        align-items: center;
        gap: 1.6rem;
    }
}

@import 'styles/varibles';

.addAssociatedDocumentsContainer {
    .title {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .inputsWrapper {
        margin-top: 2.8rem;
        display: flex;
        flex-direction: column;
        gap: 2.8rem;

        .inputTitle {
            font-size: 1.6rem;
            font-weight: 500;
            margin-bottom: 4px;
        }
    }

    .footer {
        margin-top: 2.8rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $divider;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

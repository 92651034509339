@mixin divider {
    width: 100%;
    height: 0;
}

.normal {
    @include divider;
    border: 1px solid var(--widget_card_divider_color);
}

.bold {
    @include divider;
    border: 2px solid var(--widget_card_divider_color);
}

@import 'styles/colors';

.sort {
    position: absolute;
    right: 0;
    transition: all 0.2s ease-in;
    display: inline-block;
    transform-origin: center;
    margin-top: -5px;
    &.asc {
        transform: rotate(0deg);
    }
    &.desc {
        transform: translateY(-15%) rotate(180deg);
    }
    svg {
        path {
            fill: var(--table_arrow_color);
        }
    }
}

@import 'styles/colors';
.durationFilterContainer {
    .inputWidth {
        width: 22rem;
    }
}

.durationPopoverContentWrapper {
    width: 400px;
    background-color: var(--select_menu_background);

    .form {
        height: 100%;
        display: flex;
        flex-direction: column;

        .mainContent {
            flex: 1;
            padding: 16px;

            .sectionWrapper {
                margin-top: 16px;
                display: flex;
                align-items: center;
                gap: 16px;
                font-size: 1.5rem;
                font-weight: 500;
                color: var(--card-modal-title-color);

                .section {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .input {
                        background-color: var(--my_custom_select_bg);
                        color: var(--my_custom_select_text_color);
                        border: 1px solid var(--my_custom_select_border_color);
                        font-weight: normal;
                        width: 80px;
                        height: 3rem;
                        border-radius: 8px;
                        padding: 4px;
                        &:focus {
                            outline: none;
                        }
                        &::placeholder {
                            color: $PlaceHolderGrayColor;
                            font-weight: 300;
                        }
                        &.error {
                            border: 1px solid $errorRedColor;
                        }
                    }

                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    input[type='number'] {
                        -moz-appearance: textfield;
                    }

                    .unit {
                        margin-left: 4px;
                    }
                }
            }

            .errorsWrapper {
                margin-top: 16px;
            }
        }

        .footer {
            height: 60px;
            display: flex;
            justify-content: flex-end;
            padding: 0 28px;

            .actionButtons {
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }
        }
    }
}

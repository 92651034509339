.filter {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    flex-wrap: wrap;
    .item {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .title {
            display: flex;
            align-items: center;
            gap: 0.8rem;
        }
        .filtersWrapper {
            display: flex;
            gap: 14px;
            align-items: center;
            height: 35px;

            .filterTitle {
                color: var(--filters_wrapper_text_color);
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
            }
        }
    }
}

.dropdownContent {
    min-width: 20rem;
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    animation-duration: 0.5s;
    animation-name: slidein;

    .dropdownContentHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .closeButton {
            cursor: pointer;
            margin-left: auto;
        }
    }

    .separator {
        width: 100%;
        border: 0.5px solid var(--widget__bottom_border_color);
    }
    .addNewButton {
        cursor: pointer;
        padding: 8px;
        &:hover {
            color: var(--select_menu_hover_color);
            background: var(--select_menu_hover_background);
        }
    }
}

.listContainer {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: var(--text_color);

    .listItem {
        width: 100%;
        min-width: 12rem;
        font-style: normal;
        font-weight: 300;
        display: flex;
        align-items: center;
        font-family: Roboto, Halvetica, Arial, sans-serif;
        padding: 8px;
        justify-content: space-between;
        height: 3.2rem;
        font-size: 1.6rem;
        line-height: 1.6rem;
        transition: 0.3s;
        gap: 16px;

        &:hover {
            color: var(--select_menu_hover_color);
            background: var(--select_menu_hover_background);
        }
        &.active {
            color: var(--select_menu_hover_color);
            background: var(--select_menu_hover_background);
        }

        &.all {
            font-weight: 400;
        }
    }
}

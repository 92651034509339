@import 'styles/varibles';
@import 'styles/colors';

.RCFWidget {
    .boxContainer {
        padding: 1.6rem;
    }
    .boxWrapper {
        height: 468px;
        .text {
            height: 430px;
            @include flex-center;
        }
        .durationRatio {
            .title {
                font-weight: bold;
            }
            .value {
                font-weight: bold;
                color: var(--link_color);
            }
        }
    }
}

.mainContentContainer {
    margin-top: -2.4rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    .content {
        flex: 1;
        padding-top: 16px;
        .defaultContent {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.2rem;
            position: relative;

            .title {
                font-weight: 500;
                font-size: 2rem;
                color: var(--text_color);
            }

            .subTitle {
                font-weight: 400;
                font-size: 1.6rem;
                color: var(--range_date-side-menu-color);
            }

            .imageWrapper {
                position: absolute;
                bottom: 50px;
                left: 100px;
            }
        }
    }

    .footer {
        height: 80px;
        border-top: 1px solid #dfdfe3;
        padding: 0 28px;
        display: flex;
        align-items: center;

        .selectedValue {
            font-size: 1.6rem;
            color: var(--text_color);
        }

        .actionButtons {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.8rem;
        }
    }
}

@import 'styles/varibles';

.tableContainer {
    margin: 32px 0 16px 0;
    width: 1100px;
    //TODO: fix this strange size :)

    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    @include custom-scroll;

    &.planisware {
        height: 250px;
    }
    &.plfTableContainer {
        width: 900px;
    }

    .table {
        width: 100%;

        tbody {
            width: 100%;
        }

        tr {
            width: 100%;
            border: var(--main_table_border);

            &:nth-child(even) {
                background-color: var(--table_even_color);
            }
            &:nth-child(odd) {
                background-color: var(--table_odd_color);
            }

            th,
            td {
                padding: 1.6rem 2.4rem;
            }

            th {
                font-size: 1.0625em;
                font-weight: 500;
                text-align: left;
                position: sticky;
                background-color: var(--main_table_header_bg);
                z-index: 1;
                top: -2px; /* Don't forget this, required for the stickiness */
                box-shadow: inset 0 -2px 0 #ffffff;
                .checkboxContainer {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                }
            }

            td {
                .ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@import 'styles/varibles';
@import 'styles/colors';

$toggleMargin: 48px;
$nameWidth: 300px;
$statusWidth: 150px;

.wrapper {
    margin: 8px 0 2rem 0;
    z-index: 0;

    .container {
        margin: 1.6rem 2.4rem;
        background: var(--modal_base-bg);
        padding: 1.6rem;
        border-radius: 8px;

        .header {
            display: flex;

            .grouped {
                flex: 1;
                display: flex;
                flex-direction: column;
                background: var(--modal_base-bg);
                font-size: 1.4rem;

                .groupTitle {
                    height: 48px;
                    font-weight: bold;
                    @include flex-center;
                }

                .groupItems {
                    @include flex-center;

                    .item {
                        height: 48px;
                        flex: 1;
                        padding: 8px;

                        &.name {
                            flex: initial;
                            width: $nameWidth;
                        }

                        &.status {
                            flex: initial;
                            width: $statusWidth;
                        }
                    }
                }

                &.baseline {
                    background: var(--phase-performance-baseline-bg);
                    flex: 1;
                }

                &.typical {
                    background: var(--phase-performance-typical-bg);
                    flex: 1;
                }
            }

            .toggle {
                width: $toggleMargin;
                @include flex-center;

                .toggleIcon {
                    font-size: 2.8rem;
                    color: $iconColor;
                }
            }
        }

        .rows {
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            margin-top: 0.8rem;

            .row {
                border: solid 1px #909090;
                border-radius: 8px;
                font-size: 1.2rem;
                display: flex;
                flex-direction: column;

                &.projectTypical {
                    background: var(--phase-performance-typical-bg);
                }

                .rowContainer {
                    &.error {
                        background: $errorBackground;
                        border-radius: 8px;
                        border: solid 1px $errorBackground;
                    }

                    display: flex;

                    .grouped {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        font-size: 1.4rem;
                        justify-content: center;

                        .groupTitle {
                            height: 48px;
                            font-weight: bold;
                            @include flex-center;
                        }

                        .groupItems {
                            @include flex-center;

                            .item {
                                &.error {
                                    color: $errorColor;
                                    font-weight: bold;
                                    display: flex;
                                    justify-content: space-between;

                                    .message {
                                        display: flex;
                                        align-items: center;
                                        gap: 0.8rem;
                                    }
                                }

                                flex: 1;
                                padding: 8px;
                                align-items: center;

                                &.name {
                                    flex: initial;
                                    width: $nameWidth;

                                    .projectName {
                                        font-size: 1.6rem;
                                        font-weight: bold;
                                    }

                                    .projectDate {
                                        padding: 8px 0 8px 0;
                                        font-size: 1.2rem;
                                    }
                                }

                                &.status {
                                    flex: initial;
                                    width: $statusWidth;
                                }

                                &.negative {
                                    color: $greenStatusColor;
                                }

                                &.positive {
                                    color: $redStatusColor;
                                }
                            }
                        }

                        &.baseline {
                            background: var(--phase-performance-baseline-bg);
                            flex: 1;
                        }

                        &.typical {
                            background: var(--phase-performance-typical-bg);
                            flex: 1;
                        }

                        &.projectTypical {
                            background: var(--phase-performance-typical-bg);
                        }
                    }

                    .toggle {
                        width: $toggleMargin;
                        @include flex-center;

                        .toggleIcon {
                            font-size: 2.8rem;
                            color: $iconColor;
                        }
                    }
                }

                .toggleContainer {
                    margin-right: $toggleMargin;
                    display: flex;
                    border-top: solid 1px #eaecf0;

                    .grouped {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        font-size: 1.4rem;

                        .groupTitle {
                            height: 48px;
                            font-weight: bold;
                            @include flex-center;
                        }

                        .groupItems {
                            @include flex-center;

                            .item {
                                height: 48px;
                                flex: 1;
                                padding: 8px;
                                display: flex;
                                gap: 0.8rem;
                                align-items: center;

                                &.negative {
                                    color: $greenStatusColor;
                                }

                                &.positive {
                                    color: $redStatusColor;
                                }

                                &.name {
                                    flex: initial;
                                    width: $nameWidth;
                                    .nameContent {
                                        .currentPhase {
                                            margin-top: 0.4rem;
                                            font-size: 1.2rem;
                                        }
                                    }
                                }

                                &.status {
                                    flex: initial;
                                    width: $statusWidth;
                                }
                            }
                        }

                        &.baseline {
                            background: var(--phase-performance-baseline-bg);
                            flex: 1;
                        }

                        &.typical {
                            background: var(--phase-performance-typical-bg);
                            flex: 1;
                        }

                        &.projectTypical {
                            background: var(--phase-performance-typical-bg);
                        }
                    }
                }
            }
        }
    }
}

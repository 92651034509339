@import 'styles/colors';

.cardWidgetContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.2rem;

    .statusCustomSelectContainer {
        width: 13.9rem;
        height: 2.8rem;
    }

    .statusCustomSelectItem {
        font-size: 1.5rem;
        font-weight: bold;
        width: 100%;
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: $todoCardBackground;
        color: $color-white;
        cursor: pointer;
        position: relative;

        &.TODO {
            background: $todoCardBackground;
        }

        &.IN_PROGRESS {
            background: $inProgressCardBackground;
        }

        &.DONE {
            background: $doneCardBackground;
            z-index: 10;
        }

        .arrowIconWrapper {
            margin-left: 0.8rem;
            position: absolute;
            right: 0.8rem;
            display: none;
        }

        .editedStatusIconWrapper {
            margin-left: 0.8rem;
            position: absolute;
            left: 0;
            top: 0.6rem;
            display: none;
        }
    }

    .statusCustomSelectItem:nth-child(1) {
        .arrowIconWrapper {
            display: block;
        }
        .editedStatusIconWrapper {
            display: block;
        }
    }

    .statusCustomSelectItem:not(:first-child) {
        margin-top: 0.6rem;
    }

    .statusCustomSelectItem:nth-child(3) {
        box-shadow:
            rgba(0, 0, 0, 0.3) 0px 19px 38px,
            rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
}

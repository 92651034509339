@import 'styles/colors';
@import 'styles/varibles';

.container {
    @include flex-center;
    gap: 8px;
}
.icon {
    display: flex;
}

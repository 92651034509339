.singlePhaseContainer {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding-left: 1.6rem;

    .number {
        position: relative;
        .lineBetweenNumbers {
            border-left: 2px solid var(--phase_performance_box_border);
            height: 172px;
            position: absolute;
            top: 4rem;
            left: 2rem;

            &.lastPhase {
                height: 96px;
                border-left: 2px dashed var(--phase_performance_box_border);
            }
        }
    }

    .content {
        flex: 1;
        border-radius: 1.6rem;
        border: solid 2px var(--phase_performance_box_border);
        padding: 3.2rem 1.6rem 1.6rem 1.6rem;

        .errorSpace {
            height: 22px;
        }

        .gateNames {
            margin-top: 1.6rem;
            display: flex;
            align-items: center;
            gap: 1.6rem;

            .inputWrapper {
                flex: 1;

                .disabledInput {
                    background-color: var(--phase_performance_disabled_input);
                }
                .expectedDurationWrapper {
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                    .expectedDuration {
                        padding-right: 3.5rem;
                    }
                }
            }

            .arrowIconWrapper {
                margin-bottom: 2rem;
            }
        }
    }
}

@import 'styles/colors';

.taskInfo {
    font-size: 1.6rem;
    padding: 8px;

    .header {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 0.8rem;
        padding-bottom: 8px;
        border-bottom: solid 1px $boxBorder;
    }
    .item {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        .line {
            display: flex;
            gap: 0.8rem;
            .label {
                font-weight: 700;
                width: 16rem;
                text-transform: capitalize;
            }
            .value {
                color: #656565;
                font-weight: 400;
            }
        }
    }
}

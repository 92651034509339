@import 'styles/colors';

.cardItem {
    display: flex;
    flex-direction: column;
    &:hover {
        .cardItemContent {
            .cardItemContentLink {
                .infoTooltip {
                    display: block;
                }
            }
        }
    }
    .cardItemContent {
        display: flex;
        justify-content: space-between;
        height: 46px;
        padding-left: 0.8rem;
        padding-right: 8px;
        align-items: center;
        gap: 8px;

        .cardItemContentLink {
            display: flex;
            gap: 0.8rem;
            align-items: center;
            min-width: 0;
            .itemTitle {
                align-items: center;
                font-size: 1.6rem;
                line-height: 2rem;
                gap: 0.8rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
            }
            .infoTooltip {
                display: none;
            }
        }

        &.isLink {
            .cardItemContentLink {
                a {
                    color: var(--text_color);
                }
            }

            &:hover {
                color: $light-blue-color;
                transition: 0.3s;
                background: var(--select_menu_hover_background);

                a {
                    color: var(--link_color);
                }
            }
        }
    }
}

@import 'styles/colors.scss';

.avatar {
    background-color: $defaultAvatarBackground;
    width: 3.6rem;
    height: 3.6rem;
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.unAssigned {
        background-color: $unAssignedAvatarBackground;
    }

    &.color-0 {
        background-color: $avatar_color_0;
    }
    &.color-1 {
        background-color: $avatar_color_1;
    }
    &.color-2 {
        background-color: $avatar_color_2;
    }
    &.color-3 {
        background-color: $avatar_color_3;
    }
    &.color-4 {
        background-color: $avatar_color_4;
    }
    &.color-5 {
        background-color: $avatar_color_5;
    }
    &.color-6 {
        background-color: $avatar_color_6;
    }
    &.color-7 {
        background-color: $avatar_color_7;
    }
    &.color-8 {
        background-color: $avatar_color_8;
    }
    &.color-9 {
        background-color: $avatar_color_9;
    }
}

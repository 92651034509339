.copyToContainer {
    height: 45px;
    display: flex;
    align-items: center;

    .newSubprogramContainer {
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        align-items: center;
        width: 100%;

        .breadcrumbItem {
            font-size: 2rem;
            font-weight: 400;
            color: var(--breadcrumbs_text_color);
        }

        .newSubprogramActions {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
        }
    }
    .currentSubprogramContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        .treeCrumbsContainer {
            flex: 1;
        }
    }
}

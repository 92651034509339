@import 'styles/colors';

.b-gantt-task-tooltip {
    .b-tooltip-body-wrap {
        .b-tooltip-content {
            width: 350px;
        }
    }
}

.b-gantt-task {
    &.critical-path-milestone {
        .b-gantt-task-content {
            background-color: $redStatusColor;
        }
    }
}

.b-gantt-task-wrap {
    &.b-sch-color-in-progress {
        .b-gantt-task {
            background-color: $todoCardBackground;
            .b-task-percent-bar {
                background-color: $inProgressCardBackground;
            }
        }
    }
    .critical-path-task {
        &:not(.b-milestone) {
            &.b-gantt-task {
                border: solid 4px $redStatusColor;
            }
        }
        &.b-milestone {
            .b-gantt-task-content {
                background-color: $redStatusColor;
            }
        }
    }
}

.b-sch-dependency {
    &.veryImportant {
        stroke: $redStatusColor;
        stroke-width: 2;
    }
}

.b-task-baseline {
    &.b-baseline-ahead {
        background-color: lightblue;
    }
    &.b-baseline-behind {
        background-color: indianred;
    }
    &.b-baseline-on-time {
        background-color: lightblue;
    }
}

.change-color {
    font-weight: bold;
    &.late {
        color: indianred;
    }
    &.onTime {
        color: lightblue;
    }
}

.b-tree-cell-value {
    cursor: pointer;
}

@import 'styles/colors';
@import 'styles/varibles';

.boxWrapper {
    width: 100%;
    height: 468px;
    display: flex;
    justify-content: center;
    .clusterStatisticsContainer {
        width: 100%;
        padding: 3.2rem;
        .clusterStatisticsText {
            margin-bottom: 40px;
        }
    }
    p {
        margin-bottom: 1.8rem;
    }

    .highlight {
        color: var(--link_color);
        font-weight: bold;
    }
}

#ClusterStatisticsTable {
    width: 100%;

    border-radius: 8px;
    overflow: hidden;

    // Necessary for round edges of rows, see https://dev.to/temmietope/rounded-edges-on-table-rows-1d0n
    border-collapse: collapse;

    .textMargin {
        margin-top: 0.8rem;
    }

    th {
        text-align: left;
        font-weight: bold;
        vertical-align: middle;
    }

    thead {
        tr {
            border-bottom: 2px solid var(--text_color);
            @include media-laptop {
                font-size: 1.4rem;
            }
        }
    }

    // Set border-radius on the top-left and bottom-left of the first table data on the table row
    td:first-child,
    th:first-child {
        border-radius: 4px 0 0 4px;
    }

    // Set border-radius on the top-right and bottom-right of the last table data on the table row
    td:last-child,
    th:last-child {
        border-radius: 0 4px 4px 0;
    }

    tr {
        height: 73px;
        padding-left: 1.7rem;
        border-radius: 8px;

        &.spacer {
            background: transparent;
            height: 8px;
        }

        &.dark {
            background-color: var(--table_odd_color);
        }

        &.light {
            background-color: var(--table_even_color);
        }
    }

    td {
        vertical-align: middle;

        &:nth-child(n + 2) {
            color: var(--link_color);
            font-weight: bold;
            font-size: 1.8rem;
            @include media-laptop {
                font-size: 1.4rem;
            }
        }

        &:nth-child(1) {
            padding-left: 1.7rem;
            font-size: 1.6rem;
            @include media-laptop {
                font-size: 1.4rem;
            }
        }
    }
}

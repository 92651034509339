@import 'styles/colors';
.tableDateRangeFilterContainer {
    .label {
        width: 40rem;
        background-color: var(--my_custom_select_bg);
        color: var(--my_custom_select_text_color);
        border: 1px solid var(--my_custom_select_border_color);
        font-weight: normal;
        height: 4rem;
        border-radius: 8px;
        padding: 0.5rem 5.5rem 0.7rem 0.8rem;
        display: flex;
        align-items: center;
    }
}

.dateRangePopoverContentWrapper {
    background-color: var(--select_menu_background);
    .form {
        height: 100%;
        display: flex;
        flex-direction: column;

        .mainContent {
            flex: 1;
            padding: 16px;
            min-height: 175px;

            .sectionWrapper {
                margin-top: 16px;
                display: flex;
                align-items: flex-start;
                gap: 16px;
                font-size: 1.5rem;
                color: var(--card-modal-title-color);

                .section {
                    .title {
                        font-weight: bold;
                    }
                    .selectionWrapper {
                        display: flex;
                        gap: 8px;

                        .singleSelection {
                            display: flex;
                            gap: 8px;
                            align-items: center;
                        }
                    }
                }

                .wholeProjectSection {
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    font-weight: bold;
                }
            }

            .errorsWrapper {
                margin-top: 16px;
            }
        }

        .footer {
            height: 80px;
            display: flex;
            justify-content: flex-end;
            border-top: 1px solid $divider;
            padding: 0 28px;

            .actionButtons {
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }
        }
    }
}

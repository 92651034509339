.NoPermissionContainer {
    line-height: 2rem;
    display: flex;
    padding: 24px 16px;
    .iconWrapper {
        background: red;
        border-radius: 28px;
        align-self: center;
        padding: 5px 3px;
        margin: 5px;
        span {
            margin: 5px;
            font-size: 2rem;
        }
    }
}

@import 'styles/colors.scss';
.assigneeAvatarWithSelectionContainer {
    cursor: pointer;
}
.disabled {
    cursor: default;
}

.assigneeListContainer {
    min-width: 15rem;
    max-height: 30rem;
    padding-bottom: 4px;
    padding-top: 4px;

    .listItem {
        width: 100%;
        font-style: normal;
        font-weight: 300;
        font-size: 1.6rem;
        line-height: 1.6rem;
        transition: 0.3s;
        padding: 8px 1.2rem;
        color: var(--text_color);
        display: flex;
        align-items: center;
        gap: 16px;

        &.active {
            background: var(--select_menu_hover_background);
            color: var(--select_menu_hover_color);
        }

        &:hover {
            color: var(--select_menu_hover_color);
            background: var(--select_menu_hover_background);
            cursor: pointer;
        }

        &:nth-of-type(2) {
            border-bottom: 1px dotted var(--text_color);
            padding-bottom: 1rem;
            padding-top: 1rem;
            margin-bottom: 5px;
        }
    }
}

.summaryRow {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    gap: 3.2rem;

    .summaryRowItem {
        display: flex;
        flex-direction: column;
        padding: 1.2rem;
        align-items: center;
        flex: 1;
        background-color: var(--summary_row_boxes_bg);
        border-radius: 8px;
        margin: 0;
        gap: 1.6rem;
        border: 1px solid var(--widget_border_color);
    }
}

.dataValueContainer {
    display: flex;
    align-items: baseline;
    .plannedFinishDateChange {
        padding-left: 0.8rem;
        font-size: 1.4rem;
        color: var(--status_summary_red);
    }
}

.box {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;
    flex: 1;
}

.toolTip {
    .toolTipTitle {
        font-size: 1.6rem;
        &.red {
            color: var(--status_summary_red);
        }

        &.green {
            color: var(--status_summary_green);
        }

        &.yellow {
            color: var(--status_summary_orange);
        }
    }
    .plannedActual {
        display: flex;
        gap: 1.6rem;
        font-size: 1.6rem;
        .planned {
            padding-left: 4px;
            color: var(--status_summary_blue);
        }
        .actual {
            padding-left: 4px;
            color: var(--status_summary_green);
        }
    }
}

.smallTitle {
    font-size: 1.8rem;
    font-weight: 400;
}

.title {
    font-size: 2rem;
    font-weight: 500;

    &.red {
        color: var(--status_summary_red);
    }

    &.green {
        color: var(--status_summary_green);
    }

    &.amber {
        color: var(--status_summary_amber);
    }

    &.yellow {
        color: var(--status_summary_orange);
    }
}

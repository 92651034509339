.container {
    margin-bottom: 22px;
    display: flex;
    background-color: var(--widget_bg);
    border-radius: 1.6rem;
    border: 1px solid var(--widget_border_color);
    .form {
        flex: 1;
    }
    .actions {
        display: flex;
        gap: 0.8rem;
        padding: 4rem 1.8rem 0 0;
    }

    .globalFilterComponentContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 3.2rem;
        padding: 1.6rem 1.8rem;
        z-index: 5;

        .selectWrapper {
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
            .title {
                position: relative;
                display: flex;
                align-items: center;
                gap: 0.8rem;
                .infoIcon {
                    position: absolute;
                    left: 11.8rem;
                }
            }

            .activityCodesSelectWrapper {
                min-width: 2rem;
            }
        }
    }
}

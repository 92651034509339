@import 'styles/colors';

.monthDatePickerSelectorContainer {
    .content {
        font: inherit;
        cursor: pointer;
        outline: inherit;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: transparent;
        color: var(--text_color);
        border: 2px solid var(--my_custom_select_border_color);
        font-weight: normal;
        width: 100%;
        height: 4rem;
        border-radius: 1.6rem;
        padding: 5px 8px;

        .iconsWrapper {
            display: flex;
            align-items: center;

            .clear {
                margin-top: 2px;
            }
        }

        &.noFiles {
            color: $PlaceHolderGrayColor;
        }
    }

    button:focus {
        outline: 0;
    }
}

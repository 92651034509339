@import 'styles/varibles';

.MainTable {
    max-height: $mainTableMaxHeight;

    .fixedSizeList {
        .sticky {
            position: sticky;
            z-index: 2;
        }
        @include custom-scroll;
    }
}

.headerRow {
    height: 100%;
    border-bottom: 2px solid var(--widget__bottom_border_color);
    background-color: var(--main_table_header_bg);
    padding: 1.2rem 0;
    display: flex;
}

.headerCell {
    display: table-cell;
    font-size: 1.6rem;
    font-weight: 500;
    user-select: none;
    position: relative; // needed for sorting-caret to be in the right place
    padding: 0 1.6rem;
}

.tableRowContainer {
    padding: 1.6rem 0 0 0;
    background-color: transparent;
    .tableRow {
        display: flex;
        border-radius: 4px;
        padding: 1.6rem 0;
        border: var(--main_table_border);
        &.odd {
            background-color: var(--table_odd_color);
        }
        &.even {
            background-color: var(--table_even_color);
        }
        &.selected {
            background-color: var(--my_focus_header_bg);
        }
        .dataCell {
            font-size: 1.6rem;
            position: relative;
            padding: 0 1.6rem;
            .group {
                display: flex;
                align-items: center;
                gap: 0.8rem;
                .toggleIcon {
                    width: 4rem;
                }
            }
        }
    }
}

@import 'styles/colors';

.tableContainer {
    padding: 1.6rem;
}

.duration {
    margin-bottom: 5px;
}

.adherence {
    font-size: 1.4rem;
    font-weight: bold;
    color: $versionCompareDefaultGrayColor;
    &.red {
        color: var(--status_summary_red);
    }
    &.green {
        color: var(--status_summary_green);
    }
}
.result {
    font-size: 1.7rem;
    font-weight: 700;
}

.subAdherence {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4rem;
    margin-top: 0.4rem;
}

.subTitle {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4rem;
    margin-top: 0.4rem;
    color: $versionCompareDefaultGrayColor;
}

.progressBarTitle {
    margin-right: 1.2rem;
}
.projectCell {
    width: 100%;
}

.widgetCardsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 1.6rem;
    position: relative;
    gap: 1.6rem;
}

.subProjectTitle {
    width: 100%;
    font-weight: 400;
    font-size: 1.6rem;
    margin: 1.5rem 8px 0 1.6rem;
    color: $colorGray;
    .totalCount {
        font-weight: bold;
    }
}

.projectFilterInput {
    background: transparent;
    color: var(--table_text_color);
    outline: none;
    border: solid 1px var(--input_filter_border);
    border-radius: 1rem;
    width: 60%;
    font-size: 1.2rem;
    padding: 4px 8px;
    margin: 8px 0 2rem 0;
    &::placeholder {
        color: $PlaceHolderGrayColor;
    }
}

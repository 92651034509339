@import 'styles/varibles';
@import 'styles/colors';

.errorMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .copyErrorText {
        color: lightblue;
        font-size: 1.2rem;
    }
}

.actionMenuLink {
    text-decoration: none;
    color: var(--select_menu_color);
    width: 100%;

    &:hover {
        color: var(--select_menu_hover_color);
        text-decoration: none;
    }
}

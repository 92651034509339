@import 'styles/colors.scss';

.baselineVsActualContainer {
    padding: 1.6rem;

    .topSection {
        display: flex;

        .leftSide {
            flex: 5;
            margin-right: 1.6rem;
            background-color: var(--baseline_left_side_bg_color);
            padding: 1.6rem;
            border: 1px solid var(--widget_border_color);
            border-radius: 1.2rem;

            .progressBarWrapper {
                display: flex;
                align-items: baseline;
            }

            .progressBarTitle {
                color: var(--text_color);
                line-height: 1.33;
                font-weight: 500;
                font-size: 2rem;
                margin-bottom: 2.5rem;
                width: 5.3125em;
            }

            .baseLineText {
                color: var(--base_line_text_color);
                font-size: 3rem;
            }

            .actualText {
                color: var(--actual_text_color);
                font-size: 3rem;
            }

            .progressSelection {
                color: #7489aa;
                font-size: 1.8rem;
                form {
                    color: #7489aa !important;
                }

                display: flex;
                margin-bottom: 1.6rem;

                .radioContainer {
                    margin-left: 2.4rem;
                    display: flex;
                    gap: 1.6rem;
                }
            }
        }

        .rightSide {
            display: flex;
            justify-content: flex-end;
            gap: 1.6rem;
            .box {
                width: 23rem;
                background-color: var(--baseline_right_side_box_bg_color);
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;
                padding: 4rem 1.6rem 1.6rem;
                border: 1px solid var(--widget_border_color);
                border-radius: 1.2rem;
            }

            .current {
                text-transform: capitalize;

                .title {
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: 0.3.6rem;
                }

                .value {
                    font-size: 3.2rem;
                    font-weight: 500;
                    color: var(--base_line_actual_value_color);
                    margin-top: 0.8rem;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                }
                .capitalize {
                    text-transform: capitalize;
                }
            }
            .link {
                font-size: 1.4rem;
                padding-top: 2.4rem;
                color: var(--link_color);
            }
            .schedule {
                text-transform: capitalize;

                .title {
                    font-size: 1.8rem;
                    line-height: 2.4rem;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: 0.3.6rem;
                }

                .value {
                    font-size: 3.2rem;
                    font-weight: 500;
                    margin-top: 0.8rem;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;

                    &.red {
                        color: $redStatusColor;
                    }

                    &.green {
                        color: $greenStatusColor;
                    }

                    &.amber {
                        color: $amberStatusColor;
                    }
                }
            }
        }
    }

    .tableSection {
        margin-top: 3.2rem;

        table {
            width: 100%;

            border-radius: 8px;
            overflow: hidden;

            // Necessary for round edges of rows, see https://dev.to/temmietope/rounded-edges-on-table-rows-1d0n
            border-collapse: collapse;

            th {
                font-weight: normal;
                padding: 0 1.6rem;
                vertical-align: middle;
                text-transform: capitalize;
                text-align: left;
            }

            thead {
                tr {
                    border-bottom: solid 2px var(--widget__bottom_border_color);
                }
            }

            // Set border-radius on the top-left and bottom-left of the first table data on the table row
            td:first-child,
            th:first-child {
                border-radius: 4px 0 0 4px;
            }

            // Set border-radius on the top-right and bottom-right of the last table data on the table row
            td:last-child,
            th:last-child {
                border-radius: 0 4px 4px 0;
            }

            tr {
                height: 60px;
                padding-left: 1.7rem;
                border-radius: 8px;

                &.spacer {
                    background: transparent;
                    height: 8px;
                }

                &.baseline {
                    background-image: var(--base_line_background_image);
                    background-color: var(--base_line_background_color);
                    color: $color-white;
                }

                &.latest {
                    background-image: var(--latest_background_image);
                    background-color: var(--latest_background_color);
                    color: $color-white;
                }
            }

            td {
                padding: 0 1.6rem;
                vertical-align: middle;

                &.noWrap {
                    white-space: nowrap;
                }
            }

            td:first-child {
                font-size: 1.8rem;
                font-weight: 500;
                text-transform: capitalize;
                white-space: nowrap;
            }
        }
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
}

@import 'styles/colors';
.xlsExportModalContainer {
    .title {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .checkBoxSection {
        margin-top: 2.8rem;
        .selectTitle {
            font-weight: 500;
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }
        .checkBoxWrapper {
            margin-top: 8px;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .textAreaContainer {
        margin-top: 2.8rem;
        .selectTitle {
            font-weight: 500;
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }
        .textAreaWrapper {
            width: 40rem;
            height: 15rem;
        }
    }

    .footer {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $divider;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

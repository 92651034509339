.mainFiltersContainer {
    .filtersWidgetWithTitleContainer {
        margin-bottom: 44px;

        .titleComponentsWrapper {
            display: flex;
            gap: 16px;
        }

        .hideButton {
            display: none;
        }
    }

    .filtersContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 8px 16px 16px 16px;
    }
}

.tab {
    font-weight: 400;
    text-transform: capitalize;
    padding: 2.4rem 0 2.4rem 3.2rem;
    .title {
        font-size: 1.8rem;
        color: var(--text_color);
    }

    .subTitle {
        margin-top: 1.2rem;
        font-size: 1.5rem;
        color: var(--customized_tabs_sub_title_color);
    }
}

@import 'styles/colors';
@import 'styles/varibles';

.notificationItem {
    display: flex;
    font-size: 1.6rem;
    margin: 1.4rem 0;

    .iconContainer {
        width: 3rem;

        .milestoneIcon {
            width: 3rem;
            height: 3rem;
            background-color: #7c93ac;
            border-radius: 50%;
            @include flex-center;
        }
    }

    .body {
        flex: 1;
        padding: 0 1.6rem;

        .title {
            color: $color-notifications;
            font-size: 1.5rem;
            margin: 0.4rem 0;
            cursor: pointer;
            word-break: break-word;

            &:hover {
                text-decoration: underline;
            }
        }

        .date {
            color: $color-secondary-title;
            font-size: 1.2rem;
        }
    }

    .isReadContainer {
        width: 3rem;
        display: flex;
        justify-content: center;

        .indicator {
            width: 1.6rem;
            height: 1.6rem;
            border: solid 2px #7489aa;
            border-radius: 50%;
            cursor: pointer;

            &.checked {
                border: none;
                background-color: $color-notifications;
            }
        }
    }
}

@import 'styles/varibles';

.topComponentsRow {
    width: 100%;
    display: flex;

    column-gap: 3.2rem;
    @include media-laptop {
        column-gap: 1.6rem;
    }
}

.activitiesRow {
    width: 100%;
    display: flex;
}

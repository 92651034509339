@import 'styles/colors';
.inputWrapper {
    display: flex;
    position: relative;

    .toggleIcon {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .passwordInput {
        background-color: var(--my_custom_select_bg);
        color: var(--my_custom_select_text_color);
        border: 1px solid var(--my_custom_select_border_color);
        font-weight: normal;
        width: 100%;
        height: 4rem;
        border-radius: 8px;
        padding: 0.5rem 5.5rem 0.7rem 0.8rem;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: $PlaceHolderGrayColor;
            font-weight: 300;
        }
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: #7c838c !important;
    }

    /* placeholder color */
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    ::placeholder {
        opacity: 1; /* Firefox */
    }
}

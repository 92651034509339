@import 'styles/colors';

.activityStatusBarContainer {
    border-radius: 5px;
    width: 100%;
    height: 28px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;

    &.completed,
    &.done {
        background: $doneCardBackground;
        color: $doneCardColor;
    }

    &.notStarted,
    &.toDo {
        background: $todoCardBackground;
        color: $todoCardColor;
    }

    &.inProgress {
        background: $inProgressCardBackground;
        color: $inProgressCardColor;
    }
    &.notFound {
        background: $notFoundBackground;
        color: $notFoundcolor;
    }
}

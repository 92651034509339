@import 'styles/colors';
.bulkRollbackActionContent {
    .title {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .selectTitle {
        font-size: 1.6rem;
        margin-top: 2.8rem;
        .bold {
            font-weight: 500;
        }
    }

    .note {
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 2.8rem;
        color: $color-secondary-title;
    }

    .footer {
        margin-top: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $divider;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

@import 'styles/colors';
@import 'styles/varibles';

.singleGate {
    margin-top: 2rem;
    display: flex;
    gap: 1.6rem;

    .number {
        margin-top: 1.6rem;
        position: relative;
        .lineBetweenNumbers {
            border-left: 2px solid var(--phase_performance_box_border);
            height: 174px;
            position: absolute;
            top: 4rem;
            left: 2rem;

            &.lastPhase {
                height: 0;
            }
        }
    }

    .content {
        flex: 1;
        border-radius: 1.6rem;
        border: solid 2px var(--phase_performance_box_border);
        padding: 1.6rem;
        display: flex;
        gap: 1.6rem;

        &.error {
            border: solid 2px red;
        }

        .leftSide {
            flex: 1;

            .title {
                font-weight: bold;
            }

            .gatesWrapper {
                margin-top: 1.6rem;
                display: flex;
                gap: 1.6rem;

                .gate {
                    flex: 1;

                    .gateSelectWrapper {
                        margin-top: 0.8rem;
                        margin-left: -4px;
                    }

                    .phaseDate {
                        margin-top: 2rem;
                        .phaseDateTitle {
                            font-size: 1.2rem;
                            color: $PlaceHolderGrayColor;
                        }

                        .date {
                            margin-top: 0.8rem;
                            font-size: 2.2rem;
                        }
                    }
                }

                .arrowIconWrapper {
                    padding-top: 30px;
                }
            }
        }

        .duration {
            border-left: solid 2px var(--phase_performance_box_border);
            flex-basis: 15%;
            display: flex;
            align-items: center;
            justify-content: center;

            .durationTitle {
                font-size: 1.2rem;
                color: $PlaceHolderGrayColor;
            }

            .durationDate {
                text-align: center;
                margin-top: 0.8rem;
                font-size: 2.2rem;
            }
        }
    }
}

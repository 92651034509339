@import 'styles/colors';
.textSearchInputContainer {
    position: relative;
    .input {
        background-color: var(--my_custom_select_bg);
        color: var(--my_custom_select_text_color);
        border: 1px solid var(--my_custom_select_border_color);
        font-weight: normal;
        width: 100%;
        height: 4rem;
        border-radius: 8px;
        padding: 0.5rem 5.5rem 0.7rem 0.8rem;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: $PlaceHolderGrayColor;
            font-weight: 300;
        }
    }

    .iconsWrapper {
        position: absolute;
        right: 1rem;
        top: 0;
        display: flex;
        align-items: center;
        height: 100%;
        gap: 0.4rem;

        .clearIcon {
            cursor: pointer;
            svg {
                path {
                    fill: #9299aa;
                }
            }
            &:hover {
                svg {
                    path {
                        fill: var(--text_color);
                    }
                }
            }
        }

        .searchIcon {
            cursor: pointer;
            &:hover {
                svg {
                    path {
                        fill: var(--text_color);
                    }
                }
            }
        }
    }
}

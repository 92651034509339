@import 'styles/colors';
@import 'styles/varibles';

.toggleIcon {
    cursor: pointer;
}
.popover {
    margin-top: -16px;
    margin-left: -10px;
}
.userMenuItems {
    background: $color-black;
    width: 28rem;
    padding: 20px 0 10px;
    color: $color-white;

    .userDetails {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px 0;
        .greeting {
            align-items: center;
            display: flex;
            font-size: 2.5rem;
            font-weight: 700;
            overflow: hidden;
        }
    }
    .lastViewed {
        padding: 0 15px 0;
        font-size: 1rem;
        color: $headerPopoverGray;
    }

    hr.breakLine {
        border: 1px solid $headerIconBackGroundColorLight;
        margin: 10px 18px;
    }
    .appVersion {
        font-size: 1.2rem;
        color: $headerPopoverGray;
        margin: 14px;
    }
    .itemWrapper {
        margin: 1px 0;
        padding: 8px 16px;
        font-size: 1.6rem;
        display: flex;
        transition: 0.25s ease-in-out;

        &:hover {
            background-color: $headerIconBackGroundColorLight;
        }

        a {
            text-decoration: none;
            cursor: initial;
        }
        .item {
            display: flex;
            align-items: center;
            gap: 16px;
            color: $color-white;
            font-weight: 300;
        }
    }
}

.titleActions {
    padding-left: 1.6rem;
    flex: 1;
}
.container {
    padding: 1.6rem;
    position: relative;
    .row {
        display: flex;
        margin: 3.2rem 0;
        align-items: center;
        .label {
            width: 16rem;
            font-weight: bold;
        }
        .inputWrapper {
            flex: 0 100 max-content;
        }
        .input {
            width: 35rem;
        }
        .actionWrapper {
            padding-left: 16rem;
            display: flex;
            gap: 0.8rem;
        }
    }
    .actions {
        gap: 0.8rem;
        display: flex;
    }
}

@import 'styles/varibles';

.container {
    flex: 1;
    display: flex;
    overflow-y: hidden;
    z-index: 0;
    .leftSide {
        width: 28rem;
        position: relative;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        scrollbar-gutter: stable;
        @include custom-scroll;
        @include media-laptop {
            width: 85px;
        }

        &.collapse {
            width: 85px;
            .collapseIcon {
                right: 16px;
                transform: rotate(180deg);
                @include media-laptop {
                    right: 20px;
                    transform: rotate(180deg);
                }
            }
        }
        .collapseIcon {
            position: absolute;
            right: 6px;
            top: 8px;
            z-index: 1;
            transition: transform 0.2s;
        }
    }
    .rightSide {
        flex: 1;
        overflow-y: auto;
    }
}

.green {
    color: var(--status_summary_green);
}

.yellow {
    color: var(--status_summary_orange);
}

.red {
    color: var(--status_summary_red);
}

.turquoise {
    color: var(--link_color);
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.row {
    padding: 2.4rem 3.2rem;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    align-items: center;
    flex: 1;
    padding: 1.2rem 0;
}

@import 'styles/varibles';

.boxWrapper {
    padding: 1.6rem;
    height: 468px;
    .text {
        height: 100%;
        @include flex-center;
    }
}

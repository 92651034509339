@import 'styles/varibles';
.simulationWarningsContainer {
    color: var(--text_color);
    background-color: var(--my_focus_header_bg);
    padding: 1.6rem;
    border-radius: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 20px;

    .headerContainer {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .headline {
        font-size: 1.8rem;
        font-weight: 500;
    }
    .subHeadline {
        margin-top: 10px;
        font-size: 1.6rem;
        font-weight: 500;
    }
    .orderedListContainer {
        list-style-type: decimal;
    }
    .content {
        padding-left: 30px;
    }
    .link {
        display: inline-block;
        text-decoration: none;
        color: var(--link_color);
        cursor: pointer;
    }
    .button {
        margin-left: 5px;
        color: var(--activity-log-date);
        &:hover {
            cursor: pointer;
        }
    }
    .disclaimer {
        margin-top: 2rem;
        font-size: 1.6rem;
        font-weight: 500;
    }
}

@import 'styles/colors';

.wrapper {
    text-align: center;
    color: $login-gray-text;
    font-size: 2.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    justify-content: space-between;
    white-space: nowrap;
    padding: 3.6rem 0;
    line-height: 3.2rem;
}

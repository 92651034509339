@import 'styles/varibles.scss';

.CrossVersionsAnalysisGraphContainer {
    padding: 1.6rem;
    .selectParametersWrapper {
        position: relative;
        margin: 0 0 48px 0;
        .radiosRow {
            display: flex;
            align-items: center;
            > span {
                font-size: 1.6rem;
                font-weight: 500;
                margin-right: 8px;
            }

            .radioContainer {
                display: flex;
                gap: 1.6rem;
            }
        }
        .selectRow {
            margin: 1.6rem 0;
            min-width: 400px;
            width: max-content;
        }
        .lookForContainer {
            display: flex;
            .reportIcon {
                margin-top: -0.4rem;
                margin-right: 0.4rem;
            }
        }
    }
}

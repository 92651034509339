@import 'styles/varibles';
@import 'styles/colors';

.PrioritiesMatrix {
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 16px;
    .matrix {
        flex: 1.1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        position: relative;
        .top {
            flex: 1;
            display: flex;
            gap: 16px;
            .first {
                width: 75px;
            }
            .title {
                background-color: var(--priorities_matrix_label_bg);
                color: var(--text_color);
                border: 1px solid var(--priorities_matrix_label_border);
                border-radius: 8px;
                height: 75px;
                font-size: 1.8rem;
                font-weight: 500;
                flex: 1;
                text-transform: uppercase;
                @include flex-center;
            }
        }
        .main {
            flex: 1;
            display: flex;
            gap: 16px;
            .title {
                background-color: var(--priorities_matrix_label_bg);
                color: var(--text_color);
                border-radius: 8px;
                border: 1px solid var(--priorities_matrix_label_border);
                width: 75px;
                height: 290px;
                font-size: clamp(14px, 1vw, 1.8rem);
                font-weight: 500;
                text-transform: uppercase;
                white-space: nowrap;
                @include flex-center;

                span {
                    transform: rotate(-90deg);
                }
            }
            .linksBox {
                flex: 1;
                border-radius: 8px;
                gap: 16px;
                padding: 16px;
                @include flex-center;
                flex-direction: column;
                &.doNow {
                    background: var(--priorities_matrix_do_now);
                }
                &.doNext {
                    background: var(--priorities_matrix_do_next);
                }
                &.prioritize {
                    background: var(--priorities_matrix_prioritize);
                }
                &.later {
                    background: var(--priorities_matrix_later);
                }
            }
        }
    }
    .cards {
        flex: 0.9;
        display: flex;
        flex-direction: column;
        position: relative;
        .cardsHeader {
            border-radius: 8px;
            height: 100%;
            flex: 1;
            &.doNowComponent {
                background: var(--priorities_matrix_do_now);
            }
            &.doNextComponent {
                background: var(--priorities_matrix_do_next);
            }
            &.prioritizeComponent {
                background: var(--priorities_matrix_prioritize);
            }
            &.laterComponent {
                background: var(--priorities_matrix_later);
            }
            .rowActions {
                padding: 8px 16px 8px 32px;
                font-size: clamp(10px, 1vw, 14px);
                display: flex;
                justify-content: space-between;
                color: $color-white;
                a {
                    color: $dark-theme-link-color;
                }
            }
            .cardsHeaderTitle {
                padding: 8px 16px 8px 32px;
                font-weight: 600;
                font-size: clamp(14px, 1vw, 18px);
                color: $color-white;
                .cardsHeaderTitleType {
                    text-transform: uppercase;
                }
            }

            .pills {
                display: flex;
                gap: 16px;
                margin-top: 0.8rem;
                padding: 0 32px;

                .pillWrapper {
                    cursor: pointer;
                    display: flex;
                    gap: 6px;

                    .pillsTitle {
                        white-space: nowrap;
                        @include media-laptop {
                            display: none;
                        }
                    }

                    &:hover {
                        &.doNowComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_do_now), 0 0 0 4px #ffffff;
                        }
                        &.doNextComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_do_next), 0 0 0 4px #ffffff;
                        }
                        &.prioritizeComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_prioritize), 0 0 0 4px #ffffff;
                        }
                        &.laterComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_later), 0 0 0 4px #ffffff;
                        }
                    }

                    &.activeFilter {
                        &.doNowComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_do_now), 0 0 0 4px #ffffff;
                        }
                        &.doNextComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_do_next), 0 0 0 4px #ffffff;
                        }
                        &.prioritizeComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_prioritize), 0 0 0 4px #ffffff;
                        }
                        &.laterComponent {
                            box-shadow: 0 0 0 2px var(--priorities_matrix_later), 0 0 0 4px #ffffff;
                        }
                    }
                }
            }
        }
        .cardsContainer {
            border-radius: 8px;
            height: 590px;
            width: 100%;
            background: transparent;
            position: absolute;
            bottom: 0;
            @include media-laptop {
                height: 600px;
            }
            &.isLoading {
                overflow: hidden;
            }
            .variableSizeList {
                scrollbar-gutter: stable;
                overflow: auto;
                @include custom-scroll;
                .item {
                    padding: 0 16px;
                    a:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

@import 'styles/colors.scss';
@import 'styles/varibles.scss';

.sideDashboardMenuContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: var(--side_menu_bg);
    padding-top: 4px;
    border-right: 1px solid var(--side_menu_border_right);

    & .sideDashboardMenuWrapper {
        flex: 1;

        .sideDashboardMenuTop {
            font-size: 2.3rem;
            margin: 1.6rem 1rem 1.8rem 0;
            white-space: nowrap;
            padding: 0 0 0 2.4rem;
            height: 2.3rem;
            color: var(--text_color);
            &.collapse {
                .title {
                    display: none;
                }
            }
            .title {
                @include media-laptop {
                    display: none;
                }
            }
        }
    }
}

.sideDashboardMenuItem {
    position: relative;
    display: flex;
    align-items: center;

    .setHomePage {
        position: absolute;
        right: 0;
        opacity: 0;
    }

    &:hover {
        .setHomePage {
            animation: 250ms ease 700ms normal forwards 1 hoverOut;
        }
    }

    > div {
        flex: 1;
        .navItem {
            flex: 1;
            display: flex;
            border-bottom: 1px solid var(--side_menu_border-bottom);
            padding: 0 2.4rem;
            height: 60px;
            color: var(--side_menu_text_color);
            text-decoration: none;
            align-items: center;
            font-size: 1.8rem;
            gap: 0.8rem;
            font-weight: 500;
            justify-content: flex-start;
            &.collapse {
                justify-content: center;
            }

            &:hover {
                background-color: var(--side_menu_row_bg);
                color: var(--side_menu_active_text_color);
                span {
                    color: var(--side_menu_active_text_color);
                }
                svg {
                    path {
                        fill: var(--side_menu_active_text_color);
                    }
                }
            }

            &:global.active {
                background-color: var(--side_menu_row_bg);
                color: var(--side_menu_active_text_color);
                span {
                    color: var(--side_menu_active_text_color);
                }
                svg {
                    path {
                        fill: var(--side_menu_active_text_color);
                    }
                }
            }

            .title {
                &.collapse {
                    display: none;
                }
                @include media-laptop {
                    display: none;
                }
            }
        }
    }
}

@keyframes hoverOut {
    0% {
        opacity: 0;
        right: -1rem;
    }
    100% {
        opacity: 1;
        right: 1rem;
    }
}

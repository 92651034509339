@import 'styles/colors';

.customizedStatusBarContainer {
    width: 100%;
    height: 10px;
    border-radius: 30px;
    display: flex;
    gap: 1px;
    overflow: hidden;

    .item {
        height: 100%;
    }
}

@import 'styles/varibles';

.link {
    background: rgba(0, 254, 254, 0.1);
    border-radius: 4px;
    height: 46px;
    border: solid 3px transparent;
    width: 100%;
    @include flex-center;
    transition: all 0.2s;
    cursor: pointer;
    color: $color-white;

    &.doNowComponent {
        background: var(--priorities_matrix_do_now_rows_color);
    }

    &.doNextComponent {
        background: var(--priorities_matrix_do_next_rows_color);
    }

    &.prioritizeComponent {
        background: var(--priorities_matrix_prioritize_rows_color);
    }

    &.laterComponent {
        background: var(--priorities_matrix_later_rows_color);
    }

    &.selected,
    &:hover {
        border: solid 3px #ffffff;
    }

    .count {
        flex: 1;
        text-align: center;
    }

    .label {
        flex: 3;
    }

    .colorDots {
        display: flex;
        gap: 0.4rem;
        flex: 0.5;

        .colorDot {
            height: 8px;
            width: 8px;
            left: 0px;
            top: 0px;
            border-radius: 4rem;

            &.yellow {
                background: #ffe78d;
            }

            &.red {
                background: #ff5e67;
            }
        }
    }
}

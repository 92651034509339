.topHeaderButtons {
    display: flex;
    gap: 1.6rem;
}

.formContainer {
    height: 400px;
    width: 100%;
    padding: 1.6rem;
    .note {
    }
    .inputWrapper {
        display: flex;
        gap: 1.6rem;
        align-items: center;
        margin: 3.2rem 0;
        > span {
            display: inline-block;
            width: 240px;
        }
    }
}

.deleteMessage {
    display: flex;
    align-items: center;
    gap: 1rem;

    .title {
        max-width: 400px;
        font-size: 1.6rem;
    }
}

.actionsModal {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.6rem;
    gap: 1rem;
}

.sliderContainer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 2rem;
    background-color: transparent;
    height: 4rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    border: 1px solid var(--validation_config_slider_border);

    .unitLabel {
        margin-left: 6px;
    }

    .verticalBar {
        height: 2.4rem;
        width: 1px;
        background-color: var(--validation_config_slider_border);
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }

    .checkboxWrapper {
        display: flex;
        flex-wrap: nowrap;
        font-family: Roboto;
        font-size: 1.3rem;
        column-gap: 1rem;
    }
}

@import 'styles/colors';
@import 'styles/varibles';

.filtersWrapper {
    width: 100%;
    margin-bottom: 2.4rem;
    .filters {
        display: flex;
        gap: 1.6rem;
        margin-top: 2.4rem;
        align-items: center;
        color: var(--text_color);
    }
}

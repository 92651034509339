.successorNameCell {
    width: 100%;
    .successorName {
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .successorActivityIdId {
        margin-top: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .successorActivityIdName {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

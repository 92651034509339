@import '../../../styles/colors';

/* Absolute Center Spinner */
.overlayWithSpinner {
    position: absolute; //TODO: fix layout of BattleCards so spinner will look good - tried position fixed but it broke other pages
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.overlayWithSpinner:not(:required):after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;
    height: 8rem;
    width: 8rem;
    margin-top: -4rem;
    margin-left: -4rem;
    border-radius: 50%;
    border-top: 0.7rem solid $color-white;
    border-right: 0.7rem solid transparent;
    animation: spinner 0.7s linear infinite;
}

/* Animation */
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

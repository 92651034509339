.cardWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    .topSection {
        .metricsListContainer {
            &.collapse {
                height: 48px;
                overflow: hidden;
            }
        }
    }
    .graphContainer {
        margin-top: 0.8rem;
    }
}

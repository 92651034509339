.ChangePasswordContainer {
    width: 400px;
    position: relative;
    .errorContainer {
        font-size: 1.6rem;
        color: #cc0000;
        font-weight: bold;
        margin-top: 16px;
    }
    .changePasswordTitle {
        text-align: center;
        font-size: 2.6rem;
        font-weight: 500;
    }
    .changePasswordSubTitle {
        font-size: 1.6rem;
        margin: 16px 0;
    }

    .inputWrapperContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 16px;

        .inputWrapper {
            width: 280px;
            .inValidField {
                border: solid 1px #cc0000;
            }
        }
    }
}

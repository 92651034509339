@import 'styles/colors';
.modalContainer {
    width: 58rem;
    max-height: 60vh;

    .title {
        font-weight: bold;
        font-size: 2.2rem;
    }
    .subTitle {
        margin-top: 2rem;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 2rem;
        padding: 0 10px 0 5px;
    }
    .inputTitle {
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 2.8rem;
    }

    .inputWrapper {
        margin-top: 0.8rem;
    }

    .footer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $divider;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

@import 'styles/colors';
.menuListSwitchContainer {
    padding: 1rem;
    border-bottom: 1px dotted var(--text_color);
    margin-bottom: 0.5rem;

    .selectDeselectTitle {
        display: flex;
        color: $light-theme-link-color;
        text-decoration: underline;
        cursor: pointer;
        justify-content: right;
    }
}

.labelWithIconWrapper {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
    }
}

.labelWithAssigneeWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
}

.labelWithExcludeWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    .hoverBox {
        display: flex;
        color: var(--breadcrumbs_text_color);
        gap: 16px;
        visibility: hidden;
    }

    &:hover {
        .hoverBox {
            visibility: visible;
        }
    }

    .includeExcludeTextHover {
        &:hover {
            text-decoration: underline solid var(--link_color);
        }
    }
}

.dotRepeat {
    font-size: 2rem;
}

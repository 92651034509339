@import 'styles/colors.scss';

.rowsBoardWrapper {
    padding: 8px 3.2rem;
}

.rowsBoardHeader {
    display: flex;
    align-items: center;
    width: 100%;

    .actionButtonsWrapper {
        display: flex;
        gap: 0.8rem;
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    align-items: center;
    gap: 40px;
}

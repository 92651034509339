.addSubProgramModalContainer {
    width: 350px;

    .close {
        position: absolute;
        right: -1rem;
        top: -2rem;
        cursor: pointer;
    }

    .title {
        font-weight: bold;
        font-size: 2.2rem;
    }

    .inputTitle {
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 2.8rem;
    }

    .inputWrapper {
        margin-top: 0.8rem;
    }

    .footer {
        margin-top: 2rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #e9eef5;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

@import 'styles/varibles';

.mainContainer {
    width: 600px;
    .title {
        font-weight: bold;
        font-size: 2.2rem;
    }

    .subTitle {
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 2.4rem;
    }

    .project {
        color: var(--text_color);
        font-weight: 400;
        font-size: 1.8rem;
    }

    .projectWrapper {
        padding-top: 2px;
    }

    .content {
        .selectContainer {
            margin-top: 2.8rem;
            display: flex;
            gap: 1.6rem;

            .selectTitle {
                font-weight: 500;
                font-size: 1.6rem;
                padding-top: 8px;
            }
            .selectWrapper {
                width: 400px;
            }
        }
    }

    .footer {
        margin-top: 24px;
        width: 100%;
        display: flex;

        .container {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

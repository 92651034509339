@import 'styles/varibles';

.row {
    padding: 3.2rem 0;
    .box {
        flex: 1;
        flex-direction: column;
        gap: 1.6rem;
        @include flex-center;
        .title {
            font-weight: bold;
            text-transform: capitalize;
        }
        .info {
            font-weight: bold;
            font-size: 2.4rem;
            &.actual {
                color: var(--runrate-past-actual);
            }
            &.planed {
                color: var(--runrate-past-planned);
            }
            &.future {
                color: var(--runrate-future-planned);
            }
        }
    }
}

.cardPillsAndIndicatorsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .pills {
        flex: 2;
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
        align-items: center;
    }
}

.custom-toolbar .my-text-editor .rdw-editor-wrapper .rdw-editor-toolbar div.rdw-block-wrapper,
.custom-toolbar .rdw-editor-wrapper .rdw-editor-toolbar div.rdw-fontsize-wrapper,
.custom-toolbar .rdw-editor-wrapper .rdw-editor-toolbar div.rdw-fontfamily-wrapper,
.custom-toolbar .rdw-editor-wrapper .rdw-editor-toolbar div.rdw-emoji-wrapper,
.custom-toolbar .rdw-option-wrapper[title='Monospace'],
.custom-toolbar .rdw-option-wrapper[title='Subscript'],
.custom-toolbar .rdw-option-wrapper[title='Superscript'],
.custom-toolbar .rdw-option-wrapper[title='Strikethrough'],
.custom-toolbar .rdw-embedded-wrapper[aria-expanded='false'],
.custom-toolbar .rdw-image-wrapper[aria-expanded='false'],
.custom-toolbar .rdw-list-wrapper .rdw-option-wrapper[title='Indent'],
.custom-toolbar .rdw-list-wrapper .rdw-option-wrapper[title='Outdent'],
.custom-toolbar .rdw-block-wrapper,
.custom-toolbar .rdw-embedded-wrapper,
.custom-toolbar .rdw-image-wrapper {
    display: none;
}

.custom-toolbar .rdw-editor-toolbar {
    border: 1px solid var(--my_custom_select_border_color);
    background-color: var(--text-editor-toolbar-bg);
}

.custom-toolbar .rdw-option-wrapper {
    background-color: var(--text-editor-toolbar-icon-bg);
}

.custom-toolbar .DraftEditor-root {
    position: initial;
}
.custom-toolbar .DraftEditor-editorContainer {
    position: initial;
}

.custom-toolbar .public-DraftStyleDefault-block {
    position: initial;
    margin: 0;
}

.custom-toolbar .public-DraftEditorPlaceholder-root {
    position: initial;
}

.custom-toolbar .rdw-suggestion-wrapper {
    position: absolute;
}

.custom-toolbar .rdw-suggestion-dropdown {
    background-color: var(--select_menu_background);
    width: max-content;
}

.custom-toolbar .rdw-suggestion-option {
    border: none;
}

.custom-toolbar .rdw-mention-link {
    color: var(--link_color);
    background-color: transparent;
}

.custom-toolbar .rdw-suggestion-dropdown > span:hover {
    background-color: var(--select_menu_hover_background);
    color: var(--select_menu_hover_color);
}

.custom-toolbar .rdw-editor-wrapper {
    width: 100%;
}

.custom-toolbar .rdw-link-modal {
    background-color: var(--select_menu_background);
}

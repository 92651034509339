.simulationPage {
    height: 100%;
    .simulationSettings {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 50%;

        .dateContainer {
            display: flex;
            align-items: center;
            .dateLabel {
                width: 160px;
            }

            .currentDateStep1 {
                padding-left: 10px;
            }
        }

        .simulationDateContainer {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
    .horizontalLine {
        margin-top: 20px;
        margin-bottom: 20px;
        color: grey;
        width: 100%;
    }

    .footer {
        display: flex;
        justify-content: flex-start;
        max-width: 250px;
    }
}

.tooltipInner {
    max-width: 30vw;
    .colorWrapper {
        display: flex;
        align-items: center;
        .colorIndicator {
            align-self: center;
            min-width: 30px;
            height: 6px;
            border-radius: 1.5rem;
            margin: 5px;
        }
    }
    .linkInformationText {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        .tooltipIconWrapper {
            border-radius: 100%;
            background: rgba(124, 149, 188, 0.6);
            svg path {
                fill: white;
            }
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

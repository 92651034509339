.tooltipCell {
    overflow: hidden;

    & :first-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.copyValueContainer {
    .copyValueIconWrapper {
        background-color: #16191e;
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: rgba(124, 149, 188, 1);

            svg path {
                fill: white;
            }
        }
    }
}

@import 'styles/colors';

.deletedStatusCell {
    font-size: 1.4rem;

    .contentWrapper {
        display: flex;
        gap: 0.8rem;
        align-items: center;

        .statusBar {
            font-size: 1.5rem;
            font-weight: bold;
            width: 100%;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: $notFoundBackground;
            color: $color-white;
            cursor: pointer;
        }
    }

    .wasStatus {
        margin-top: 0.8rem;
        font-weight: 400;
        color: var(--text_color);
    }
}

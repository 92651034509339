.ProgramLibraryUploadFilesContainer {
    .uploadFilesHeader {
        font-size: 2.2rem;
        display: flex;
        align-items: center;

        .uploadFilesHeaderSection {
            margin: 16px 32px 16px 0;
            display: flex;

            .uploadFilesHeaderName {
                color: var(--link_color);
                font-weight: 500;
                display: inline-block;
                margin-left: 8px;

                .ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 600px;
                }
            }
        }
    }

    .uploadFilesHeaderSentences {
        font-size: 1.6rem;
        margin: 16px 0;

        span,
        div {
            margin-right: 16px;
        }
    }
    .isIntegratedProjects {
        visibility: visible;
        &.hide {
            visibility: hidden;
        }
    }

    .integratedComment {
        padding: 20px 0 8px 0;
        line-height: 20px;
        color: var(--status_summary_orange);
    }
    .integrated {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        .input {
            width: 532px;
        }
    }

    .content {
        padding: 24px 0;
    }

    .uploadFilesButtons {
        display: flex;
        margin-top: 24px;
        justify-content: flex-end;
        gap: 1rem;

        input {
            position: absolute;
            top: 0;
            // This is temp solution
            right: 5000px;
            margin: 0;
            padding: 0;
            font-size: 2rem;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);
            width: 148px;
            height: 46px;
        }
    }

    .languagesSelect {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 350px;
        margin-top: 8px;
    }
}

@import 'styles/varibles';
.tableContainer {
    padding: 8px 32px;
    .red {
        color: red;
        font-weight: bold;
    }
    table {
        display: flex;
        flex-flow: column;
        width: 100%;
    }

    thead {
        flex: 0 0 auto;
        tr {
            width: calc(100% - 12px);
        }
    }

    tbody {
        flex: 1 1 auto;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;
        height: 800px;
        @include custom-scroll;
    }

    tr {
        width: 100%;
        display: table;
        table-layout: fixed;
        td {
            border-top: none !important;
            .ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    align-items: center;
    gap: 40px;
}

.filters {
    display: flex;
    align-items: center;
    gap: 16px;
    .selectWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        .title {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    .filtersWrapper {
        display: flex;
        gap: 14px;
        align-items: center;
        height: 3.5rem;

        .filterTitle {
            color: var(--filters_wrapper_text_color);
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.6rem;
            text-align: left;
        }
    }
}

.hasCommentsFilterContainer {
    .inputWidth {
        min-width: 29rem;
    }
}

.popoverContentWrapper {
    display: flex;
    flex-direction: column;
    width: 940px;
    background-color: var(--select_menu_background);

    .selectContainer {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .mainTitle {
            font-size: 2.2rem;
            font-weight: 700;
            color: var(--text_color);
            padding: 16px 0;
        }
        .selectWrapper {
            display: flex;
            align-items: center;
            .title {
                width: 100px;
                font-size: 1.6rem;
                color: var(--text_color);
            }
        }
    }

    .dateRangeWrapper {
        height: 370px;
        display: flex;
    }
}

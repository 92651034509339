@import 'styles/colors';

.createAliasContainer {
    .title {
        font-size: 2.2rem;
        font-weight: 700;
    }

    .subTitle {
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 2.4rem;
    }

    .content {
        .selectContainer {
            margin-top: 2.8rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.6rem;

            .selectTitle {
                font-weight: 500;
                font-size: 1.6rem;
            }
            .selectWrapper {
                width: 400px;
            }
        }
    }

    .footer {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .container {
            display: flex;
            margin-top: 2rem;
            width: 100%;
            justify-content: flex-end;
            gap: 1rem;
        }
    }
}

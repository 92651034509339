.pillsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    overflow: hidden;
    max-height: 68px;
    .more {
        padding: 7px 1.2rem;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        visibility: hidden;
        &.visible {
            visibility: visible;
        }
    }
}

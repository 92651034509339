.formContainer {
    width: 100%;
    padding: 16px;
    .note {
    }
    .inputWrapper {
        display: flex;
        align-items: center;
        margin: 32px 0;
        .title {
            display: inline-block;
            width: 26rem;
        }
    }
}

@import 'styles/colors';
.widgetTopHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: calc(100% + 2px);
    background-color: var(--widget_title_bg);
    border-radius: 16px 16px 0 0;
    margin-bottom: -10px;
    padding: 25px 40px 25px 15px;
    border: 1px solid var(--widget_border_color);
    .projectTitle {
        font-weight: 400;
        font-size: 2rem;
        color: var(--text_color);
    }

    .versionWrapper {
        display: flex;
        gap: 6px;
        margin-bottom: 10px;
        .versionTitle {
            font-size: 2rem;
            font-weight: 400;
            color: $color-secondary-title;
        }
        .title {
            font-size: 2rem;
        }
    }

    .removeWidget {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
    }
}

@import 'styles/colors.scss';

.changedActivitiesContainer {
    .rowsBoardWrapper {
        padding: 8px 3.2rem;
        .activityStatusChanged {
            background-color: var(--activity-has-change);
        }
    }
}
.footer {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}

.rowsBoardHeader {
    display: flex;
    align-items: center;
    width: 100%;

    .actionButtonsWrapper {
        display: flex;
        gap: 0.8rem;
    }
}
.pagination {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    align-items: center;
    gap: 40px;
}

.container {
    padding: 1.6rem;
}

.header {
    font-size: 1.8rem;
    font-weight: bold;
}

.overflow {
    overflow: hidden;
}

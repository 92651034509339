.rowsBoardWrapper {
    padding: 8px 32px;
}

.linkWrapper {
    display: flex;
    align-items: center;
}

.statusBar {
    display: flex;
    gap: 16px;
    align-items: center;
    .statusBarWrapper {
        width: 80px;
    }
}

.filters {
    display: flex;
    align-items: center;
    gap: 16px;
    .selectWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        .title {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    .filtersWrapper {
        display: flex;
        gap: 14px;
        align-items: center;
        height: 3.5rem;

        .filterTitle {
            color: var(--filters_wrapper_text_color);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
        }
    }
}

.imageContainer {
    display: flex;
    img.mainHeaderAvatar {
        display: inline-block;
        width: 2.71em;
        height: 2.71em;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    img.mainHeaderOrg {
        margin-left: -0.6em;
        display: inline-block;
        width: 2.71em;
        height: 2.71em;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}

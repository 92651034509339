@import 'styles/colors';
.titleAndValueContainer {
    margin-top: 1.8rem;
    display: flex;
    justify-content: space-between;

    .titleClass {
        font-size: 1.5rem;
        color: var(--card-tabs-color);
        font-weight: 500;
    }

    .valueClass {
        font-size: 1.5rem;
        color: var(--text_color);
        font-weight: normal;
        flex: 1;
    }
}
